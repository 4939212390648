import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
 
import { ApiUrl } from '../../API/Api.config';
import withReactContent from 'sweetalert2-react-content'
// import Cookies from 'universal-cookie';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import popupimage from '../../assets/images/startup/popup-image.jpg';
import influencer1 from '../../assets/images/startup/influencer1.png';
import influencer2 from '../../assets/images/startup/influencer2.png';
import influencer3 from '../../assets/images/startup/influencer3.png';
import influencer4 from '../../assets/images/startup/influencer4.png';
import video from '../../assets/images/startup/business.mp4';
import Image0 from '../../assets/images/startup/cs-1-1-1.jpg';
import Image1 from '../../assets/images/startup/cs-1-1-2.jpg';
import Image2 from '../../assets/images/startup/cs-1-1-3.png';
import Image3 from '../../assets/images/startup/cs-1-logo.webp';
import Image4 from '../../assets/images/case-study/cs6/4.png';
import Image5 from '../../assets/images/case-study/cs8/3.jpg';
import Image6 from '../../assets/images/startup/infer.png';
import './StartupHome.style.scss';
import CookieConsent from "react-cookie-consent";


const StartupHome = () => {
    document.title = "Startup | PROMOTE"

    // const cookies = new Cookies();
    const [isOpen, setIsOpen] = useState(false)
  // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    async function emailOffer(data) {
        try {
            axios.post(ApiUrl + `offer_email/`, data, { headers: { 'Accept': 'application/json' } })
            .then(function (response) {
                
            }).then(willSearch => {
                    if (willSearch) {
                        // swal.close()
                        window.location.reload(false);
                    }
                })
                .catch(error => {
                    if (error.request.status) {
                        // swal({
                        //     title: "Confirmation",
                        //     text: "Please try again later",
                        //     icon: "error",
                        //     className: "app-popup",
                        //     dangerMode: true,
                        // })
                    }
                });
        } catch (e) {
            console.log(e)
        }
    }

    const { setError, register, handleSubmit, formState: { errors } } = useForm();

    const handleSubmitForm = (data) => {
        emailOffer(data)

    }

    // useEffect(() => {
    //     if (cookies.get('visit') === undefined) {
    //         cookies.set('visit', 'visited');
    //         setIsOpen(true)
    //     }
    // }, [])
    useEffect(() => { }, [isOpen])

    return (
        <>
            <Header />
            <div id='startupHome'>
                <section id='section1' className='section'>
                    <div className='container mx-auto h-full'>
                        <div className='flex flex-col justify-start items-center h-full w-full'>
                            <div className='flex flex-row  flex-wrap sm:flex-nowrap w-full h-full sm:space-x-8'>
                                <div className='sm:basis-1/4 basis-full'>
                                    <h1>
                                        <div className='p-2 text-primary big-text'>CONNECT.</div><div className='p-2'>ADVERTISE.</div><div className='bg-third p-2 big-text text-bg text-white'>GROW.</div>
                                    </h1>
                                    <div className='mt-2 hidden sm:block'>
                                        <button type="button" className="text-white bg-secondary hover:bg-third hover:text-white focus:ring-5 focus:ring-primary flex justify-center text-base rounded-lg text-center">
                                            <a href="https://calendly.com/promote-io/30min" className='flex text-white items-center h-full px-6 py-3.5'>
                                                Book Your Meeting <i className="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </button>
                                    </div>
                                    {/* {isOpen && 
                                    <div id="defaultModal" tabindex="-1" aria-hidden="true" className="modal-background flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 p-4 w-full md:inset-0 h-modal md:h-full">
                                        <div className="relative w-full max-w-2xl h-full md:h-auto">
                                        <div className=" modal-width flex w-1/3 h-2/3 flex-col bg-white border rounded-lg shadow-md md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                                            <img className="hidden sm:block object-cover w-1/3 h-full rounded-t-lg md:rounded-none md:rounded-l-lg" src={popupimage} alt="" />
                                            <div className="flex flex-col p-4 leading-normal">
                                                <div className='flex flex-row items-start pt-10'>
                                                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 pb-3">ONE-TIME OFFER.</p>
                                                </div>
                                                <div className='flex flex-col items-center'>
                                                    <h5 className="flex mb-2 font-bold tracking-tight text-gray-900 dark:text-white">Enjoy 5% off your first month subscription</h5>
                                                    <p className="mb-3 font-light pt-3 text-gray-700 dark:text-gray-400">Also receive a free meeting with one of our team who will talk you through the platform and showcase some of our amazing influencers for you!.</p>
                                                </div>
                                                <div className="mt-2">
                                                    <label htmlFor="email" className="block pt-3 text-black text-sm font-bold">Email</label>
                                                    <input type="text" placeholder='example@promote.io' name="email" className="block w-full px-4 py-2 mb-2 mt-2 text-black-700 bg-white border rounded-sm focus:border-primary focus:ring-primary focus:outline-none focus:ring focus:ring-opacity-40" {...register('email')}/>
                                                </div>
                                                <form onSubmit={handleSubmit(handleSubmitForm)}>
                                                    <button
                                                        className="button-modal text-white transition-colors duration-200 transform bg-primary rounded-sm hover:bg-black focus:outline-none focus:bg-black">
                                                        Register
                                                    </button>
                                                </form>
                                                <h5 className="flex ml-2 text-sm mt-2 font-medium tracking-tight text-gray-900 dark:text-white">Get Your promo code and apply it at checkout</h5>
                                                <p className="text-gray-700 duration-200 transform text-center mt-5 underline cursor-pointer" onClick={()=>{setIsOpen(false)}}>
                                                    No thanks
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>} */}
                                </div>
                                <div className='sm:basis-3/4 basis-full'>
                                    <div className='flex flex-row flex-wrap sm:flex-nowrap justify-end h-full sm:space-x-4'>
                                        <video  controls autoPlay muted loop id='vid' className='border-4 border-primary'>
                                            <source src={video} type="video/mp4"/>
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className='text-2xl mt-20 w-full bg-fourth p-8 text-center text-black'>
                        Authentic reviews from real people… just on social media
                    </h5>
                </section>
                <section id='section2' className='section'>
                    <div className='container mx-auto h-full'>
                        <div className='flex flex-col justify-start items-center h-full w-full '>
                            <h2 className='text-center'>Find the perfect influencer to <span className='text-primary'>promote</span> your product</h2>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap w-full sm:space-x-4'>
                                <div className='sm:basis-1/4 basis-full'>
                                    <div className="card mt-4 sm:mt-0" >
                                        <div className='rounded-lg influencer-img'>
                                            <img className="product-image" src={influencer1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='sm:basis-1/4 basis-full'>
                                    <div className="card mt-4 sm:mt-0">
                                        <div className='rounded-lg influencer-img'>
                                            <img className="product-image" src={influencer2} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='sm:basis-1/4 basis-full'>
                                    <div className="card mt-4 sm:mt-0">
                                        <div className='rounded-lg influencer-img'>
                                            <img className="product-image" src={influencer3} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='sm:basis-1/4 basis-full'>
                                    <div className="card mt-4 sm:mt-0">
                                        <div className='rounded-lg influencer-img'>
                                            <img className="product-image" src={influencer4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='button-div'>
                                <a href="https://calendly.com/promote-io/30min" className="inline-flex items-center justify-center w-full h-full py-2 px-3 text-sm font-medium text-center text-white bg-secondary rounded-lg hover:bg-third focus:ring-4 focus:outline-none">
                                    <b>Book your Meeting</b>
                                    <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='section2-1' className='section bg-primary'>
					<div className='container mx-auto '>
                        <div className='flex justify-center items-center flex-col'>
                            <h2 className='text-center text-fourth'><span className='text-secondary'>Case</span> Studies</h2>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap carousel'>
                                <div className='sm:basis-5/12 basis-full case-study-img '>
                                    <div id="case-study-carousel-home" className="relative modal-img-div " data-carousel="static">
                                        {/* <!-- Carousel wrapper --> */}
                                        <div className="relative h-full overflow-hidden rounded-lg">
                                            {/* <!-- Item 1 --> */}
                                            <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                <img src={Image0} className="absolute block" alt="..."/>
                                            </div>
                                            {/* <!-- Item 2 --> */}
                                            <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                <img src={Image1} className="absolute block" alt="..."/>
                                            </div>
                                        </div>
                                        {/* <!-- Slider controls --> */}
                                        <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                            <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                                <span className="sr-only">Previous</span>
                                            </span>
                                        </button>
                                        <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                            <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                <span className="sr-only">Next</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className='sm:basis-7/12 basis-full py-6'>
                                    <div className="p-6 border-b">
                                        <img src={Image3} alt="Hair Virtue London" className='mb-5' />
                                        <h3 className="text-xl font-semibold text-black mb-5">
                                            Hair Virtue London Campaign
                                        </h3>
                                        <p className="text-base leading-relaxed text-black">
                                            Hair Virtue campaign's aim was to create greater brand awareness for their nourishing hair care products and moisturising hair oils. Through PROMOTE they partnered up with a creator who was passionate about their product and did an amazing job of promoting their products on her Instagram.
                                        </p>
                                    </div>
                                    <div className='p-6'>
                                        <div className='flex flex-row  flex-wrap mb-10'>
                                            <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                <i className="fa-solid fa-users text-primary"></i>
                                                <p className='text-primary'>3,070</p>
                                                <p><b>Audience</b></p>
                                            </div>
                                            <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                <i className="fa-solid fa-heart text-primary"></i>
                                                <p className='text-primary'>425</p>
                                                <p><b>Likes</b></p>
                                            </div>
                                            <div className='sm:basis-1/3 basis-1/2 sm:mt-0 mt-4 text-center'>
                                                <i className="fa-solid fa-comment text-primary"></i>
                                                <p className='text-primary'>72</p>
                                                <p><b>Comments</b></p>
                                            </div>
                                            <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                <i className="fa-solid fa-handshake text-primary"></i>
                                                <p className='text-primary'>16.3%</p>
                                                <p><b>Engagement rate</b></p>
                                            </div>
                                            <div className='sm:basis-1/3 basis-1/2 sm:mt-4 mt-4 text-center'>
                                                <i className="fa-solid fa-eye text-primary"></i>
                                                <p className='text-primary'>1,425</p>
                                                <p><b>Impressions</b></p>
                                            </div>
                                            <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                <i className="fa-solid fa-percent text-primary"></i>
                                                <p className='text-primary'>45.2%</p>
                                                <p><b>Impression rate</b></p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div className='button-div'>
                                <a href="/case-study" className="inline-flex items-center justify-center w-full h-full py-2 px-3 text-sm font-medium text-center text-white bg-secondary rounded-lg hover:bg-fourth hover:text-black focus:ring-4 focus:outline-none">
                                    <b>More case studies here</b>
                                    <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>
                        </div>
					</div>
                </section>
                <section id='section3' className='section'>
                    <div className='container mx-auto h-full'>
                        <div className='flex flex-col justify-start items-center'>
                            <div className='w-full'>
                                <div className='w-full'>
                                    <div className="block p-6 w-full bg-secondary rounded-lg border border-gray-200 shadow-md">
                                        <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                            <div className='sm:basis-1/3 basis-1/2 text-center p-2 flex flex-col justify-center items-center text-white border-r-2 border-b-2 sm:border-b-0'>
                                                <p><b>Average Engagement Rate</b></p>
                                                <p>9.6%</p>
                                            </div>
                                            <div className='sm:basis-1/3 basis-1/2 text-center p-2 flex flex-col justify-center items-center text-white border-l-2 sm:border-r-2 border-b-2 sm:border-b-0'>
                                                <p><b>Trusted By</b></p>
                                                <p>1000s</p>
                                            </div>
                                            <div className='sm:basis-1/3 basis-full text-center p-2 flex flex-col justify-center items-center text-white sm:border-l-2 border-t-2 sm:border-t-0'>
                                                <p><b>50+</b></p>
                                                <p>Satisfied Business</p>
                                            </div>
                                        </div>
                                        {/* <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">1000+ Influncers | 6.5% Avg. engagement rate</h5> */}
                                        <p className="font-normal text-gray-700 dark:text-gray-400"></p>
                                    </div>
                                </div>
                            </div>
                            <h2 className='text-center'>Benefits of <span className='text-primary'>influencer marketing</span></h2>
                            <div className='mt-10'>
                                <p className='main-text'>Find the perfect influencer to promote your brand. We provide access to focused, affordable social media advertising for your business by making it easy to find micro and nano influencers that will reach out to your ideal target audience. Micro and nano influencers outperform larger influencers due to their high engagement rate!</p>
                            </div>
                            <div className='flex w-full mt-5 flex-wrap sm:flew-nowrap mt-10'>
                                <div className='sm:basis-1/3 basis-full px-4 text-center sm:mt-0 mt-4'>
                                    <div className='flex justify-center'>
                                        <span className="flex justify-center items-center point-ring rounded-full text-secondary bg-primary">
                                            <i className="fa-solid fa-award"></i>
                                        </span>
                                    </div>
                                    <h3 className="text-center mb-1 mt-5 text-lg font-semibold text-secondary">Enrich your marketing strategy!</h3>
                                    <p className="font-normal text-third">Influencers provide authentic content directly to your target audience. 92% of consumers trusting recommendations from their friends and will be more likely to check out your brand</p>
                                </div>
                                <div className='sm:basis-1/3 basis-full px-4 text-center sm:mt-0 mt-4'>
                                    <div className='flex justify-center'>
                                        <span className="flex justify-center items-center point-ring rounded-full text-secondary bg-primary">
                                            <i className="fa-solid fa-money-bill-1-wave"></i>
                                        </span>
                                    </div>
                                    <h3 className="text-center mb-1 mt-5 text-lg font-semibold text-secondary">Cost and time effective!</h3>
                                    <p className="font-normal text-third">Use our filtered search to find perfect ambassadors quickly and browse the featured page to see your best matched influencers</p>
                                </div>
                                <div className='sm:basis-1/3 basis-full px-4 text-center sm:mt-0 mt-4'>
                                    <div className='flex justify-center'>
                                        <span className="flex justify-center items-center point-ring rounded-full text-secondary bg-primary">
                                            <i className="fa-solid fa-link"></i>
                                        </span>
                                    </div>
                                    <h3 className="text-center mb-1 mt-5 text-lg font-semibold text-secondary">Collaborate with cool businesses!</h3>
                                    <p className=" font-normal text-third">Collaborating helps you expand your network and product portfolio whilst achieving mutual growth</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='section4' className='section bg-fourth'>
                    <div className='container mx-auto'>
                        <div className='flex flex-col'>
                            <h2 className='text-center'>
                                What businesses say about <span className='text-primary'>Promote</span>
                            </h2>
                            <div id="controls-carousel" className="relative" data-carousel="static">
                                <div className="overflow-hidden relative influencer-review">
                                    <div className="duration-700 ease-in-out absolute inset-0 transition-all transform -translate-x-full z-10" data-carousel-item="">
                                        <figure className="max-w-screen-lg mx-auto text-center flex flex-col items-center justify-center h-full">
                                            <svg className="h-12 mx-auto mb-3 text-primary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                                            </svg>
                                            <blockquote>
                                                <p className="font-medium text-black">They were pretty quick when it came to paying the influencer’s balance as we had won a £20 voucher on the platform. All in all, we are definitely going to be partnering with them again and we highly recommend them to any Brand, especially start up/small Brands who are looking for affordable Nano influencers. Thank you Promote!!!</p>
                                            </blockquote>
                                            <figcaption className="flex items-center justify-center mt-6 space-x-3">
                                                
                                                <div className="flex flex-col items-center">
                                                    <img src={Image3} alt="profile picture"/>
                                                    <p className="font-medium text-black mt-3">Hair Virtue London</p>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20" data-carousel-item="active">
                                        <figure className="max-w-screen-lg mx-auto text-center flex flex-col items-center justify-center h-full">
                                            <svg className="h-12 mx-auto mb-3 text-primary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                                            </svg>
                                            <blockquote>
                                                <p className="font-medium text-black">I thought that you were very easy to deal with and had a quick response time. Understood I was sceptical about paid promotions and offered some alternative which was perfect. Will definitely be using this service again in the future</p>
                                            </blockquote>
                                            <figcaption className="flex items-center justify-center mt-6 space-x-3">
                                                <div className="flex flex-col items-center">
                                                    <img src={Image4} alt="profile picture" width={80}/>
                                                    <p className="font-medium text-black mt-3">Inspired Images</p>
                                                    
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20" data-carousel-item="active">
                                        <figure className="max-w-screen-lg mx-auto text-center flex flex-col items-center justify-center h-full">
                                            <svg className="h-12 mx-auto mb-3 text-primary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                                            </svg>
                                            <blockquote>
                                                <p className="font-medium text-black">Promote have been wonderful from start to finish. The value for money was incredible. They organised 5 influencers for my growing beauty biz, which all fitted my brief perfectly. They were even able to convince influencers I had chosen, to work with me. Which is great as if I’d approached them without the service that iPromote provide, I’m not sure they would have agreed. The platform is easy to work with and communication was always fantastic. I hope to work with them more in the future and would thoroughly recommend.</p>
                                            </blockquote>
                                            <figcaption className="flex items-center justify-center mt-6 space-x-3">
                                                <div className="flex flex-col items-center">
                                                    <img src={Image5} alt="profile picture" width={80}/>
                                                    <p className="font-medium text-black mt-3">Neikid</p>
                                                    
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20" data-carousel-item="active">
                                        <figure className="max-w-screen-lg mx-auto text-center flex flex-col items-center justify-center h-full">
                                            <svg className="h-12 mx-auto mb-3 text-primary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                                            </svg>
                                            <blockquote>
                                                <p className="font-medium text-black">Promote has been so helpful in finding the perfect ambassador for my clothing brand Infer and has given the Infer team more time to focus on other parts of the business. </p>
                                            </blockquote>
                                            <figcaption className="flex items-center justify-center mt-6 space-x-3">
                                                <div className="flex flex-col items-center">
                                                    <img src={Image6} alt="profile picture" width={80}/>
                                                    <p className="font-medium text-black mt-3">Infer</p>
                                                    
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                                <div className='sm:static relative'>
                                    <button type="button" className="flex absolute top-0 left-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-prev="">
                                        <span className="inline-flex justify-center items-center w-10 h-10 rounded-full bg-secondary text-white group-focus:ring-4 group-focus:ring-white">
                                            <svg aria-hidden="true" className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
                                            <span className="sr-only">Previous</span>
                                        </span>
                                    </button>
                                    <button type="button" className="flex absolute top-0 right-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-next="">
                                        <span className="inline-flex justify-center items-center w-10 h-10 rounded-full bg-secondary text-white group-focus:ring-4 group-focus:ring-white">
                                            <svg aria-hidden="true" className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                                            <span className="sr-only">Next</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CookieConsent buttonStyle={{ backgroundColor: "#008e95", fontSize: "13px" }}>This website uses cookies to enhance the user experience.</CookieConsent>
                <Footer />
            </div>
        </>
    );
}

export default StartupHome;