import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import { useStepperContext } from "../../../contexts/StepperContext";
import Range from "../../../components/Range/Range";
import secureLocalStorage from "react-secure-storage";
import InputField from "../../../components/InputField/InputField";

function Expectation({ heading, subHeading, apiName, apiName2, influencer, setDataEmpty }) {
    const { userData, setUserData } = useStepperContext();
    const [postPrice, setPostPrice] = useState(0);
    const [minFollowers, setMinFollowers] = useState("");
    const role = secureLocalStorage.getItem('role');


    useEffect(() => {
        if (influencer == 1) {
            setUserData({ ...userData, [apiName]: postPrice, ['followers_number']: minFollowers, });
        } else {
            setUserData({ ...userData, [apiName]: minFollowers, [apiName2]: postPrice });
        }

    }, [postPrice, minFollowers])


    useEffect(() => {
        if (influencer == 1) {

            setDataEmpty(true)

            if (postPrice > 0 && minFollowers > 0) {
                setDataEmpty(false);
            } else {
                setDataEmpty(true);
            }
        }
    }, [postPrice, minFollowers])

    // useEffect(() => {
    //     console.log(userData)
    //     console.log(postPrice)
    // }, [userData, postPrice])


    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        {subHeading}
                    </small>
                </p>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex flex-col justify-center items-center step-card">
                        {influencer == 0 &&
                            <div className="w-full grid sm:grid-cols-2 sm:gap-4 rounded-lg">
                                <div className="w-full pb-4">
                                    <InputField type={'number'} inputName={'min_followers'} onChange={(e) => { setMinFollowers(e.target.value); }} id={"inf_followers"} className="bg-white" labelName={"Enter min. number of followers"} />
                                    {/* <input type="number" placeholder="Please Enter Your Price Here" onChange={(e)=>{setPostPrice(e.target.value);}} className="w-full"/> */}
                                </div>
                                <div className="w-full pb-4">
                                    <InputField type={'number'} inputName={'max_followers'} onChange={(e) => { setPostPrice(e.target.value); }} id={"inf_pricing"} className="bg-white" labelName={"Enter max. number of followers"} />
                                    {/* <input type="number" placeholder="Please Enter Your Price Here" onChange={(e)=>{setPostPrice(e.target.value);}} className="w-full"/> */}
                                </div>
                            </div>
                        }


                        {influencer == 1 &&
                            <>
                                <div className="w-full grid sm:grid-cols-2 sm:gap-4 rounded-lg">
                                    <div className="w-full pb-4">
                                        <InputField type={'number'} inputName={'inf_price'} onChange={(e) => { setPostPrice(e.target.value); }} id={"inf_pricing"} className="bg-white" labelName={"Enter your price per post (£)"} />
                                        {/* <input type="number" placeholder="Please Enter Your Price Here" onChange={(e)=>{setPostPrice(e.target.value);}} className="w-full"/> */}
                                    </div>
                                    <div className="w-full pb-4">
                                        <InputField type={'number'} inputName={'inf_price'} onChange={(e) => { setMinFollowers(e.target.value); }} id={"inf_followers"} className="bg-white" labelName={"Enter your number of followers"} />
                                        {/* <input type="number" placeholder="Please Enter Your Price Here" onChange={(e)=>{setPostPrice(e.target.value);}} className="w-full"/> */}
                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="relative overflow-x-auto w-full">
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" className="px-6 py-2">
                                                        Number of followers
                                                    </th>
                                                    <th scope="col" className="px-6 py-2">
                                                        Average price per post
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-white border-b">
                                                    <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                        {"< 1000"}
                                                    </th>
                                                    <td className="px-6 py-2">
                                                        £1 - £10
                                                    </td>
                                                </tr>
                                                <tr className="bg-white border-b">
                                                    <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                        1000 - 1999
                                                    </th>
                                                    <td className="px-6 py-2">
                                                        £1 - £20
                                                    </td>
                                                </tr>
                                                <tr className="bg-white border-b">
                                                    <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                        2000 - 4999
                                                    </th>
                                                    <td className="px-6 py-2">
                                                        £10 - £50
                                                    </td>
                                                </tr>
                                                <tr className="bg-white ">
                                                    <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                        5000+
                                                    </th>
                                                    <td className="px-6 py-2">
                                                        £30 - £50 +
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="mt-4">
                                        <small>
                                            Price per post will lost vary due to these factors: Engagment rate, Social media platform, The complexity of the subject matter, Content quality, Content type, Influencer time investment
                                        </small>
                                    </p>
                                </div>
                            </>
                        }
                        {/* <div className="w-full">
                            <Range
                                min={0}
                                max={role == 1 ? 500 : 10000}
                                onChange={({ min, max }) => (setPostPrice(max), setMinFollowers(min) )}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Expectation;
