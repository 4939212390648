import { useForm } from "react-hook-form";
// import userInfoAPI from "../../API/UserInfoApi";
import useAxios, { ApiUrl, imgApi } from '../../API/Api.config';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from "../../components/DashboardHeader/DashboardHeader";
import Sidebar from '../../components/Sidebar/Sidebar';
import Dropzone from "../../components/Dropzone/Dropzone";
import TokenService from "../../API/token.service";
import axios from "axios";
import chroma from "chroma-js";
import Select from 'react-select'
import secureLocalStorage from "react-secure-storage";
import './UserInfo.style.scss';
import Compressor from 'compressorjs';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const UploadPromotionPictures = () => {
    document.title = "Update Profile | Promote"
    
    const token = TokenService.getLocalAccessToken()
    const role = secureLocalStorage.getItem("role");
    const [ pp, setpp ] = useState("");
    const MySwal = withReactContent(Swal)
    const nav = useNavigate();
    const [images, setImages] = useState([]);


    async function getUserImages() {
        try {
            await axios.get(ApiUrl + 'influencer_images/', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setImages(response.data); })
                .catch(error => console.log(error))
        } catch (e) {
            console.log()
        }
    }

    async function getStartupImages() {
        try {
            await axios.get(ApiUrl + 'startup_images/', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setImages(response.data); })
                .catch(error => console.log(error))
        } catch (e) {
            console.log()
        }
    }

    async function influencerImage({ image }) {
        try {
            axios.post(ApiUrl + 'influencer_images/', { image }, { headers: { 'Accept': 'application/json', 'Authorization': 'Token ' + token } })
            .then(response => {
                if (response.status === 201) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your image has been uploaded',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                }
                getUserImages()
                setTimeout(function(){
                    nav('/user-info');
                }, 3000);
            }).catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    } else {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was an error uploading your image',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                });
        } catch (e) {
            console.log(e)
            MySwal.fire({
                position: 'top',
                toast: true,
                icon: 'error',
                title: 'There was an error uploading your image',
                showConfirmButton: false,
                timer: 3000,
            })
        }
    }

    async function startupImage({ image }) {
        try {
            axios.post(ApiUrl + 'startup_images/', { image }, { headers: { 'Accept': 'application/json', 'Authorization': 'Token ' + token } })
            .then(response => {
                if (response.status === 201) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your image has been uploaded',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                }
                getUserImages()
                setTimeout(function(){
                    nav('/user-info');
                }, 3000);
            }).catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    } else {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was an error uploading your image',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                });
        } catch (e) {
            console.log(e)
            MySwal.fire({
                position: 'top',
                toast: true,
                icon: 'error',
                title: 'There was an error uploading your image',
                showConfirmButton: false,
                timer: 3000,
            })
        }
    }

    async function influencerDeleteImage(id) {
        try {
            axios.delete(ApiUrl + `influencer_images/${id}/`, { headers: { 'Accept': 'application/json', 'Authorization': 'Token ' + token } })
            .then(response => {
                MySwal.fire({
                    position: 'top',
                    toast: true,
                    icon: 'success',
                    title: 'Your Image has been deleted',
                    showConfirmButton: false,
                    timer: 3000,
                })
                getUserImages()

            }).catch(error => {
                if (error.request.status === 401) {
                    nav('/login/')
                } else if (error.request.status === 400) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'error',
                        title: 'There was an error deleting your image',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                }
            });
        } catch (e) {
            console.log(e)
        }
    }

    async function startupDeleteImage(id) {
        try {
            axios.delete(ApiUrl + `startup_images/${id}/`, { headers: { 'Accept': 'application/json', 'Authorization': 'Token ' + token } })
            .then(response => {
                MySwal.fire({
                    position: 'top',
                    toast: true,
                    icon: 'success',
                    title: 'Your Image has been deleted',
                    showConfirmButton: false,
                    timer: 3000,
                })
                getUserImages()

            }).catch(error => {
                if (error.request.status === 401) {
                    nav('/login/')
                } else if (error.request.status === 400) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'error',
                        title: 'There was an error deleting your image',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                }
            });
        } catch (e) {
            console.log(e)
        }
    }

    const handleInfleuncerImage = (file) => {
        if(role == 1) {
            influencerImage({ image: file })
        } else {
            startupImage({ image: file })
        }
    }
    

    useEffect(() => {
        if(role == 1) {
            getUserImages()
        } else {
            getStartupImages()
        }
    }, [images])

    return(
        <>
        <Header />
        <Sidebar />
        <div className="sm:ml-64 ml-0 mt-20 user-upload">
            <div className="container">
                <div className="relative flex flex-col mt-10 overflow-hidden max-h-full" id="more-pics">
                    <h2 className="p-4 pl-0 text-2xl font-bold">Manage Pictures</h2>

                    <div className="bg-secondary p-4 rounded-t-md text-white">
                        Promotion pictures
                    </div>
                    <div className="bg-white p-6 rounded-b-md">
                        <div className="flex flex-row flex-wrap sm:flex-nowrap justify-center w-full ">
                            <div className="w-full">
                                <form>
                                    <div className="border p-6">
                                        <Dropzone setpp={setpp}></Dropzone>
                                    </div>
                                    <button className="bg-primary hover:bg-secondary w-full mt-3 text-white p-4 rounded-lg" type="button" onClick={() => handleInfleuncerImage(pp)}>
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative flex flex-col mt-5 mb-10 overflow-hidden max-h-full">
                    <div className="bg-secondary p-4 rounded-t-md text-white">
                        Your pictures
                    </div>
                    <div className="bg-white p-6 rounded-b-md">
                        <div className="flex flex-row sm:space-x-6 flex-wrap sm:flex-nowrap">
                            {images.length > 0 && images.map(obj => {
                                return (
                                    <div className="sm:basis-1/4 basis-full">
                                        <div className="flex flex-col justify-center items-center pt-2 pb-6 each-profile-img">
                                            <img src={imgApi + obj.image} alt={obj.image} className="more-pics" />
                                            {role == 1 && 
                                                <button className="bg-red-700 hover:bg-secondary w-full mt-3 text-white p-4 rounded-lg" type="button" onClick={() => influencerDeleteImage(obj.id)}>
                                                    Delete
                                                </button>
                                            }
                                            {role == 2 && 
                                                <button className="bg-red-700 hover:bg-secondary w-full mt-3 text-white p-4 rounded-lg" type="button" onClick={() => startupDeleteImage(obj.id)}>
                                                    Delete
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

}

export default UploadPromotionPictures;