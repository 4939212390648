import { useEffect, useState } from "react";
import { useStepperContext } from "../../../contexts/StepperContext";
import { EditorState, convertToRaw } from 'draft-js';
import chroma from "chroma-js";
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import '../onboarding.style.scss';


function Yourself({ setDataEmpty, heading, apiName, influencer }) {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const { userData, setUserData } = useStepperContext();
    const [htmlContent, setHtmlContent] = useState(null);
    const [location, setLocation] = useState(null);

    const handleEditorChange = (state) => {
        setEditorState(state);
        setHtmlContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    useEffect(() => {
        if (editorState.getCurrentContent().getPlainText() !== '' && location !== null) {
            setDataEmpty(false)
        } else {
            setDataEmpty(true)
        }
    }, [htmlContent, location])

    useEffect(() => {
        setUserData({ ...userData, [apiName]: htmlContent });
    }, [htmlContent])

    useEffect(() => {
        if (influencer === 1) {
            setUserData({ ...userData, ['location']: location });
        }
    }, [location])

    useEffect(() => {
        setDataEmpty(true)
    }, [])

    useEffect(() => {
        console.log(userData);
    }, [userData])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex flex-col justify-center items-center step-card">
                        <div className="flex flex-col items-center justify-center w-full">
                            <Editor
                                onEditorStateChange={handleEditorChange}
                                editorClassName="editor-class rounded-b-lg"
                                toolbarClassName="toolbar-class"
                                toolbar={{
                                    options: ['inline', 'blockType']
                                }}
                            ></Editor>
                            {influencer == 1 &&
                                <div className="w-full mt-5">
                                    <p className="mb-2"><b>Let us know where you are from</b></p>
                                    <GooglePlacesAutocomplete
                                        apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: ['GB'],
                                            }
                                        }}
                                        selectProps={{
                                            onChange: (value) => { setLocation(value.value.description) },
                                            placeholder: "Select your Location",
                                            styles: {
                                                input: (provided) => ({
                                                    ...provided,
                                                    color: 'black',
                                                    boxShadow: "none",
                                                    outline: "none",
                                                    ':hover': {
                                                        '--tw-ring-color': 'black !important'
                                                    },
                                                    ':focus': {
                                                        '--tw-ring-color': 'black !important'
                                                    },
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: '1px solid black',
                                                    ':hover': {
                                                        border: '1px solid #008e95 !important'
                                                    },
                                                    boxShadow: "none",
                                                    outline: "none"
                                                }),

                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                    const color = chroma('#008e95');
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? undefined
                                                            : isSelected
                                                                ? data.color
                                                                : isFocused
                                                                    ? color.alpha(0.1).css()
                                                                    : undefined,
                                                        ':active': {
                                                            ...styles[':active'],
                                                            backgroundColor: !isDisabled
                                                                ? isSelected
                                                                    ? color
                                                                    : color.alpha(0.3).css()
                                                                : undefined,
                                                        },
                                                    };
                                                },
                                            }
                                        }}
                                    />

                                </div>

                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Yourself