import React from 'react'
import axios from 'axios'
import TokenService from '../../API/token.service'
import { ApiUrl } from '../../API/Api.config';
import { useState } from 'react';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import MyMapComponent from '../../components/MapContainer/MapContainer';
import PieChart from '../../components/PieChart/PieChart';
import secureLocalStorage from "react-secure-storage";
import { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { imgApi } from '../../API/Api.config';
import hero from '../../assets/images/users/cover.jpg';
import ReactStars from "react-rating-stars-component";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './UserInfo.style.scss';
import { Tabs } from 'flowbite-react'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

export default function UserById() {
    document.title = "User Profile | Promote"

    // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    const token = TokenService.getLocalAccessToken();
    const role = secureLocalStorage.getItem("role");
    const userid = secureLocalStorage.getItem("userid");
    const myid = secureLocalStorage.getItem("user_id");
    const profileView = secureLocalStorage.getItem("profile_view");
    // let influencer_connect = secureLocalStorage.getItem("influencer_connect");
    const [influencer_connect, set_influencer_connect] = useState(0)
    const [connectionNumber, setConnectionNumber] = useState(0);
    const [currentConnection, setCurrentConnection] = useState(0);
    const [limit, setLimit] = useState(true);
    // let influencer_connect = 0
    const [counter, setCounter] = useState(0);
    const [recevier, setRecevier] = useState(0);
    const influencerid = userid
    const [social, setSocial] = useState([])
    const [review, setReview] = useState([])
    const nav = useNavigate();
    const [influencerData, setInfluencerData] = useState([]);
    const [startupData, setStartupData] = useState([]);
    const [images, setImages] = useState([])
    const paymentStatus = secureLocalStorage.getItem('payment_status');
    const [socialAudience, setSocialAudience] = useState([]);
    const [socialAudienceCount, setSocialAudienceCount] = useState([]);
    const [socialAudienceLocation, setSocialAudienceLocation] = useState({});
    const MySwal = withReactContent(Swal)


    async function check_is_friend() {
        try {

            axios.get(ApiUrl + `check_user_friend/?friend_id=${userid}`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {

                    if (response.data.data) {
                        set_influencer_connect(1)
                    }
                }).catch(e => { console.log(e) })
        } catch (e) { console.log(e) }
    }

    async function getInfluencer() {
        try {
            axios.get(ApiUrl + `get_influencer_id/?id=${userid ? influencerid : influencerid}`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    setInfluencerData(response.data)

                    // secureLocalStorage.removeItem("userid");
                    response?.data[0]['social_media_audience'].forEach((sma) => {
                        setSocialAudience(prevState => [...prevState, sma.category])
                        setSocialAudienceCount(prevState => [...prevState, 1])
                        setSocialAudienceLocation({ lat: Number(response?.data[0].audience_geolocation.split(',')[0]), lng: Number(response?.data[0].audience_geolocation.split(',')[1]) })
                    })
                })
                .catch(error => {
                    console.log(error)
                    // if (error.request.status === 401) {
                    //     // secureLocalStorage.removeItem("userid");
                    //     console.log(error)
                    //     nav('/login/')

                    // }
                });
        } catch (e) {
            console.log(e)
        }
    }

    async function getUserReview() {
        try {
            await axios.get(ApiUrl + `influencer_review/?id=${userid ? influencerid : influencerid}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }
            })
                .then(response => {
                    // console.log(response.data)
                    setReview(response.data)
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function getUserImages() {
        try {
            await axios.get(ApiUrl + `get_influencer_images/?id=${userid ? influencerid : influencerid}`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setImages(response.data); })
                .catch(error => console.log(error))
        } catch (e) {
            console.log()
        }
    }

    async function getStartup() {
        try {
            axios.get(ApiUrl + `get_startup_id/?id=${userid ? userid : influencerid}`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    setStartupData(response.data)
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                });
        } catch (e) {
            console.log(e)
        }
    }

    async function getSocial() {
        try {
            await axios.get(ApiUrl + `get_soical_account/${influencerid}`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    setSocial(response.data)
                }).catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {

        }
    }

    async function sendMessage(data, name) {
        const token = TokenService.getLocalAccessToken();

        try {
            await axios.post(ApiUrl + `send_messages/`, data,
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your message has been sent and you will be redirected to messages page shortly',
                        showConfirmButton: false,
                        width: 600,
                        timer: 3000,
                    })
                    setTimeout(function () {
                        nav('/messages?name=' + name.replace(' ', '_'));
                    }, 3000);
                })
                .catch(function (error) {
                    if (error.request.status === 400) {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was an error sending the messages',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    };

    async function handleFavoritConnection(data) {
        try {
            await axios.post(ApiUrl + `favorite_content/${data.friend}/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + token
                }
            })
                .then(response => console.log(response))
                .catch(error => console.log(error))
        } catch (e) {
            console.log(e)
        }

    }

    const handleClickSendMessage = (id, message, name) => {
        sendMessage({ "sender": myid, "message": message, 'receiver': id }, name)
        handleFavoritConnection({ 'friend': id });
    };

    const handleConnectButton = async (id, name) => {

        const connectTextDefault = `Hi ${name}, it would be great to collaborate with you.`
        handleClickSendMessage(id, connectTextDefault, name)
    }

    async function numberFavoritConnection() {
        try {
            await axios.get(ApiUrl + 'favorite_content_number/', {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }
            })
                .then(response => {
                    setCurrentConnection(response.data.connection_number)
                    if (response.status === 401) {
                        nav('/login/')
                    }
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function getConnectionsNumber() {
        try {
            await axios.get(ApiUrl + 'payment_register/', {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Token ' + token,
                }
            }).then(response => {
                console.log(response.data)
                if (response.data[0]?.connection_number) {
                    setConnectionNumber(response.data[0]?.connection_number)
                    console.log(response.data[0]?.connection_number)
                }
            }).catch(error => console.log(error));
        } catch (e) { console.log(e) }
    }


    const handleClick = (img) => {
        Swal.fire({
            imageUrl: imgApi + img,
            imageAlt: 'Custom image',
            width: 800,
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                image: 'img-pop-up',
                popup: 'html-img'
            }
        })
    }


    const extractUsernameFromUrl = (url) => {
        const regex = {
            instagram: /(?:http(?:s)?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_\.]+)/,
            facebook: /(?:http(?:s)?:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9\.]+)/,
            tiktok: /(?:http(?:s)?:\/\/)?(?:www\.)?tiktok\.com\/@([a-zA-Z0-9_\.]+)/,
            linkedin: /(?:http(?:s)?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9\-\.]+)/,
            youtube: /(?:http(?:s)?:\/\/)?(?:www\.)?youtube\.com\/(?:channel\/)?([a-zA-Z0-9_\-]+)/
        };

        let username = null;
        Object.keys(regex).some((platform) => {
            const match = url.match(regex[platform]);
            if (match) {
                username = match[1];
                return true;
            }
        });

        return username;
    };

    const createSocialMediaUrl = (platform, username) => {
        switch (platform) {
            case 'instagram':
                return `https://www.instagram.com/${username}`;
            case 'facebook':
                return `https://www.facebook.com/${username}`;
            case 'tiktok':
                return `https://www.tiktok.com/@${username}`;
            case 'linkedin':
                return `https://www.linkedin.com/in/${username}`;
            case 'youtube':
                return `https://www.youtube.com/channel/${username}`;
            default:
                return null;
        }
    };

    function getSocialMediaColor(platformName) {
        let color;
        switch (platformName.toLowerCase()) {
            case 'instagram':
                color = '#C13584'; // pink
                break;
            case 'facebook':
                color = '#1877F2'; // blue
                break;
            case 'tiktok':
                color = '#000000'; // black
                break;
            case 'linkedin':
                color = '#0077B5'; // blue
                break;
            case 'youtube':
                color = '#FF0000'; // red
                break;
            default:
                color = '#000000'; // black
        }
        return color;
    }

    useEffect(() => {
        numberFavoritConnection()
        // console.log(currentConnection >= connectionNumber)

        if (currentConnection >= connectionNumber) {
            console.log('1')
            setLimit(false);
        } else if (connectionNumber === 0) {
            console.log('2')
            setLimit(false)
        } else {
            console.log('3')
            setLimit(true)
        }

    }, [currentConnection, connectionNumber])


    useEffect(() => {

        if (paymentStatus) {
            getConnectionsNumber()
            numberFavoritConnection()
        } else {
            setConnectionNumber(0)
        }
    }, [connectionNumber, currentConnection])


    useEffect(() => {

        if (profileView === 1) {
            getInfluencer()
            getUserImages()
            getSocial()
            getUserReview()
            check_is_friend()
        } else if (profileView === 2) {
            getStartup()
            getSocial()
        } else {
            nav('/login/')
        }

    }, [])

    useEffect(() => { }, [influencerData])
    useEffect(() => { }, [startupData])
    useEffect(() => { }, [influencer_connect])

    return (
        <>
            <Header />
            <Sidebar />
            <div className='sm:ml-64 ml-0 mt-20 pb-10 pt-1 user-info'>
                <div className='container mx-auto '>
                    <div className='flex justify-center'>
                        {influencerData.length !== 0 && influencerData.map(res => {
                            return (
                                <>
                                    <div className='user-details w-full flex justify-center items-center h-full'>
                                        <div className='grid sm:grid-cols-6 sm:grid-rows-1  gap-y-4 w-full'>
                                            <div className='bg-white flex items-center justify-center p-4 rounded-l-lg sm:col-span-1 col-span-6 sm:row-span-1'>
                                                <div className='basic-info flex flex-col items-center justify-center w-full'>
                                                    <div className='profile-img '>
                                                        <img src={res.profile_image ? imgApi + res.profile_image : 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png'} alt={res.profile_image} className="border border-primary rounded-full" />
                                                    </div>
                                                    <div>
                                                        <p className='mt-4 text-secondary font-bold text-center'>
                                                            {res.name ? res.name : res.first_name}
                                                        </p>
                                                        <p className=' text-primary italic text-center'>
                                                            Influencer
                                                        </p>
                                                    </div>
                                                    {role == 2 &&
                                                        (influencer_connect === 1 ?
                                                            <button className="w-full p-1 text-white rounded-lg bg-primary mt-4" >
                                                                Connected
                                                            </button>
                                                            :
                                                            (limit == true?

                                                                <button className="w-full p-1 text-white rounded-lg bg-primary mt-4" onClick={() => { setRecevier(res.user); handleConnectButton(res.user, (res.name ? res.name : res.first_name)) }}>
                                                                    Connect
                                                                </button>
                                                                :
                                                                <button
                                                                    className="w-full p-1 text-white rounded-lg bg-primary mt-4" disabled={true}>
                                                                    Connect Limit Reached {limit}
                                                                </button>
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className='sm:col-span-5 col-span-6 sm:row-span-1 h-full'>
                                                <div className='bg-white p-4 rounded-r-lg h-full'>
                                                    <div className='flex justify-end flex-col h-full'>

                                                        <div className=''>
                                                            <p className='text-sm'>
                                                                <i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{res.location ? res.location : 'Not Available'}
                                                            </p>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <p className='text-sm'>
                                                                <i className="fa-solid fa-graduation-cap"></i>&nbsp;&nbsp;{res.education_location ? res.education_location : 'Not Available'}
                                                            </p>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <p className='text-sm'>
                                                                <i className="fa-solid fa-suitcase"></i>&nbsp;&nbsp;{res.career ? res.career : 'Not Available'}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className='text-sm mt-2 leading-6' dangerouslySetInnerHTML={{ __html: res.bio }}></p>
                                                        </div>
                                                        {paymentStatus ?
                                                            (influencer_connect === 1 ?
                                                                (social.length > 0 ?
                                                                    <div className={'grid grid-cols-5 justify-items-center mt-2 w-1/4'}>
                                                                        {Object.keys(social[0]).map((oneKey, i) => {
                                                                            if (oneKey !== 'id') {
                                                                                if ((social[0][oneKey] !== '') && (social[0][oneKey] !== null)) {
                                                                                    if (social[0][oneKey]?.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase() !== 'NA'.toLowerCase()) {
                                                                                        if (social[0][oneKey].toLowerCase().includes(oneKey + ".com") === true) {
                                                                                            return (
                                                                                                <div key={i} className="p-2 mr-2 flex items-center justify-center h-full rounded-lg w-full border hover:border-secondary" style={{ background: getSocialMediaColor(oneKey) }}>
                                                                                                    <a href={createSocialMediaUrl(oneKey, extractUsernameFromUrl(social[0][oneKey].toLowerCase().replace('@', '')))} target="_blank" className='text-center text-xs w-full block'>
                                                                                                        <i className={"fa-brands fa-" + oneKey + " text-white"}></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <div key={i} className="p-2 mr-2 flex items-center justify-center h-full rounded-lg w-full border hover:border-secondary" style={{ background: getSocialMediaColor(oneKey) }}>
                                                                                                    <a href={createSocialMediaUrl(oneKey, social[0][oneKey].replace('@', ''))} target="_blank" className='text-center text-xs w-full block'>
                                                                                                        <i className={"fa-brands fa-" + oneKey + " text-white"} ></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    } else {
                                                                                        return (
                                                                                            <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-fourth rounded-lg w-full border hover:border-secondary">
                                                                                                <span target="_blank" className='text-center text-xs w-full block'>
                                                                                                    <i className={"fa-brands fa-" + oneKey + " text-black"}></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                } else {
                                                                                    return (
                                                                                        <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-fourth rounded-lg w-full border hover:border-secondary">
                                                                                            <span target="_blank" className='text-center text-xs w-full block'>
                                                                                                <i className={"fa-brands fa-" + oneKey + " text-black"}></i>
                                                                                            </span>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        })}
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className='mt-2 bg-secondary rounded-lg p-2 px-4 '>
                                                                            <p className='text-white text-sm'>
                                                                                No Social Media Accounts Found
                                                                            </p>
                                                                        </div>
                                                                    </>)
                                                                :
                                                                <>
                                                                    <div className='mt-2 bg-secondary rounded-lg p-2 px-4 '>
                                                                        <p className='text-white text-sm'>
                                                                            Connect with the Influencer to view their Social Media
                                                                        </p>
                                                                    </div>
                                                                </>)
                                                            :
                                                            <>
                                                                {
                                                                    role == 2 ?
                                                                        <div className='mt-2 bg-secondary rounded-lg p-2 px-4 '>
                                                                            <p className='text-white text-sm'>
                                                                                Subcribe in order to view their Social Media
                                                                            </p>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                            </>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-span-6 row-span-2'>
                                                <div className='grid grid-cols-6 grid-rows-1 gap-4 w-full'>
                                                    <div className='sm:col-span-3 col-span-6 row-span-2 h-full'>
                                                        <div className='bg-white p-4 rounded-lg h-full'>
                                                            <h3 className='font-bold'>
                                                                Basic Information
                                                            </h3>
                                                            <div className='mt-4 rounded-lg'>
                                                                <table className="w-full rounded-lg text-xs text-left text-gray-500">
                                                                    <tbody>
                                                                        <tr className="bg-white border-b">
                                                                            <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                Age:
                                                                            </th>
                                                                            <td className="py-3 px-2">
                                                                                {res.age ? res.age : 'Not Specified'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="bg-white border-b">
                                                                            <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                Gender:
                                                                            </th>
                                                                            <td className="py-3 px-2">
                                                                                {res.gender ? <span className='capitalize'>{res.gender}</span> : 'Not Specified'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="bg-white border-b">
                                                                            <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                Charitable:
                                                                            </th>
                                                                            <td className="py-3 px-2">
                                                                                {res.charitable ? 'Yes' : 'No'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="bg-white border-b">
                                                                            <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                Price Per Post:
                                                                            </th>
                                                                            <td className="py-3 px-2">
                                                                                £ {res.price_per_post ? res.price_per_post : 'Not Specified'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="bg-white border-b">
                                                                            <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                Payment Type:
                                                                            </th>
                                                                            <td className="py-3 px-2">
                                                                                {res.callaboration_options.map((payment, key) => (
                                                                                    <span key={key}> {payment.title} {key !== res.callaboration_options.length - 1 ? '/' : ''}</span>
                                                                                ))}
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="bg-white border-b">
                                                                            <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                Followers:
                                                                            </th>
                                                                            <td className="py-3 px-2">
                                                                                {res.followers_number ? res.followers_number.toLocaleString() : 'Not Specified'}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='sm:col-span-3 col-span-6 row-span-2 h-full'>
                                                        <div className='bg-white p-4 rounded-lg h-full'>
                                                            <Tabs.Group
                                                                aria-label="Tabs with underline"
                                                                style="underline"
                                                                className='tab-btn'
                                                            >
                                                                <Tabs.Item
                                                                    title="Interests"
                                                                    active={true}
                                                                >
                                                                    <h3 className='font-bold'>
                                                                        Influencer's Interests
                                                                    </h3>
                                                                    <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mt-4 h-full interests-card'>
                                                                        {res.interest.length > 0 ?
                                                                            res.interest.map(obj => {
                                                                                return (
                                                                                    <div className='rounded-lg interest-img' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + imgApi + obj.image + ")" }}>
                                                                                        <div className='flex justify-center items-center h-full text-white text-center'>
                                                                                            <p className='font-bold text-xl'>
                                                                                                {obj.interest}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }) :
                                                                            <>
                                                                                <p>No Interests Found</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Tabs.Item>
                                                                <Tabs.Item
                                                                    title="Promotional Pictures"
                                                                >
                                                                    <div className='flex justify-between'>
                                                                        <h3 className='font-bold'>
                                                                            Influencer's Pictures (<small>Click to view bigger</small>)
                                                                        </h3>
                                                                    </div>
                                                                    <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mt-4 h-full'>
                                                                        {images.length > 0 ?
                                                                            images.map(obj => {
                                                                                return (
                                                                                    <div className='rounded-lg more-pics' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + imgApi + obj.image + ")" }} onClick={() => { handleClick(obj.image) }}></div>
                                                                                )
                                                                            }) :
                                                                            <>
                                                                                <p> No Pictures Found</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Tabs.Item>
                                                                <Tabs.Item
                                                                    title=" Reviews"
                                                                >
                                                                    <div className='flex justify-between'>
                                                                        <h3 className='font-bold'>
                                                                            Influencer's Reviews
                                                                        </h3>
                                                                    </div>
                                                                    <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mt-4 h-full'>
                                                                        {review.length > 0 ?
                                                                            review.map(obj => {
                                                                                return (
                                                                                    <div className='p-4 rounded-lg border'>
                                                                                        <ReactStars
                                                                                            count={5}
                                                                                            value={obj.star}
                                                                                            size={24}
                                                                                            edit={false}
                                                                                            activeColor="#ffd700"
                                                                                        />
                                                                                        <p>
                                                                                            {obj.review}
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                            }) :
                                                                            <>
                                                                                <p> No Reviews Found</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Tabs.Item>
                                                            </Tabs.Group>
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-span-2 h-full row-span-2'>
                                                        <div className='bg-white p-4 rounded-lg h-full'>
                                                            <h3 className='font-bold'>
                                                                Influencer Stats
                                                            </h3>
                                                            <div className='grid grid-cols-2 mt-4 gap-4'>
                                                                <div className='text-center bg-fourth rounded-lg p-4 col-span-2'>                                                                    
                                                                    {res.audience_location ? res.audience_location : 'NA' }
                                                                    <p className='text-sm mt-2 text-sm font-bold'>Audience Location</p>
                                                                </div>
                                                                <div className='text-center bg-fourth rounded-lg p-4 col-span-2'>
                                                                    <div className='flex justify-center space-x-4'>
                                                                        {res.social_media_audience.length > 0 ? 
                                                                            res.social_media_audience.map(obj => {
                                                                                return(
                                                                                    <>
                                                                                        <div className='p-2 bg-secondary rounded-lg mt-2'>
                                                                                            <p className='text-white'>
                                                                                                <small>
                                                                                                    {obj.category}
                                                                                                </small>
                                                                                            </p>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                            : 
                                                                            <>
                                                                                <p>NA</p>
                                                                            </>
                                                                        }                                                                   
                                                                    </div> 
                                                                    {res.audience_location ? res.audience_location : 'NA' }
                                                                    <p className='text-sm mt-2 text-sm font-bold'>Audience Category</p>
                                                                </div>
                                                                <div className='text-center bg-fourth rounded-lg p-4'>                                                                    
                                                                    {res.applied_jobs > 0 ? res.applied_jobs : 0 }
                                                                    <p className='text-sm mt-2 text-sm font-bold'>Jobs Applied</p>
                                                                </div>
                                                                <div className='text-center bg-fourth rounded-lg p-4'>                                                                    
                                                                    {res.influencer_tasks > 0 ? res.influencer_tasks : 0 }
                                                                    <p className='text-sm mt-2 text-sm font-bold'>Current Campaigns</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className='sm:col-span-3 col-span-6 h-full row-span-1'>
                                                        <div className='bg-white p-4 rounded-lg h-full'>
                                                            <h3 className='font-bold'>
                                                                Target Audience
                                                            </h3>
                                                            <div>
                                                                <PieChart label={socialAudience} data={socialAudienceCount} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='sm:col-span-3 col-span-6 h-full row-span-1'>
                                                        <div className='bg-white p-4 rounded-lg h-full'>
                                                            <h3 className='font-bold'>
                                                                Audience Location
                                                            </h3>
                                                            <div className='map'>
                                                                <Wrapper apiKey={"AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw"}>
                                                                    <MyMapComponent center={socialAudienceLocation} zoom={5} />
                                                                </Wrapper>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                        {/* 
                        {startupData.length !== 0 && startupData.map(res => {
                            return (
                                <>
                                    <div className='flex flex-row w-full sm:space-x-4'>
                                        <div className='basis-full w-full'>
                                            <div className=" w-full block mt-10 border border-gray-200 shadow-md flex items-end user-cover" style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + hero + ")" }}>
                                                <div className='w-full bg-primary user-details user-details-profile'>
                                                    <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                                        <div className='sm:basis-1/2 basis-full flex items-center'>
                                                            <div className='inline-flex profile-img items-center justify-center'>
                                                                <img src={imgApi + res.logo} alt={res.logo} className="border border-secondary" />
                                                            </div>
                                                            <div className='inline-block'>
                                                                <p className='p-5 text-white font-bold'>
                                                                    {res.name}<br />
                                                                    <span className='font-light'><small>{res.location}</small></span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='sm:basis-1/2 basis-full flex items-center justify-end edit-button'>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div className='block w-full bg-third text-white mt-5 border-b p-4 card-header'>
                                                    Bio
                                                </div>
                                                <div className='w-full bg-white p-4 card-body'>
                                                    {res.bio}
                                                </div>
                                            </div>
                                            <div className='flex flex-row flex-wrap sm:flex-nowrap sm:space-x-4 mt-5'>
                                                <div className='sm:basis-1/2 basis-full w-full'>
                                                    <div className='block w-full bg-third text-white border-b p-4 card-header'>
                                                        Personal Details
                                                    </div>
                                                    <div className='bg-white'>
                                                        <div className="overflow-x-auto relative">
                                                            <table className="w-full text-sm text-left text-gray-500">
                                                                <tbody>
                                                                    <tr className="bg-white border-b">
                                                                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                                            Charitable Organisation:
                                                                        </th>
                                                                        <td className="py-4 px-6">
                                                                            {res.charitable ? 'Yes' : 'No'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="bg-white border-b">
                                                                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                                            Business Type:
                                                                        </th>
                                                                        <td className="py-4 px-6">
                                                                            {res.business_type}
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="bg-white border-b">
                                                                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                                            Website:
                                                                        </th>
                                                                        <td className="py-4 px-6">
                                                                            <a href={res.website}>{res.website}</a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="bg-white">
                                                                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                                            Location:
                                                                        </th>
                                                                        <td className="py-4 px-6">
                                                                            {res.location}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='sm:basis-1/2 basis-full w-full sm:mt-0 mt-5'>
                                                    <div className='block w-full bg-third text-white border-b p-4 social card-header'>
                                                        <div className='flex flex-row'>
                                                            <div className='sm:basis-1/2 basis-full'>
                                                                Social Media
                                                            </div>
                                                            <div className='basis-1/2 flex justify-end'>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='bg-white '>
                                                        <div className="overflow-x-auto relative">
                                                            <table className="w-full text-sm text-left text-gray-500">
                                                                <tbody>
                                                                    <tr className="bg-white border-b">
                                                                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                                            Facebook:
                                                                        </th>
                                                                        <td className="py-4 px-4">
                                                                            https://www.instagram.com/reachio21/
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="bg-white border-b">
                                                                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                                            Instagram:
                                                                        </th>
                                                                        <td className="py-4 px-4">
                                                                            https://www.instagram.com/reachio21/
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="bg-white border-b">
                                                                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                                            Twitter:
                                                                        </th>
                                                                        <td className="py-4 px-4">
                                                                            https://www.instagram.com/reachio21/
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="bg-white">
                                                                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                                            LinkedIn:
                                                                        </th>
                                                                        <td className="py-4 px-4">
                                                                            https://www.instagram.com/reachio21/
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })} */}
                    </div>
                </div>
            </div>
        </>
    )
}
