import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import hero from '../../assets/images/case-study/hero.jpg';
import ImageCS11 from '../../assets/images/case-study/cs1/1.jpg';
import ImageCS12 from '../../assets/images/case-study/cs1/2.jpg';
import ImageCS13 from '../../assets/images/case-study/cs1/3.webp';
import ImageCS21 from '../../assets/images/case-study/cs2/1.png';
import ImageCS22 from '../../assets/images/case-study/cs2/2.png';
import ImageCS23 from '../../assets/images/case-study/cs2/3.png';
import ImageCS24 from '../../assets/images/case-study/cs2/4.gif';
import ImageCS31 from '../../assets/images/case-study/cs3/1.jpg';
import ImageCS32 from '../../assets/images/case-study/cs3/2.png';
import ImageCS41 from '../../assets/images/case-study/cs4/1.jpg';
import ImageCS42 from '../../assets/images/case-study/cs4/2.png';
import ImageCS43 from '../../assets/images/case-study/cs4/3.jpg';
import ImageCS51 from '../../assets/images/case-study/cs5/1.png';
import ImageCS52 from '../../assets/images/case-study/cs5/2.jpeg';
import ImageCS53 from '../../assets/images/case-study/cs5/3.jpg';
import ImageCS54 from '../../assets/images/case-study/cs5/4.jpeg';
import ImageCS61 from '../../assets/images/case-study/cs6/1.jpg';
import ImageCS62 from '../../assets/images/case-study/cs6/2.png';
import ImageCS63 from '../../assets/images/case-study/cs6/3.gif';
import ImageCS64 from '../../assets/images/case-study/cs6/4.png';
import ImageCS71 from '../../assets/images/case-study/cs7/1.webp';
import ImageCS72 from '../../assets/images/case-study/cs7/2.jpg';
import ImageCS73 from '../../assets/images/case-study/cs7/3.jpg';
import ImageCS74 from '../../assets/images/case-study/cs7/4.jpg';
import ImageCS75 from '../../assets/images/case-study/cs7/5.jpg';
import ImageCS76 from '../../assets/images/case-study/cs7/6.jpg';
import ImageCS81 from '../../assets/images/case-study/cs8/1.gif';
import ImageCS82 from '../../assets/images/case-study/cs8/2.gif';
import ImageCS83 from '../../assets/images/case-study/cs8/3.jpg';
import { Link } from 'react-router-dom';
import './CaseStudy.style.scss';


const CaseStudy = () => {
    document.title = "Case Study | PROMOTE"

    return (
        <>
            <Header></Header>
            <div id='CaseStudy'>
                <section id='section1' className='section' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + hero + ")" }}>
                    <div className='container mx-auto h-full'>
                        <div className='flex flex-col justify-center items-center h-full w-full'>
                            <div className="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-lg">
                                <h5 className="text-4xl tracking-tight font-extrabold text-center text-primary">Case Studies</h5>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='section2' className='section'>
                    <div className='container mx-auto'>
                        <div className='flex flex-col flex-wrap justify-center items-center h-full w-full'>
                            {/* <h2 className="mb-4 text-3xl tracking-tight font-medium text-center text-black">Checkout our best case studies</h2> */}
                            <div className='flex flex-row flex-wrap w-full'>
                                <div className='sm:basis-1/4 basis-full flex justify-center p-2'>
                                    <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                        <button type="button" data-modal-toggle="CaseStudy1" className='w-full'>
                                            <div className='casestudy cs1' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS11 + ")" }}></div>
                                            <div className="p-5">
                                                <div className='flex flex-col items-center'>
                                                    <img src={ImageCS13} className="mb-5" alt="Hair Virtue Logo" width={150} />
                                                    <h5 className="mb-2 text-black text-center">Hair Virtue London <br></br>Campaign</h5>
                                                </div>
                                            </div>
                                        </button>
                                        <div id="CaseStudy1" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                                            <div className="relative p-4 w-full max-w-7xl h-full">
                                                <div className="relative bg-white rounded-lg shadow">
                                                    <div className="flex justify-between items-start p-6 rounded-t">
                                                        <h3 className="text-xl font-semibold text-black">
                                                            Campaign Details
                                                        </h3>
                                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="CaseStudy1">
                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            <span className="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <hr />
                                                    <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                                        <div className='sm:basis-1/2 basis-full case-study-img'>
                                                            <div id="case-study-carousel" className="relative modal-img-div" data-carousel="static">
                                                                {/* <!-- Carousel wrapper --> */}
                                                                <div className="relative h-full overflow-hidden rounded-lg">
                                                                    {/* <!-- Item 1 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS11} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 2 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS12} className="absolute block" alt="..."/>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Slider controls --> */}
                                                                <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                                                        <span className="sr-only">Previous</span>
                                                                    </span>
                                                                </button>
                                                                <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                                        <span className="sr-only">Next</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='sm:basis-1/2 basis-full py-5'>
                                                            <div className="p-6 border-b">
                                                                <div className='flex justify-center'>
                                                                    <img src={ImageCS13} alt="Hair Virtue London Logo" className="mb-5"/>
                                                                </div>
                                                                <h3 className="text-xl font-semibold text-black mb-5 text-center">
                                                                    Hair Virtue London Campaign
                                                                </h3>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Hair virtue offer hair and self-care products which are made from natural ingredients and are formulated to help trigger hair growth and reduce hair loss.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Their aim on Promote’s platform was twofold they wanted to find an influencer who shared their passion to promote their products to their social media audience. Secondly, they wanted to utilise the influencer’s promotional content for their website showcasing the quality of their products.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Through Promote they found the perfect beauty product influencer who assisted in growing their brand awareness and contributed to greater sales through her static image post on Instagram. Her promotional content was high quality and is featured on their website contributing to their most sold product!
                                                                </p>
                                                            </div>
                                                            <div className='p-6'>
                                                                <div className='flex flex-row  flex-wrap mb-10'>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-users text-primary"></i>
                                                                        <p className='text-primary'>3,070</p>
                                                                        <p><b>Audience</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-heart text-primary"></i>
                                                                        <p className='text-primary'>425</p>
                                                                        <p><b>Likes</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 sm:mt-0 mt-4 text-center'>
                                                                        <i className="fa-solid fa-comment text-primary"></i>
                                                                        <p className='text-primary'>72</p>
                                                                        <p><b>Comments</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-handshake text-primary"></i>
                                                                        <p className='text-primary'>16.3%</p>
                                                                        <p><b>Engagement rate</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-eye text-primary"></i>
                                                                        <p className='text-primary'>1,425</p>
                                                                        <p><b>Impressions</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-percent text-primary"></i>
                                                                        <p className='text-primary'>45.2%</p>
                                                                        <p><b>Impression rate</b></p>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sm:basis-1/4 basis-full flex justify-center p-2'>
                                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md w-full">
                                        <button type="button" data-modal-toggle="CaseStudy2" className='w-full'>
                                            <div className='casestudy cs-2' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS23 + ")" }}></div>
                                            <div className="p-5">
                                                <div className='flex flex-col items-center'>
                                                    <img src={ImageCS21} className="mb-5" alt="Hair Virtue Logo" width={80} />
                                                    <h5 className="mb-2 text-black text-center">House of Henna <br></br>Campaign</h5>
                                                </div>
                                            </div>
                                        </button>
                                        <div id="CaseStudy2" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                                            <div className="relative p-4 w-full max-w-7xl h-full">
                                                <div className="relative bg-white rounded-lg shadow">
                                                    <div className="flex justify-between items-start p-6 rounded-t">
                                                        <h3 className="text-xl font-semibold text-black">
                                                            Campaign Details
                                                        </h3>
                                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="CaseStudy2">
                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            <span className="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <hr />
                                                    <div className='flex flex-row flex-wrap sm:flex-nowrap '>
                                                        <div className='sm:basis-1/2 basis-full case-study-img'>
                                                            <div id="case-study-carousel" className="relative modal-img-div" data-carousel="static">
                                                                {/* <!-- Carousel wrapper --> */}
                                                                <div className="relative h-full overflow-hidden rounded-lg">
                                                                    {/* <!-- Item 1 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS22} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 2 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS24} className="absolute block" alt="..."/>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Slider controls --> */}
                                                                <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                                                        <span className="sr-only">Previous</span>
                                                                    </span>
                                                                </button>
                                                                <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                                        <span className="sr-only">Next</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='sm:basis-1/2 basis-full py-5'>
                                                            <div className="p-6 border-b">
                                                                <div className='flex justify-center'>
                                                                    <img src={ImageCS21} alt="Hair Virtue London Logo" className="mb-5" width={80}/>
                                                                </div>
                                                                <h3 className="text-xl font-semibold text-black mb-5 text-center">
                                                                    House of Henna Campaign
                                                                </h3>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    House of Henna specialise in creating beautiful and unique temporary body art and tattoos using only natural and jagua ink.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    During the summer of 2022 they ran a pop-up shop in John Lewis, Westfield shopping centre. Their aim was to create a buzz and entice new customers to their pop-up shop.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Through Promote they partnered with 5 creators who had a passion for body art. House of Henna gifted our influencers some spectacular hennas in return for posting the results to their Instagram audiences through both Reels and static posts. The results were great which led to lots of new customers for House of Henna.
                                                                </p>
                                                            </div>
                                                            <div className='p-6'>
                                                                <div className='flex flex-row  flex-wrap mb-10'>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-users text-primary"></i>
                                                                        <p className='text-primary'>5</p>
                                                                        <p><b>Number of Influncers</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-heart text-primary"></i>
                                                                        <p className='text-primary'>13,001</p>
                                                                        <p><b>Audience</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 sm:mt-0 mt-4 text-center'>
                                                                        <i className="fa-solid fa-comment text-primary"></i>
                                                                        <p className='text-primary'>1,021</p>
                                                                        <p><b>Likes</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-handshake text-primary"></i>
                                                                        <p className='text-primary'>3.3%</p>
                                                                        <p><b>Engagement rate</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sm:basis-1/4 basis-full flex justify-center p-2'>
                                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md w-full">
                                        <button type="button" data-modal-toggle="CaseStudy5" className='w-full'>
                                            <div className='casestudy cs-5' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS31 + ")" }}></div>
                                            <div className="p-5">
                                                <div className='flex flex-col items-center'>
                                                    <img src={ImageCS32} className="mb-2" alt="Hair Virtue Logo" style={{height:"85px"}} />
                                                    <h5 className="mb-2 text-black text-center">Aquajoy Wetsuits <br></br>Campaign</h5> 
                                                </div>
                                            </div>
                                        </button>
                                        <div id="CaseStudy5" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                                            <div className="relative p-4 w-full max-w-7xl h-full">
                                                <div className="relative bg-white rounded-lg shadow">
                                                    <div className="flex justify-between items-start p-6 rounded-t">
                                                        <h3 className="text-xl font-semibold text-black">
                                                            Campaign Details
                                                        </h3>
                                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="CaseStudy5">
                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            <span className="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <hr />
                                                    <div className='flex flex-row flex-wrap sm:flex-nowrap '>
                                                        <div className='sm:basis-1/2 basis-full case-study-img'>
                                                            <div id="case-study-carousel" className="relative modal-img-div" data-carousel="static">
                                                                {/* <!-- Carousel wrapper --> */}
                                                                <div className="relative h-full overflow-hidden rounded-lg">
                                                                    {/* <!-- Item 1 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS31} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 2 --> */}
                                                                       <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS31} className="absolute block" alt="..."/>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Slider controls --> */}
                                                                <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                                                        <span className="sr-only">Previous</span>
                                                                    </span>
                                                                </button>
                                                                <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                                        <span className="sr-only">Next</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='sm:basis-1/2 basis-full py-5'>
                                                            <div className="p-6 border-b">
                                                                <div className='flex justify-center'>
                                                                    <img src={ImageCS32} alt="Hair Virtue London Logo" className="mb-5" style={{height:"85px"}}/>
                                                                </div>

                                                                <h3 className="text-xl font-semibold text-black mb-5 text-center">
                                                                    Aquajoy Campaign
                                                                </h3>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Aquajoy produce floral patterned wetsuits for babies aged 0-3 years old. The wetsuits offer sun protection, warmth and buoyancy.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Their aim was to increase their sales throughout the UK by infiltrating the ‘parent’ influencer networks on Instagram. The hope was to increase their brand awareness just before the summer season.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    On Promote, they collaborated with a mum influencer who was involved with multiple parent influencer networks and was even the administrator for a couple of groups. She posted two static photos of the wetsuits on separate dates to prolong the brands exposure. The static images had a great response from the followers leading to an increase in summer sales!
                                                                </p>
                                                            </div>
                                                            <div className='p-6'>
                                                                <div className='flex flex-row  flex-wrap mb-10'>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-users text-primary"></i>
                                                                        <p className='text-primary'>8,894</p>
                                                                        <p><b>Audience</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-heart text-primary"></i>
                                                                        <p className='text-primary'>1,324</p>
                                                                        <p><b>Likes</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 sm:mt-0 mt-4 text-center'>
                                                                        <i className="fa-solid fa-comment text-primary"></i>
                                                                        <p className='text-primary'>22</p>
                                                                        <p><b>Comments</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-handshake text-primary"></i>
                                                                        <p className='text-primary'>14.9%</p>
                                                                        <p><b>Engagement rate</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sm:basis-1/4 basis-full flex justify-center p-2'>
                                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md w-full">
                                        <button type="button" data-modal-toggle="CaseStudy6" className='w-full'>
                                            <div className='casestudy cs-6 bg-no-repeat bg-cover' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS41 + ")" }}></div>
                                            <div className="p-5">
                                                <div className='flex flex-col items-center'>
                                                    <img src={ImageCS42} className="mb-2" alt="Hair Virtue Logo" width={80} />
                                                    <h5 className="mb-2 text-black text-center">Loyalty Sunglasses <br></br>Campaign</h5>
                                                </div>
                                            </div>
                                        </button>
                                        <div id="CaseStudy6" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                                            <div className="relative p-4 w-full max-w-7xl h-full">
                                                <div className="relative bg-white rounded-lg shadow">
                                                    <div className="flex justify-between items-start p-6 rounded-t">
                                                        <h3 className="text-xl font-semibold text-black">
                                                           Campaign Details
                                                        </h3>
                                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="CaseStudy6">
                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            <span className="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <hr />
                                                    <div className='flex flex-row flex-wrap sm:flex-nowrap '>
                                                        <div className='sm:basis-1/2 basis-full case-study-img'>
                                                            <div id="case-study-carousel" className="relative modal-img-div" data-carousel="static">
                                                                {/* <!-- Carousel wrapper --> */}
                                                                <div className="relative h-full overflow-hidden rounded-lg">
                                                                    {/* <!-- Item 1 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS41} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 2 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS43} className="absolute block" alt="..."/>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Slider controls --> */}
                                                                <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                                                        <span className="sr-only">Previous</span>
                                                                    </span>
                                                                </button>
                                                                <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                                        <span className="sr-only">Next</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='sm:basis-1/2 basis-full py-5'>
                                                            <div className="p-6 border-b">
                                                                <div className='flex justify-center'>
                                                                    <img src={ImageCS42} className="mb-2" alt="Hair Virtue Logo" width={80} />
                                                                </div>

                                                                <h3 className="text-xl font-semibold text-black mb-5 text-center">
                                                                    Loyalty Campaign
                                                                </h3>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Loyalty sunglass chains are a new company that offers three different chain designs. They are perfect for holiday makers and compliment every type of sunglasses.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Their aim was to locate lots of travel and lifestyle influencers to boost the launch of their new company. They were after influencers who loved wearing and posing in their sunglasses.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Through Promote, they have managed to collaborate with multiple travel influencers and lifestyle bloggers. The influencers have produced a mixture of static and Reel contents pieces from their gifted products for their Instagram audiences. The larger number of influencers has allowed them to have a vast customer base. Their promotion is still ongoing!
                                                                </p>
                                                            </div>
                                                            <div className='p-6'>
                                                                <div className='flex flex-row flex-wrap mb-10'>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-user text-primary"></i>
                                                                        <p className='text-primary'>7</p>
                                                                        <p><b>Number of Influencers</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-users text-primary"></i>
                                                                        <p className='text-primary'>38,889</p>
                                                                        <p><b>Audience</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-heart text-primary"></i>
                                                                        <p className='text-primary'>1,618</p>
                                                                        <p><b>Likes</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-comment text-primary"></i>
                                                                        <p className='text-primary'>121</p>
                                                                        <p><b>Comments</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-handshake text-primary"></i>
                                                                        <p className='text-primary'>4.5%</p>
                                                                        <p><b>Engagement rate</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sm:basis-1/4 basis-full mt-4 sm:mt-0 flex justify-center p-2'>
                                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md w-full">
                                        <button type="button" data-modal-toggle="CaseStudy7" className='w-full'>
                                            <div className='casestudy cs-7' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS72 + ")" }}></div>
                                            <div className="p-5">
                                                <div className='flex flex-col items-center'>
                                                    <img src={ImageCS71} className="mb-5" alt="Inspired Images Logo" height={75}/>
                                                    <h5 className="mb-2 text-black text-center">Caley Styles <br></br>Campaign</h5>
                                                </div>
                                            </div>  
                                        </button>
                                        <div id="CaseStudy7" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                                            <div className="relative p-4 w-full max-w-7xl h-full">
                                                <div className="relative bg-white rounded-lg shadow">
                                                    <div className="flex justify-between items-start p-6 rounded-t">
                                                        <h3 className="text-xl font-semibold text-black">
                                                            Campaign Details
                                                        </h3>
                                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="CaseStudy7">
                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            <span className="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <hr />
                                                    <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                                        <div className='sm:basis-1/2 basis-full case-study-img'>
                                                            <div id="case-study-carousel" className="relative modal-img-div" data-carousel="static">
                                                                {/* <!-- Carousel wrapper --> */}
                                                                <div className="relative h-full overflow-hidden rounded-lg">
                                                                    {/* <!-- Item 1 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS74} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 2 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS73} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 3 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS75} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 4 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS76} className="absolute block" alt="..."/>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Slider controls --> */}
                                                                <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                                                        <span className="sr-only">Previous</span>
                                                                    </span>
                                                                </button>
                                                                <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                                        <span className="sr-only">Next</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='sm:basis-1/2 basis-full py-5'>
                                                            <div className="p-6 border-b">
                                                                <div className='flex justify-center'>
                                                                    <img src={ImageCS71} className="mb-5" alt="Inspired Images Logo" width={150}/>
                                                                </div>
                                                                <h3 className="text-xl font-semibold text-black mb-5 text-center">
                                                                    Caley Styles Campaign
                                                                </h3>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Caley Styles is an up-and-coming womenswear ecommerce store that offer a wide range of quality items. Their goal is to make their customers look great whilst feeling beautiful!
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Their aim was to improve their presence in the UK through influencer marketing. Plus, they wanted user generated content to place on their website to give their customers a more realistic depiction of their clothing line.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Through Promote’s platform they were partnered with two womenswear influencers who shared their passion for fashion. Caley Styles gifted the influencers an item of clothing from their store in return for producing a static post of themselves modelling the item. The promotion was a great success and Caley Styles’ presence in the UK has risen, plus the influencers’ content is now featured on their website!
                                                                </p>
                                                            </div>
                                                            <div className='p-6'>
                                                                <div className='flex flex-row  flex-wrap mb-10'>
                                                                    <div className='sm:basis-1/3 basis-1/2 sm:mt-0 mt-4 text-center'>
                                                                        <i className="fa-solid fa-comment text-primary"></i>
                                                                        <p className='text-primary'>2</p>
                                                                        <p><b>Number of Influencers</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-users text-primary"></i>
                                                                        <p className='text-primary'>14,892</p>
                                                                        <p><b>Audience</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-heart text-primary"></i>
                                                                        <p className='text-primary'>842</p>
                                                                        <p><b>Likes</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-handshake text-primary"></i>
                                                                        <p className='text-primary'>5.7%</p>
                                                                        <p><b>Engagement rate</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sm:basis-1/4 basis-full mt-4 sm:mt-0 flex justify-center p-2'>
                                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md w-full">
                                        <button type="button" data-modal-toggle="CaseStudy8" className='w-full'>
                                            <div className='casestudy cs-8' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS81 + ")" }}></div>
                                            <div className="p-5">
                                                <div className='flex flex-col items-center'>
                                                    <img src={ImageCS83} className="mb-5" alt="Inspired Images Logo" width={75} />
                                                    <h5 className="mb-2 text-black text-center">Neikid <br></br>Campaign</h5>
                                                </div>
                                            </div>  
                                        </button>
                                        <div id="CaseStudy8" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                                            <div className="relative p-4 w-full max-w-7xl h-full">
                                                <div className="relative bg-white rounded-lg shadow">
                                                    <div className="flex justify-between items-start p-6 rounded-t">
                                                        <h3 className="text-xl font-semibold text-black">
                                                            Campaign Details
                                                        </h3>
                                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="CaseStudy8">
                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            <span className="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <hr />
                                                    <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                                        <div className='sm:basis-1/2 basis-full case-study-img'>
                                                            <div id="case-study-carousel" className="relative modal-img-div" data-carousel="static">
                                                                {/* <!-- Carousel wrapper --> */}
                                                                <div className="relative h-full overflow-hidden rounded-lg">
                                                                    {/* <!-- Item 1 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS81} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 2 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS82} className="absolute block" alt="..."/>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Slider controls --> */}
                                                                <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                                                        <span className="sr-only">Previous</span>
                                                                    </span>
                                                                </button>
                                                                <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                                        <span className="sr-only">Next</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='sm:basis-1/2 basis-full py-5'>
                                                            <div className="p-6 border-b">
                                                                <div className='flex justify-center'>
                                                                    <img src={ImageCS83} className="mb-5" alt="Inspired Images Logo" width={75} />
                                                                </div>
                                                                <h3 className="text-xl font-semibold text-black mb-5 text-center">
                                                                    Neikid beauty Campaign
                                                                </h3>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Neikid beauty are a beauty brand based in Balham and offer lash and brow treatments that will reduce your desire to wear make-up and help you feel your best at any time of day.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Their aim was to find local influencers who could help encourage their following to start receiving Neikid’s treatments. Additionally, for their Instagram page they wanted detailed footage of how the treatments are performed.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Through Promote they managed to team up with 5 beauty influencers all living in the surrounding areas! 2 out of the 5 influencers have so far completed the promotion by producing Reels of them receiving a complementary treatment from Neikid. Neikid are extremely happy with the content so far and have placed it on their Instagram page.
                                                                </p>
                                                            </div>
                                                            <div className='p-6'>
                                                                <div className='flex flex-row flex-wrap mb-10'>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-user text-primary"></i>
                                                                        <p className='text-primary'>2</p>
                                                                        <p><b>Number of Influencers</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-users text-primary"></i>
                                                                        <p className='text-primary'>8,733</p>
                                                                        <p><b>Audience</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-eye text-primary"></i>
                                                                        <p className='text-primary'>4,446</p>
                                                                        <p><b>Impressions</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-percent text-primary"></i>
                                                                        <p className='text-primary'>51%</p>
                                                                        <p><b>Impression rate</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-heart text-primary"></i>
                                                                        <p className='text-primary'>182</p>
                                                                        <p><b>Likes</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-comment text-primary"></i>
                                                                        <p className='text-primary'>39</p>
                                                                        <p><b>Comments</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2  sm:mt-4 mt-4 text-center'>
                                                                        <i className="fa-solid fa-handshake text-primary"></i>
                                                                        <p className='text-primary'>5%</p>
                                                                        <p><b>Engagement rate</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sm:basis-1/4 basis-full mt-4 sm:mt-0 flex justify-center p-2'>
                                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md w-full">
                                        <button type="button" data-modal-toggle="CaseStudy4" className='w-full'>
                                            <div className='casestudy cs-4' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS51 + ")" }}></div>
                                            <div className="p-5">
                                                <div className='flex flex-col items-center'>
                                                    <img src={ImageCS54} className="mb-5" alt="Parker PA Logo" width={75} />
                                                    <h5 className="mb-2 text-black text-center">Parker PA <br></br>Campaign</h5>
                                                </div>
                                            </div>
                                        </button>
                                        <div id="CaseStudy4" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                                            <div className="relative p-4 w-full max-w-7xl h-full">
                                                <div className="relative bg-white rounded-lg shadow">
                                                    <div className="flex justify-between items-start p-6 rounded-t">
                                                        <h3 className="text-xl font-semibold text-black">
                                                            Campaign Details
                                                        </h3>
                                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="CaseStudy4">
                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            <span className="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <hr />
                                                    <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                                        <div className='sm:basis-1/2 basis-full case-study-img'>
                                                            <div id="case-study-carousel" className="relative modal-img-div" data-carousel="static">
                                                                {/* <!-- Carousel wrapper --> */}
                                                                <div className="relative h-full overflow-hidden rounded-lg">
                                                                    {/* <!-- Item 1 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS51} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 2 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS52} className="absolute block" alt="..."/>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Slider controls --> */}
                                                                <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                                                        <span className="sr-only">Previous</span>
                                                                    </span>
                                                                </button>
                                                                <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                                        <span className="sr-only">Next</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='sm:basis-1/2 basis-full py-5'>
                                                            <div className="p-6 border-b">
                                                                <img src={ImageCS54} className="mb-5" alt="Parker PA Logo" width={75} />

                                                                <h3 className="text-xl font-semibold text-black mb-5">
                                                                    Parker PA Campaign
                                                                </h3>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Parker PA are a virtual assistant service that includes businesses PA support, business support, sales support and Inbox detox.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Their aim was to reach out to lots small/medium sized businesses promoting the benefits of using a virtual assistant. Parker PA were after an influencer who worked in a professional environment and had a engaged LinkedIn audience.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Through Promote, they managed to find a LinkedIn influencer who was a director at Marine Diaries and an executive at Carbon Brief and had lots of business connections through networking. The promotion included a detailed and eye-opening insight into the benefits of virtual assistants in return for a cash reward.
                                                                </p>
                                                            </div>
                                                            <div className='p-6'>
                                                                <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-users text-primary"></i>
                                                                        <p className='text-primary'>500+</p>
                                                                        <p><b>Audience</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-heart text-primary"></i>
                                                                        <p className='text-primary'>16</p>
                                                                        <p><b>Likes</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-full text-center'>
                                                                        <i className="fa-solid fa-handshake text-primary"></i>
                                                                        <p className='text-primary'>4%</p>
                                                                        <p><b>Engagement rate</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sm:basis-1/4 basis-full mt-4 sm:mt-0 flex justify-center p-2'>
                                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md w-full">
                                        <button type="button" data-modal-toggle="CaseStudy3" className='w-full'>
                                            <div className='casestudy cs-3' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS63 + ")" }}></div>
                                            <div className="p-5">
                                                <div className='flex flex-col items-center'>
                                                    <img src={ImageCS64} className="mb-5" alt="Inspired Images Logo" width={75} />
                                                    <h5 className="mb-2 text-black text-center">Inspired Images <br></br>Campaign</h5>
                                                </div>
                                            </div>  
                                        </button>
                                        <div id="CaseStudy3" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                                            <div className="relative p-4 w-full max-w-7xl h-full">
                                                <div className="relative bg-white rounded-lg shadow">
                                                    <div className="flex justify-between items-start p-6 rounded-t">
                                                        <h3 className="text-xl font-semibold text-black">
                                                            Campaign Details
                                                        </h3>
                                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="CaseStudy3">
                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            <span className="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <hr />
                                                    <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                                        <div className='sm:basis-1/2 basis-full case-study-img'>
                                                            <div id="case-study-carousel" className="relative modal-img-div" data-carousel="static">
                                                                {/* <!-- Carousel wrapper --> */}
                                                                <div className="relative h-full overflow-hidden rounded-lg">
                                                                    {/* <!-- Item 1 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS62} className="absolute block" alt="..."/>
                                                                    </div>
                                                                    {/* <!-- Item 2 --> */}
                                                                    <div className="hidden duration-700 ease-in-out flex justify-center items-center" data-carousel-item>
                                                                        <img src={ImageCS63} className="absolute block" alt="..."/>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Slider controls --> */}
                                                                <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                                                        <span className="sr-only">Previous</span>
                                                                    </span>
                                                                </button>
                                                                <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                                                    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-secondary">
                                                                        <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                                        <span className="sr-only">Next</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='sm:basis-1/2 basis-full py-5'>
                                                            <div className="p-6 border-b">
                                                                <div className='flex justify-center'>
                                                                    <img src={ImageCS64} className="mb-5" alt="Inspired Images Logo" width={75} />
                                                                </div>
                                                                <h3 className="text-xl font-semibold text-black mb-5 text-center">
                                                                    Inspired Images Campaign
                                                                </h3>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Inspired images are a small business on Etsy that create beautifully framed artistic prints which are used to create affordable greeting cards.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    Their campaign aim was to find an influencer who could create a greater brand awareness for their products before the Christmas period began.
                                                                </p>
                                                                <p className="text-base leading-relaxed text-black mb-3">
                                                                    On Promote, they found an influencer with a large following who had a passion for arts and craft businesses. The influencer created a unwrapping, reveal reel on Instagram showcasing multiple gifted Inspired Image card designs. The reel performed strongly, creating a whole new customer base for Inspired Images.
                                                                </p>
                                                            </div>
                                                            <div className='p-6'>
                                                                <div className='flex flex-wrap sm:flex-nowrap flex-row'>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-users text-primary"></i>
                                                                        <p className='text-primary'>25,200</p>
                                                                        <p><b>Audience</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-1/2 text-center'>
                                                                        <i className="fa-solid fa-eye text-primary"></i>
                                                                        <p className='text-primary'>3,769</p>
                                                                        <p><b>Impressions</b></p>
                                                                    </div>
                                                                    <div className='sm:basis-1/3 basis-full text-center'>
                                                                        <i className="fa-solid fa-percent text-primary"></i>
                                                                        <p className='text-primary'>45.2%</p>
                                                                        <p><b>Impression rate</b></p>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer></Footer>
        </>
    );
}

export default CaseStudy;