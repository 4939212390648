import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import { useStepperContext } from "../../../contexts/StepperContext";
import CheckboxButton from "../../../components/CheckboxButtons/CheckboxButtons";


function Content({heading, subHeading, apiName}){
    const { userData, setUserData } = useStepperContext();
    const [contentData, setContentData] = useState([]);
    const [userContent, setuserContent] = useState([]);

    async function getContentData() {
        try {
            await axios.get(ApiUrl + 'collaboration_type/', {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => setContentData(response.data))
            .catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }
    
    const handleChange = (e) => {
        const { id, checked } =  e.target

        if(checked) {
            setuserContent([...userContent, contentData.at(id)])
        } else {
            userContent.splice(userContent.indexOf(id), 1);
        }
    };

    useEffect(() => {
        getContentData();
    }, [])
    
    useEffect(() => {
        setUserData({ ...userData, [apiName]:userContent });
    }, [userContent])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        {subHeading}
                    </small>
                </p>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card">
                        <CheckboxButton
                        inputName={'content'}
                        apiName={'type'}
                        apiName2={'type'}
                        onChange={handleChange}
                        options={contentData}
                        ></CheckboxButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;