import { useState, useEffect, useContext } from "react";
import { useStepperContext } from "../../../contexts/StepperContext";
import axios from "axios";
import TokenService from "../../../API/token.service";
import secureLocalStorage from "react-secure-storage";
import { ApiUrl } from "../../../API/Api.config";

function Complete({heading, redirect, closeBtn, apiName, influencer}) {
    const { userData, setUserData } = useStepperContext();
    const token = TokenService.getLocalAccessToken()
    const [error, setError] = useState(false)

    async function userInfoAPI(data) {

        try {
            await axios.post(ApiUrl + apiName, data,
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response) {
                    if(influencer == 0) {
                        secureLocalStorage.setItem("profile_completed", true)
                    }
                    setTimeout(function(){
                       document.getElementById('closeBtn').style.display = "block"
                    }, 2000);
                })
                .catch(function (error) {
                    setTimeout(function(){
                        document.getElementById('closeBtn').style.display = "block"
                    }, 2000);
                    // console.log(error.response.data)
                    setError(true)
                })
        } catch (error) {
            setTimeout(function(){
                document.getElementById('closeBtn').style.display = "block"
            }, 2000);
            // console.log(error.response.data)
            setError(true)
        }
    };

    

    useEffect(() => {
        userInfoAPI(userData)
    })

    // useEffect(() => {
    // },[])

    return (
        <div className="container md:mt-10">
            <div className="flex flex-col items-center">
                <div className="wrapper">
                    <svg
                        className="checkmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                    >
                        <circle
                            className="checkmark__circle"
                            cx="26"
                            cy="26"
                            r="25"
                            fill="none"
                        />
                        <path
                            className="checkmark__check"
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                    </svg>
                </div>
                {error == false? 
                <>
                    <div className="mt-3 text-xl font-semibold uppercase text-primary">
                        Congratulations!
                    </div>
                    <div className="text-lg font-semibold text-gray-500 text-center mt-4">
                       {heading}
                    </div>
                    <a className="mt-10" href={redirect}>
                        <button id="closeBtn" className="hidden h-10 px-5 text-green-700 transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-green-500 hover:text-green-100">
                            {closeBtn}
                        </button>
                    </a>
                </>
                :               
                <>
                    <div className="mt-3 text-xl font-semibold uppercase text-red-500">
                        Error
                    </div>
                    <div className="text-lg font-semibold text-gray-500 text-center mt-4">
                        There was a problem in submitting your information,<br/> kindly try again later or contact us at <a href="mailto:support@promote.io" className="text-primary">support@promote.io</a>
                    </div>
                    <a className="mt-10" href="/logout">
                        <button id="closeBtn" className="hidden h-10 px-5 text-green-700 transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-green-500 hover:text-green-100">
                            Close
                        </button>
                    </a>
                </>}
            </div>
      </div>
    );
}

export default Complete;