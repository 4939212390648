import { useEffect, useState } from 'react';
import influencer from '../../assets/images/home/influencer.jpg';
import startup from '../../assets/images/home/startup.jpg';
import secureLocalStorage from "react-secure-storage";
import { Link } from 'react-router-dom';
import './Home.style.scss';
import Logo1 from '../../assets/images/home/company/logo-1.png'
import Logo2 from '../../assets/images/home/company/logo-2.png'
import Logo3 from '../../assets/images/home/company/logo-3.png'
import Logo4 from '../../assets/images/home/company/logo-4.png'
import Logo5 from '../../assets/images/home/company/logo-5.png'
import Logo6 from '../../assets/images/home/company/logo-6.png'
import Logo7 from '../../assets/images/home/company/logo-7.webp'
import CookieConsent from "react-cookie-consent";

const HomePage = () => {
    document.title = "Home | PROMOTE"

    return (
        <>
            {/* <Header /> */}
            <div id='Home'>
                <section id='section1' className='section bg-primary'>
                    <div className='container h-full'>
                        <div className='flex flex-col justify-center items-center h-full w-full'>
                            <div className="role-card block w-full bg-white rounded-lg border border-gray-200 shadow-md">
                                <div className='w-full'>
                                    <p className='welcome-text text-center'>Welcome to</p><h1 className='text-center text-primary'>PROMOTE</h1>
                                    <div className='flex lg:flex-row flex-col w-full all-roles'>
                                        <div className='lg:basis-1/2 basis-full section-1-img image-1' style={{ backgroundImage: "linear-gradient(rgba(125, 129, 95, 0.3), rgba(0, 0, 0)), url(" + influencer + ")" }}>
                                            <div className='flex flex-col justify-center items-center h-full'>
                                                <p className='text-white role-text'>
                                                    I am an
                                                </p>
                                                <h2 className='text-white influencer-text'>
                                                    Influencer
                                                </h2>
                                                <div className='learn-more-inf'>
                                                    <button type="button" className="text-gray-900 flex justify-center bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm mt-2" onClick={()=>secureLocalStorage.setItem("role_web", 1)}>
                                                        <a href="/influencer" className='text-primary flex items-center h-full px-5 py-2'>
                                                            Start earning today!
                                                        </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='lg:basis-1/2 basis-full section-1-img image-2' style={{ backgroundImage: "linear-gradient(rgba(125, 129, 95, 0.3), rgba(0, 0, 0)), url(" + startup + ")" }}>
                                            <div className='flex flex-col justify-center items-center h-full'>
                                                <p className='text-white role-text'>
                                                    We are a
                                                </p>
                                                <h2 className='text-white startup-text'>
                                                    Business
                                                </h2>
                                                <div className='learn-more-srt'>
                                                    <button type="button" className="text-gray-900 bg-white flex justify-center border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm mt-2" onClick={()=>secureLocalStorage.setItem("role_web", 2)}>
                                                        <a href="/business" className='text-primary flex items-center h-full px-5 py-2'>
                                                            Join the new era of marketing
                                                        </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-10'>
                                        <h2 className='text-center trusted-by-text'>
                                            <b>
                                                TRUSTED
                                            </b>
                                        </h2>
                                        <p className='text-center trusted-by-text-sec'>
                                            <span className='text-primary'>
                                                <b>
                                                    by
                                                </b>
                                            </span>
                                        </p>
                                    </div>
                                    <div className='relative marquee-container sm:none block '>
                                        <div className="marquee flex justify-around">
                                            <span className='flex items-center carousel-img'><img src={Logo1} alt="Parker PA Logo" className='logo-img logo-img-1'/></span>
                                            <span className='flex items-center carousel-img'><img src={Logo2} alt="Skedadle Logo" className='logo-img'/></span>
                                            <span className='flex items-center carousel-img'> <img src={Logo3} alt="breakbottle Logo" className='logo-img'/></span>
                                            <span className='flex items-center carousel-img'><img src={Logo4} alt="KWM Wealth Logo" className='logo-img'/></span>
                                            <span className='flex items-center carousel-img'><img src={Logo5} alt="AquaJoy Logo" className='logo-img'/></span>
                                            {/* <span className='flex items-center bg-black'><img src={Logo6} alt="Ambriel Logo" className='logo-img'/></span>
                                            <span className='flex items-center'><img src={Logo7} alt="Simi Logo" className='logo-img'/></span> */}
                                        </div>
                                        <div className=" marquee2 flex justify-around">
                                            <span className='flex items-center carousel-img'><img src={Logo1} alt="Parker PA Logo" className='logo-img logo-img-1'/></span>
                                            <span className='flex items-center carousel-img'><img src={Logo2} alt="Skedadle Logo" className='logo-img'/></span>
                                            <span className='flex items-center carousel-img'> <img src={Logo3} alt="breakbottle Logo" className='logo-img'/></span>
                                            <span className='flex items-center carousel-img'><img src={Logo4} alt="KWM Wealth Logo" className='logo-img'/></span>
                                            <span className='flex items-center carousel-img'><img src={Logo5} alt="AquaJoy Logo" className='logo-img'/></span>
                                            {/* <span className='flex items-center bg-black'><img src={Logo6} alt="Ambriel Logo" className='logo-img'/></span>
                                            <span className='flex items-center'><img src={Logo7} alt="Simi Logo" className='logo-img'/></span> */}
                                        </div>




                                        {/* <div className='flex flex-wrap'>
                                            <div className=' basis-1/2 sm:basis-1/5 flex justify-center items-center'>
                                                <img src={Logo1} alt="Parker PA Logo" className='logo-img logo-img-1'/>
                                            </div>
                                            <div className=' basis-1/2 sm:basis-1/5 flex justify-center items-center'>
                                                <img src={Logo2} alt="Skedadle Logo" className='logo-img'/>
                                            </div>
                                            <div className=' basis-1/2 sm:basis-1/5 flex justify-center items-center'>
                                                <img src={Logo3} alt="breakbottle Logo" className='logo-img'/>
                                            </div>
                                            <div className=' basis-1/2 sm:basis-1/5 flex justify-center items-center'>
                                                <img src={Logo4} alt="KWM Wealth Logo" className='logo-img'/>
                                            </div>
                                            <div className=' basis-1/2 sm:basis-1/5 flex justify-center items-center'>
                                                <img src={Logo5} alt="AquaJoy Logo" className='logo-img'/>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CookieConsent buttonStyle={{ backgroundColor: "#008e95", fontSize: "13px" }}>This website uses cookies to enhance the user experience.</CookieConsent>
            </div>
        </>
    );
}

export default HomePage;