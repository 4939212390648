import { useEffect, useState } from "react";
import Header from "../../components/DashboardHeader/DashboardHeader";
import Sidebar from '../../components/Sidebar/Sidebar';
import TokenService from "../../API/token.service";
import Dropzone from "../../components/Dropzone/Dropzone";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useForm } from "react-hook-form";
import axios from "axios";
import { ApiUrl, imgApi } from "../../API/Api.config";
import './UserInfo.style.scss';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import secureLocalStorage from "react-secure-storage";
import { useNavigate, useLocation } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import chroma from "chroma-js";
import { Tabs } from 'flowbite-react'

export default function UpdateStartupInfo() {
    document.title = "Update Profile | Promote"
   
    const token = TokenService.getLocalAccessToken()
    const prevUsername = secureLocalStorage.getItem("username");
    const [data, setData] = useState([]);
    const [username, setUsername] = useState("");
    const nav = useNavigate()
    const [file, setFile] = useState(null);
    const getData = useLocation();
    const [ pp, setpp ] = useState("");
    const overlayStyle = { width: '50%' }
    const contentStyle = window.innerWidth > 768 ? { width: '30%' } : { width: '85%' };
    const position = window.innerWidth > 768 ? 'right center' : 'center';
    const MySwal = withReactContent(Swal)

    async function StartupInfo() {
        try {
            axios.get(ApiUrl + 'startup_info/', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    if (response.data !== null) {
                        setData(response.data)
                        console.log(response.data)
                    } else if (response.status == 401) {
                        nav('login/')
                    }
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }


    async function UpdateStartupUsername(username) {
        try {
            axios.get(ApiUrl + `change_username/?first_name=${username}`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your Profile has been updated',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }else{
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was a problem updating your profile',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function StartupUpdateInfo(data) {
        try {
            axios.post(ApiUrl + 'startup_info/', data, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    if (response.status === 200) {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'success',
                            title: 'Your Profile has been updated',
                            showConfirmButton: false,
                            timer: 3000,
                        })

                        secureLocalStorage.setItem("profile_completed", true)

                    }
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                    else {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was a problem updating your profile',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }


    const handleSelectCharitable = (e) => {
        const selected = e.target.value;
        setValue('charitable', selected)
    }

    async function StartupUpdateInfoApi(data) {
        try {
            axios.patch(ApiUrl + 'startup_info/', data, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    if (response.status === 201) {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'success',
                            title: 'Your Profile has been updated',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                        setTimeout(function(){
                            nav('/user-info');
                        }, 3000);
                        secureLocalStorage.setItem("profile_completed", true)

                    }
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                    else{
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was a problem updating your profile',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    // useEffect(() => {
    //     if (getData) {
    //         getBase64FromUrl(imgApi + getData.state?.logo)
    //     }
    // }, [getData]);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const handleSubmitForm = (data) => {
        StartupUpdateInfoApi(data)
    }


    useEffect(() => {
        StartupInfo()
    }, [])

    useEffect(()=>{
        if(pp !== '') {
            setValue('logo', pp)
        }
    },[pp])

    useEffect(() => {
        if (getData) {
            setValue('charitable', getData.state?.charitable)
            setValue('location', getData.state?.location)
        }
    }, [getData]);

    const updateUsername = (e)=>{
        e.preventDefault()
        UpdateStartupUsername(username)
    }

    useEffect(()=> {
        setUsername(prevUsername)
    }, [])

    return (
        <>
            <Header />
            <Sidebar />
            <div className="sm:ml-64 ml-0 mt-20 user-edit">
                <div className="container mx-auto ">
                    <div className="relative flex flex-col mt-10  overflow-hidden max-h-full">
                        <h2 className="p-4 pl-0 text-2xl font-bold">Update Profile</h2>
                        {/* <div className='mb-3 p-2 rounded-lg text-white text-center text-sm bg-primary sm:w-[240px] w-full'>
                            <Popup overlayStyle="w-10" trigger={() => <button className='text-white' id="connectBtn"><b className="text-white">Change Your Business Name</b> </button>}
                                {...{ overlayStyle, contentStyle }}
                                
                                position={position}>
                                <h1 className="p-1">Note: The Name Should Be Available </h1>
                                <form>
                                    <div>
                                        <input DefualtValue={"Hi, it would be great to collaborate"} className="rounded-l-lg p-1 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white" onChange={(e) => setUsername(e.target.value)} />
                                        <button onClick={(e)=>updateUsername(e)}
                                            className="px-4 rounded-r-lg bg-primary text-white font-bold p-1 uppercase border-t border-b border-r"
                                        >Change</button>
                                    </div>
                                </form>
                            </Popup>
                        </div> */}

                        <Tabs.Group
                            aria-label="Update profile tabs"
                            className="tab-btn"
                            style="underline"
                            >
                            <Tabs.Item 
                            title="Profile"
                            active={true}
                            >
                                <div className="bg-secondary p-4 rounded-t-md text-white">
                                    <h3 className="font-bold text-white">
                                        Basic Information
                                    </h3>
                                </div>
                                <div className="w-full p-6 bg-white rounded-t-md ">
                                    <form className="" onSubmit={handleSubmit(handleSubmitForm)} >
                                        <div className="p-6 border ">
                                            <Dropzone setpp={setpp} oldImage={getData.state?.logo}></Dropzone>
                                        </div>
                                        <hr className="mb-4" />
                                        <div className="flex flex-row sm:space-x-4 flex-wrap sm:flex-nowrap">
                                            <div className="sm:basis-1/2 basis-full">
                                                <div>
                                                    <div className="mt-2">
                                                        <label forHTML="charitable" className="block text-black font-bold">Bio</label>
                                                        <textarea name="bio" defaultValue={getData.state?.bio} {...register('bio')} cols="30" rows="11" className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm:basis-1/2 basis-full">
                                                <div>
                                                    <div className="mt-2">
                                                        <label forHTML="business_type" className="block text-black font-bold">Business Type</label>
                                                        <input type="text" defaultValue={getData.state?.business_type} name="business_type" {...register('business_type')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                    </div>
                                                    <div className="mt-2">
                                                        <label forHTML="website" className="block text-black font-bold">Website</label>
                                                        <input type="text" defaultValue={getData.state?.website} name="website" {...register('website')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                    </div>
                                                    <div className="mt-2">
                                                        <label forHTML="charitable" className="block text-black font-bold">Charitable</label>
                                                        <select className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" id="select" onChange={handleSelectCharitable} defaultValue={getData?.state?.charitable}>
                                                            <option value={true} selected={getData?.state?.charitable === true ?  "selected" : ""}>Yes</option>
                                                            <option value={false} selected={getData?.state?.charitable === false ? "selected" : ""}>No</option>       
                                                        </select>
                                                    </div>
                                                    <div className="mt-2">
                                                        <label forHTML="charitable" className="block text-black font-bold">Location</label>
                                                            <GooglePlacesAutocomplete
                                                                apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                                                selectProps={{
                                                                    onChange: (value) => { setValue('location', value.value.description) },
                                                                    defaultInputValue: getData.state?.location ? getData.state?.location : '',
                                                                    styles:{
                                                                        input: (provided) => ({
                                                                            ...provided,
                                                                            color: 'black',
                                                                            boxShadow: "none",
                                                                            outline: "none",
                                                                            ':hover': {
                                                                                '--tw-ring-color': 'black !important'
                                                                            },
                                                                            ':focus': {
                                                                                '--tw-ring-color': 'black !important'
                                                                            },
                                                                        }),
                                                                        control: (provided) => ({
                                                                            ...provided,
                                                                            border: '1px solid black',
                                                                            ':hover': {
                                                                                border: '1px solid #008e95 !important'
                                                                            },
                                                                            boxShadow: "none",
                                                                            outline: "none"
                                                                        }),
                                                                        
                                                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                                            const color = chroma('#008e95');
                                                                            return {
                                                                            ...styles,
                                                                            backgroundColor: isDisabled
                                                                                ? undefined
                                                                                : isSelected
                                                                                ? data.color
                                                                                : isFocused
                                                                                ? color.alpha(0.1).css()
                                                                                : undefined,
                                                                                ':active': {
                                                                                    ...styles[':active'],
                                                                                    backgroundColor: !isDisabled
                                                                                    ? isSelected
                                                                                    ? color
                                                                                    : color.alpha(0.3).css()
                                                                                    : undefined,
                                                                                },
                                                                            };
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 w-fully place-content-center justify-items-center">
                                            <button
                                                className="w-full py-2 text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Tabs.Item>
                            <Tabs.Item
                                title="Username"
                            >
                                 <div className="bg-secondary p-4 rounded-t-lg text-white">
                                    <h3 className="font-bold text-white">
                                        User Information
                                    </h3>
                                </div>
                                <div className="w-full p-6 bg-white rounded-b-lg shadow-lg">
                                      {/* <div className='mb-3 p-2 rounded-lg text-white text-center text-sm bg-primary sm:w-[240px] w-full'>
                                    <Popup overlayStyle="w-12" trigger={() => <button className='text-white' id="connectBtn"><b className="text-white">Change Your Name</b> </button>}
                                        {...{ overlayStyle, contentStyle }}

                                        position={position}>
                                        <h1 className="p-1">Note: The Name Should Be Available </h1>
                                        <form>
                                            <div>
                                                <input DefualtValue={"Hi, it would be great to collaborate"} className="rounded-l-lg p-1 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white" onChange={(e) => setUsername(e.target.value)} />
                                                <button onClick={(e) => updateUsername(e)}
                                                    className="px-4 rounded-r-lg bg-primary text-white font-bold p-1 uppercase border-t border-b border-r"
                                                >Change</button>
                                            </div>
                                        </form>
                                    </Popup>
                                </div> */}
                                    <form>
                                        <div>
                                            <div className="grid sm:grid-cols-1 grid-cols-1 gap-6">
                                                <div className="">
                                                    <label htmlFor="first_name" className="block text-black font-bold">Business Name</label>
                                                    <input type="text" name="bio" defaultValue={prevUsername} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" onChange={(e) => setUsername(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 w-ful justify-items-center">
                                            <button className="w-full py-2 text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black" id="submitBtn" onClick={(e) => updateUsername(e)}>
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </Tabs.Item>
                          
                        </Tabs.Group>


                    </div>

                </div>
            </div>
        </>
    )
}
