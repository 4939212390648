import { useEffect, useState,useRef } from 'react';
import TokenService from '../../API/token.service';
import { imgApi } from '../../API/Api.config';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {
    useParams
} from "react-router-dom";
import { useLocation } from 'react-router-dom';


const NewsById = () => {
    document.title = "Blog | Promote"

  // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    
    const location = useLocation()
    const token = TokenService.getLocalAccessToken()
    const [data, setData] = useState([]);
    const { id, title, summary, descriptions, image } = useParams();
    const topRef = useRef(null);
    useEffect(() => {

        setData(id, title, summary, descriptions, image);
    }, []);

    useEffect(()=>{topRef.current?.scrollIntoView({ behavior: 'auto' });},[])
    return (
        <>
            <Header />
                <div id='EachNewsPage'>
                    <section id='section1' ref={topRef}>
                        <div className='container mx-auto px-4 sm:px-0'>
                            <div className='flex flex-row justify-center items-center'>
                                <div className='max-w-4xl w-full'>
                                    <h1 className='text-4xl font-bold mb-6'>{location.state.title}</h1>
                                    <p className='mb-6'>Blog | {location.state.date}</p>
                                    <img className='mb-12 w-full' src={imgApi + location.state.image} alt={location.state.image} />
                                    <div className='' dangerouslySetInnerHTML={{ __html: location.state.descriptions }} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <div className='flex justify-center'>
                    <div key={location.state.id} className="p-5">
                        <div className='text-4xl italic font-bold mb-6'>{location.state.title}</div>
                        <img className='mb-6' src={imgApi + location.state.image} alt={location.state.image} />
                        <div className='text-xl' dangerouslySetInnerHTML={{ __html: location.state.summary }} />
                        <div className='text-xl' dangerouslySetInnerHTML={{ __html: location.state.descriptions }} />
                    </div>
                </div> */}
            <Footer/>
        </>
    );
}

export default NewsById;