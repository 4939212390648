import { useEffect, useState } from "react";
import { useStepperContext } from "../../../contexts/StepperContext";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Select from "../../../components/Select/Select";


function AgeGender({setDataEmpty}) {
    const { userData, setUserData } = useStepperContext();
    const [ selectedDate, setSelectedDate ] = useState(0);
    const [ gender, setGender ] = useState("");
    
    const genderOptions = [
        {
            'label': 'Select Your Gender',
            'value': '',
            'disabled' : false
        },
        {
            'label': 'Male',
            'value': 'male',
            'disabled' : false
        },
        {
            'label': 'Female',
            'value': 'female',
            'disabled' : false
        },
        {
            'label': 'Rather Not Specify',
            'value': 'rather_not_specify',
            'disabled' : false
        },
    ]

    const handleChange = (e) => {
        const { value } = e.target;
        setGender(value);
    };

    useEffect(()=>{
        if(selectedDate > 0 && gender !== "") {
            setDataEmpty(false);            
        } else {
            setDataEmpty(true);            
        }
    }, [selectedDate, gender])

    useEffect(() => {
        setUserData({ ...userData, ['gender']:gender, ['age']:selectedDate  });
    }, [selectedDate, gender])

    useEffect(() => {
        setDataEmpty(true)
    }, [])

    useEffect(() => {
        console.log(userData)
    }, [userData])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        Please select your Age and Gender
                    </b>
                </h4>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card">
                        <div className="flex items-center justify-center w-full">
                            <div className="grid sm:grid-cols-2 grid-cols-1 gap-6 w-full">
                                <div className="bg-white p-6 rounded-lg">
                                    <p className="text-center mb-4">
                                        <b>
                                            Age
                                        </b>
                                    </p>
                                    <div>
                                        <DatePicker 
                                            labelName={"Select Your Birthday"}
                                            setSelectedDate={setSelectedDate}
                                        ></DatePicker>
                                    </div>
                                </div>
                                <div className="bg-white p-6 rounded-lg">
                                    <p className="text-center mb-4">
                                        <b>
                                            Gender
                                        </b>
                                    </p>
                                    <Select
                                        options={genderOptions}
                                        onChange={handleChange}
                                        inputValue={gender}
                                        inputName={'gender'}
                                    ></Select>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgeGender