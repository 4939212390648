import { useEffect, useState } from "react";
import { useStepperContext } from "../../../contexts/StepperContext";
import Dropzone from "../../../components/Dropzone/Dropzone";


function UploadProfilePicture({setDataEmpty, heading, apiName}) {
    const { userData, setUserData } = useStepperContext();
    const [ pp, setpp ] = useState("");

    useEffect(() => {
        if(pp !== ''){
            setDataEmpty(false)
        } else {
            setDataEmpty(true)   
        }
    }, [pp])

    useEffect(() => {
        setUserData({ ...userData, [apiName]:pp });
    }, [pp])
    
    useEffect(() => {
        setDataEmpty(true)
    }, [])

    return (
        <div className="w-full">
            <div className=" w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card">
                        <div className="flex items-center justify-center w-full">
                            <Dropzone setpp={setpp}></Dropzone>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
  );
}

export default UploadProfilePicture