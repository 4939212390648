import { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import TokenService from "../../../API/token.service";
import { useStepperContext } from "../../../contexts/StepperContext";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Select from "../../../components/Select/Select";
import CheckboxButton from "../../../components/CheckboxButtons/CheckboxButtons";
import '../onboarding.style.scss';

function Interest({ setDataEmpty, heading, subHeading, apiName }) {
    const { userData, setUserData } = useStepperContext();
    const token = TokenService.getLocalAccessToken()
    const [interests, setInterests] = useState([]);
    const [userInterests, setUserInterests] = useState([]);
    const [finalInterest, setFinalInterests] = useState([]);
    // const [userInterestsFilter, setUserInterestsFilter] = useState([]);
    const [interestsReached, setInterestsReached] = useState(false);

    async function getInterests() {
        try {
            await axios.get(ApiUrl + 'all_interests/', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + token
                }
            }).then(response => setInterests(response.data))
                .catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {
        const { id, checked } = e.target

        if (checked) {
            if (userInterests.length < 3) {
                // setUserInterestsFilter(...userInterestsFilter, ) 
                setUserInterests([...userInterests, interests.at(id)])
                // userInterests.forEach(function(item){ delete item.image });
                setDataEmpty(false)
            } else {
                setDataEmpty(true)
                setInterestsReached(true)
            }
        } else {
            userInterests.splice(userInterests.indexOf(id), 1);
            if (userInterests.length < 3) {
                setInterestsReached(false)
                setDataEmpty(true)
            }
            
            if (userInterests.length <= 0) {
                setDataEmpty(true)
            }
        }
    };

    useEffect(() => {
        getInterests()
    }, [])
 
   
    useEffect(() => {
       const data = []
       data.push(userInterests.map(item =>  ({'id':item.id, 'interest':item.interest}) ))
   
        setUserData({ ...userData, [apiName]: data[0] })
    }, [userInterests])

    useEffect(() => {
        setDataEmpty(true)
    }, [])

    useEffect(() => {
        console.log(userData)
    }, [userData])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small> {subHeading}</small>
                </p>
                {interestsReached == true &&
                    <p className="text-center">
                        <small className="text-red-500"><b>You have chosen more than 3 interests, Kindly reduce the number of interests.</b></small>
                    </p>
                }
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card">
                        <div className="flex items-center justify-center">
                            <div className="">
                                <CheckboxButton
                                    id={'id'}
                                    apiName={'interest'}
                                    apiName2={'interest'}
                                    bgImage={true}
                                    onChange={handleChange}
                                    options={interests}
                                    className={'all-interests'}
                                ></CheckboxButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Interest