import { imgApi } from '../../API/Api.config';
import './checkboxbuttons.style.scss';
import React from 'react';


const CheckboxButton = ({ labelName, inputName, inputValue, onChange, style, required, className, classNameLabel, id, options, apiName, apiName2, apiDesc, bgImage, icon, optionsLength}) => {
    


    return (
        <>
            <ul className={"grid w-full gap-4 " + (options.length > 3 ? "grid-cols-2" : "") + " " + (bgImage ? "sm:grid-cols-4 " : "sm:grid-cols-3 ") + className}>
                {options.map((option, index) => (
                    <li key={index} className="">
                        <input type="checkbox" data={index} id={index} name={option[id]} value={option[apiName2]} onChange={onChange} className="hidden peer" required="" />
                        <label htmlFor={index} className={"inline-flex items-center justify-center text-center w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-primary hover:text-secondary dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 each-box " + classNameLabel} style={{ 'backgroundImage': bgImage == true ? 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(' + imgApi + option.image + ')' : '' }}>
                            <div className="block ">
                                {icon == true &&
                                    <div><i className={`fa-brands fa-${option[apiName].toLowerCase()}`}></i></div>
                                }
                                <div className={'w-full text-lg font-semibold ' + (bgImage ? 'text-white' : 'text-black')}>{option[apiName]} {apiDesc !== '' ? <> <br /> <small className='font-normal'>{option[apiDesc]}</small> </> : ''} </div>
                            </div>
                        </label>
                    </li>
                ))}
            </ul>
        </>
    );
}


export default CheckboxButton;