import { useState, useEffect, useContext } from "react";
import { useStepperContext } from "../../../contexts/StepperContext";
import InputField from "../../../components/InputField/InputField";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import TokenService from '../../../API/token.service';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


// export function getStatus(status=false){
//     return status
// }

function SocialMediaConnect({setDataEmpty, apiName}) {
    const { userData, setUserData } = useStepperContext();
    // const [ status, setStatus ] = useState(false)
    const [ scData, setscData ] = useState({
        'tiktok': null,
        'instagram': null,
        'youtube': null,
        'linkedin': null,
        'facebook': null,
        'twitter': null,
    });
    const token = TokenService.getLocalAccessToken()
    const MySwal = withReactContent(Swal)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setscData({ ...scData, [name]: value });
      
    };


    async function SocialUpdateAPI(data) {
    
        try {
            await axios.post(ApiUrl + 'update_social/', data,
                {
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        'Authorization': `Token ${token}`
                    }
                })
                .then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your social media has been submitted, now you can continue to the next step',
                        showConfirmButton: false,
                        timer: 3000,
                        customClass:{
                            'container': 'z-50'
                        }
                    })

                    setDataEmpty(false)
                    // getStatus(true)
                })
                .catch(function (error) {
                    if (error.request.status === 400) {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'success',
                            title: 'There was a problem submitting your social media',
                            showConfirmButton: false,
                            timer: 3000,
                            customClass:{
                                'container': 'z-50'
                            }
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    };

    // const getStatus = () =>{
    //     return { status }
    // }

    

    // useEffect(() => {
    //     getStatus()
    // }, [status])

    useEffect(() => {
        setDataEmpty(true)
    }, [])

    useEffect(()=> {
        console.log(scData)
    },[scData])

    useEffect(()=> {
        console.log(userData)
    },[userData])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        Connect your social media profile
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        Enter your username here to connect your social media profile 
                    </small>
                </p>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex  flex-col justify-center items-center step-card">
                        <div className={"w-full grid sm:grid-cols-"+ (userData.social_network.length > 3 ? "3" : userData.social_network.length )+" gap-4"}>
                        {userData.social_network.map((sc, index) => (
                            <div className="p-4 bg-white border border-gray-200 rounded-lg shadow" key={index}>
                                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 text-center"><i className={`fa-brands fa-${sc.social_network.toLowerCase()}` }></i>  {sc.social_network}</h5>
                                <InputField type={'text'} inputName={sc.social_network.toLowerCase()} id={sc.social_network.toLowerCase()} inputValue={''} required={true} onChange={handleChange} labelName={sc.social_network + " Username"} className="mt-5" />
                            </div>
                        ))}
                        </div>
                        <div className="mt-3">
                            <button className="bg-primary p-2 text-white rounded-lg" onClick={(e)=>{SocialUpdateAPI(scData)}}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default SocialMediaConnect;
