import { useState, useEffect, useContext } from "react";
import chroma from "chroma-js";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useStepperContext } from "../../../contexts/StepperContext";
import secureLocalStorage from "react-secure-storage";
import CheckboxButton from "../../../components/CheckboxButtons/CheckboxButtons";
import '../onboarding.style.scss';

function DemoG2({ heading, subHeading, apiName }) {
    const { userData, setUserData } = useStepperContext();
    const [location, setLocation] = useState(userData.location);
    const [latlang, setLatLang] = useState("");
    const role = secureLocalStorage.getItem('role');

    useEffect(() => {
        if (role == 1) {
            setUserData({ ...userData, [apiName]: location, ['audience_geolocation']: latlang });
        } else {
            setUserData({ ...userData, [apiName]: location });
        }
    }, [location, latlang])

    if (role == 1) {
        geocodeByAddress(location)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>
                setLatLang(lat + ',' + lng)
            );
    }

    useEffect(() => {
        console.log(latlang);
    }, [latlang]);

    useEffect(() => {
        console.log(userData)
        // console.log(postPrice)
    }, [userData])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        {subHeading}
                    </small>
                </p>
                <div className="mt-7 google-places">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card">
                        <div className="bg-white w-full p-6 rounded-lg">
                            <GooglePlacesAutocomplete
                                apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ['GB'],
                                    }
                                }}
                                selectProps={{
                                    onChange: (value) => { setLocation(value.value.description) },
                                    styles: {
                                        input: (provided) => ({
                                            ...provided,
                                            color: 'black',
                                            boxShadow: "none",
                                            outline: "none",
                                            ':hover': {
                                                '--tw-ring-color': 'black !important'
                                            },
                                            ':focus': {
                                                '--tw-ring-color': 'black !important'
                                            },
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            border: '1px solid black',
                                            ':hover': {
                                                border: '1px solid #008e95 !important'
                                            },
                                            boxShadow: "none",
                                            outline: "none"
                                        }),

                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                            const color = chroma('#008e95');
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? undefined
                                                    : isSelected
                                                        ? data.color
                                                        : isFocused
                                                            ? color.alpha(0.1).css()
                                                            : undefined,
                                                ':active': {
                                                    ...styles[':active'],
                                                    backgroundColor: !isDisabled
                                                        ? isSelected
                                                            ? color
                                                            : color.alpha(0.3).css()
                                                        : undefined,
                                                },
                                            };
                                        },
                                    }
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DemoG2;