import { useEffect, useState, useContext } from 'react';
import TokenService from '../../API/token.service';
import useAxios, { imgApi } from '../../API/Api.config';
import { Link, useNavigate} from 'react-router-dom';
import api from '../../API/axiosConfig';
import { userContext } from "../../components/Context/UserContext";
import { useLocation } from 'react-router-dom';
import './advicehub.style.scss';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Card } from 'flowbite-react';

const AdviceHub = () => {
    document.title = "Advice Hub | Promote"

    const token = TokenService.getLocalAccessToken()
    const role = secureLocalStorage.getItem("role");
    const profileCompleted = secureLocalStorage.getItem('profile_completed')
    const user_id = secureLocalStorage.getItem("user_id");
    const profile = secureLocalStorage.getItem("profile_completed");
    const name = secureLocalStorage.getItem("username");
    const MySwal = withReactContent(Swal)

    const { response, isLoading, status, error } = useAxios({
        method: "get",
        url: 'advice_hub/',
        headers: {
            "Authorization": `Token ${token}`
        }
    });

    const truncateSentence = (sentence) => {
        if (sentence.length > 100) {
          return sentence.slice(0, 130) + "...";
        }
        return sentence;
    }
    

    useEffect(()=>{
        if(!profileCompleted){
            if(role == 1) {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<Link href="/influencer/onboarding" className="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }  else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/business/onboarding" className="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }     
        }
    }, [])

    return (  
        <>
            <Header/>
            <Sidebar/>
            <div className='sm:ml-64 mt-20 mb-10' id='advice-hub'>
                <div className='container mx-auto '>
                    <div>
                        <h2 className="py-4 pb-0 text-2xl font-bold">Advice Hub</h2>
                        <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mt-5'>
                            {response !== null ?
                                (response.length > 0 ? 
                                    (response?.map(advice => (
                                    <>
                                        <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                            <a href="#">
                                                <img className="rounded-t-lg w-full advice-img" src={imgApi + advice.image} alt={advice.title + 'image'} />
                                            </a>
                                            <div className="p-5">
                                                <span className="bg-primary text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">{advice.category}</span>
                                                <div className='mt-2'>
                                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"> {advice.title}</h5>
                                                </div>
                                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400" dangerouslySetInnerHTML={{__html: truncateSentence(advice.information)}}></p>
                                                <Link to={`/influencer-hub/${advice.title.toLowerCase().replace(/\s+/g, '-')}`} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-secondary rounded-lg" state={{
                                                        id: advice.id,
                                                        title: advice.title,
                                                        category: advice.category,
                                                        information: advice.information,
                                                        video: advice.video_link,
                                                        image: advice.image,
                                                        created_at: advice.created_at,
                                                    }}>
                                                    Read more
                                                    <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                    )))
                                    : 
                                    <></>
                                )
                                :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdviceHub;