import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import hero from '../../assets/images/contact/hero.jpg';
import './Contact.style.scss';
import TokenService from "../../API/token.service";
import { ApiUrl } from '../../API/Api.config';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const CaseStudy = () => {

    document.title = "Contact | PROMOTE"

    const { register, handleSubmit, formState: { error } } = useForm();
    const token = TokenService.getLocalAccessToken()

    async function sendMessage(data){
        try{
            await axios.post(ApiUrl + 'contact_us/', data, {headers: {'Content-Type': 'application/json', 'Authorization': 'Token ' + token}})
            .then(response=> {
                if(response.status  === 201){
                    
                    // swal({
                    //     title: "Confirmation",
                    //     text: "Your data submitted successfully",
                    //     icon: "success",
                    //     className:"app-popup",
    
                    // })
                }
            })
            .catch(error=> {
                if(error.request.status === 400){
                    // swal({
                    //     title: "Confirmation",
                    //     text: "Your data was not submitted successfully",
                    //     icon: "error",
                    //     className:"app-popup",
                    //     dangerMode: true,
                    // })
                }
            })
        }catch(e){
            console.log(e)
        }
    }

    return (
        <>
            <Header></Header>
            <div id='Contact'>
                <section id='section1' className='section' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + hero + ")" }}>
                    <div className='container mx-auto h-full'>
                        <div className='flex flex-col justify-center items-center h-full w-full'>
                            <div className="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-lg">
                                <h1 className="text-4xl tracking-tight font-extrabold text-center text-primary">Get in touch</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='section2' className='section'>
                    <div className='container mx-auto'>
                        <div className='flex flex-col justify-center items-center h-full w-full'>
                            <div className="px-4 mx-auto max-w-screen-md">
                                <h2 className="mb-4 text-3xl tracking-tight font-medium text-center text-black">Got a technical issue? Want to send feedback about a feature? Need details about our plans? Let us know.</h2>
                                <form onSubmit={handleSubmit(sendMessage)} className="space-y-8">
                                    <div>
                                        <label for="email" className="block mb-2 text-sm font-medium text-black">Your email</label>
                                        <input type="email" id="email" {...register('sender')} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5" placeholder="name@yourwebsite.com" required />
                                    </div>
                                    <div>
                                        <label for="subject" className="block mb-2 text-sm font-medium text-black">Subject</label>
                                        <input type="text" id="subject" {...register('subject')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary focus:border-primary" placeholder="Let us know how we can help you" required />
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label for="message" className="block mb-2 text-sm font-medium text-black">Your message</label>
                                        <textarea id="message" rows="6" {...register('message')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary focus:border-primary" placeholder="Leave a comment..."></textarea>
                                    </div>
                                    <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary sm:w-fit hover:bg-secondary focus:ring-4">Send message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer></Footer>
        </>
    );
}

export default CaseStudy;