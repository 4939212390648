import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import { useStepperContext } from "../../../contexts/StepperContext";
import CheckboxButton from "../../../components/CheckboxButtons/CheckboxButtons";


function Reward({heading, subHeading, apiName}){
    const { userData, setUserData } = useStepperContext();
    const [rewardData, SetRewardData] = useState([]);
    const [userReward, SetUserReward] = useState([]);
    const [allSc, setAllSc] = useState([]);

    async function getRewardData() {
        try {
            await axios.get(ApiUrl + 'collaboration_options/', {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => SetRewardData(response.data))
            .catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {
        const { id, checked } =  e.target

        if(checked) {
            SetUserReward([...userReward, rewardData.at(id)])
        } else {
            userReward.splice(userReward.indexOf(id), 1);
        }
    };

    useEffect(() => {
        getRewardData();
    }, [])
    
    useEffect(() => {
        setUserData({ ...userData, [apiName]:userReward });
    }, [userReward])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        {subHeading}
                    </small>
                </p>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card">
                        <CheckboxButton 
                        inputName={'content'}
                        apiName={'title'}
                        apiName2={'title'}
                        apiDesc={'description'}
                        onChange={handleChange}
                        options={rewardData}
                        ></CheckboxButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reward;