import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import { useStepperContext } from "../../../contexts/StepperContext";
import CheckboxButton from "../../../components/CheckboxButtons/CheckboxButtons";


function DemoG({heading, subHeading, apiName}){
    const { userData, setUserData } = useStepperContext();
    const [demoData, setDemoData] = useState([]);
    const [audience, setAudience] = useState([]);


    async function getAudienceData() {
        try {
            await axios.get(ApiUrl + 'audience_category/', {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => setDemoData(response.data))
            .catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }
    
    const handleChange = (e) => {
        const { id, checked } =  e.target

        if(checked) {
            setAudience([...audience, demoData.at(id)])       
        } else {
            audience.splice(audience.indexOf(id), 1);
        }
    };

    useEffect(() => {
        getAudienceData()
    }, [])

    useEffect(() => {
        setUserData({ ...userData, [apiName]:audience });
    }, [audience])

    useEffect(() => {
        console.log(userData)
    }, [userData])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        {subHeading}
                    </small>
                </p>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card">
                        <CheckboxButton
                        id={'id'}
                        inputName={'demo_g'}
                        apiName={'category'}
                        apiName2={'category'}
                        onChange={handleChange}
                        options={demoData}
                        bgImage={false}
                        ></CheckboxButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DemoG;