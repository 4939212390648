import React, { useState, useEffect, useRef } from "react";
import './onboarding.style.scss';

const Stepper = ({ steps, currentStep }) => {
    const [newStep, setNewStep] = useState([]);
    const stepsRef = useRef();

    const updateStep = (stepNumber, steps) => {
        const newSteps = [...steps];
        console.log(newSteps);
        let count = 0;
        while (count < newSteps.length) {
        //current step
        if (count === stepNumber) {
            newSteps[count] = {
                ...newSteps[count],
                highlighted: true,
                selected: true,
                completed: false,
            };
            count++;
        }

        //step completed
        else if (count < stepNumber) {
            newSteps[count] = {
                 ...newSteps[count],
                highlighted: false,
                selected: true,
                completed: true,
            };
            count++;
        }
        //step pending
        else {
            newSteps[count] = {
                ...newSteps[count],
                highlighted: false,
                selected: false,
                completed: false,
            };
            count++;
        }
    }

    return newSteps;
};

useEffect(() => {
    const stepsState = steps.map((step, index) =>
        Object.assign(
            {},
            {
                description: step,
                completed: false,
                highlighted: index === 0 ? true : false,
                selected: index === 0 ? true : false,
            }
        )
    );

    stepsRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepsRef.current);
    setNewStep(current);
}, [steps, currentStep]);

const stepsDisplay = newStep.map((step, index) => {
    return (
        <div
            key={index}
            className={
            index !== newStep.length - 1
            ? "w-full flex items-center"
            : "flex items-center"
        }
        >
        <div className="relative sm:flex flex-col items-center text-primary">
            <div
                className={`rounded-full transition duration-500 ease-in-out border-2 border-gray-300 sm:h-12 h-6 sm:w-12 w-6 flex items-center justify-center py-3  ${
                step.selected
                    ? "bg-primary text-white font-bold border border-primary "
                    : ""
                }`}
            >
                {step.completed ? (
                <span className="text-white font-bold sm:text-xl text-xs"><i className="fa-solid fa-circle-check"></i></span>
                ) : (
                <><span className="sm:text-xl text-xs">{index + 1}</span></>
                
                )}
            </div>
            <div
                className={`absolute top-0  text-center mt-16 sm:w-32 w-10 text-xs font-medium uppercase sm:block hidden ${
                step.highlighted ? "text-gray-900" : "text-gray-400"
            }`}
            >
                <small className="stepper-desc">
                    {step.description}
                </small>
            </div>
        </div>
        <div
            className={`flex-auto border-t-2 transition duration-500 ease-in-out  ${
                step.completed ? "border-primary" : "border-gray-300 "
            }  `}
        ></div>
      </div>
    );
});

return (
    <div className="mx-4 p-4 flex justify-between items-center">
        {stepsDisplay}
    </div>
    );
};

export default Stepper;