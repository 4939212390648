import React, {useState} from "react";
import { Pie } from "react-chartjs-2";

function PieChart({ label, data }) {
    const [chartData, setChartData] = useState({
            labels: label, 
            datasets: [
            {
                data: data,
                backgroundColor: [
                "#003f5c",
                "#444e86",
                "#955196",
                "#dd5182",
                "#ff6e54",
                "#ffa600"
                ],
                borderColor: "black",
                borderWidth: 2,
            }
            ]
    });
  return (
    <div className="chart-container">
      <Pie
        data={chartData}
        options={{
            plugins: {
            }
        }}
      />
    </div>
  );
}
export default PieChart;