import React from 'react';
import { useEffect, useState } from 'react'
import logo from '../../logo.png';
import infuencer1 from '../../assets/images/influencer/influencer-1.jpg';
import { Link } from 'react-router-dom';
import './DashboardHeader.style.scss';
import secureLocalStorage from "react-secure-storage";



export default function DashboardHeader() {
    const role = secureLocalStorage.getItem("role");
    const username = secureLocalStorage.getItem("username");
    const first_name = secureLocalStorage.getItem("first_name");
    const [profile, setProfile] = useState("");
    const [name, setName] = useState("");

    const openMenu = (id) => {
        if(document.getElementById(id).classList.contains("hidden")){
            document.getElementById(id).classList.remove("hidden")
        } else {
            document.getElementById(id).classList.add("hidden")
        }
    }

    useEffect(() => {
        if(role === 1){
            setProfile('/update_user_profile') 
        }else{
            setProfile('/update_startup_profile') 
        }
        
        setName(first_name ? first_name : username);
        
    }, [])

    return (
    <div>
        <header>
            
            <nav className="bg-white border-gray-200 px-4 lg:px-6 shadow-lg fixed w-full top-0 z-40">
                <div className="flex flex-wrap sm:justify-end justify-between items-center mx-auto ">
                    <div className="flex items-center lg:order-2">
                        <button type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600" onClick={(e)=> {openMenu('mobile-menu-2')}} aria-expanded="false" on>
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                        <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                            <li>
                                <a href="/search" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Search</b></a>
                            </li>
                            <li>
                                <a href="/messages" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Messaging</b></a>
                            </li>
                            {role == 2 && 
                                <li>
                                    <a href="/tailored-influencers" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Tailored Influencers</b></a>
                                </li>
                            }
                            <li>
                                <a href="/campaigns" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Campaigns</b></a>
                            </li>
                            <li>
                                <a href="/task-center" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Task Centre</b></a>
                            </li>
                            <li>
                                <a href="/referrals" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Referrals</b></a>
                            </li>
                            <li>
                                <a href="/advice-hub" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Advice Hub</b></a>
                            </li>
                            <li>
                                <a href="/blog" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Blog</b></a>
                            </li>
                            {role == 2 && 
                                <li>
                                    <a href="/dashboard/pricing-plan" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Pricing Plans</b></a>
                                </li>
                            }
                            <li>
                                <a href="/how-it-works" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>How It Works</b></a>
                            </li>
                            <li>
                                <a href="/contact" className="block sm:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Contact Us</b></a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex items-center lg:order-2">
                        <div className=''>
                            <a href="/logout" className="block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent"><i className="fa-solid fa-arrow-right-from-bracket"></i> Log out {name}</a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </div>
    );
}