import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useAxios, { ApiUrl } from '../../API/Api.config';
import TokenService from '../../API/token.service';
import { imgApi } from '../../API/Api.config';
import Header from "../../components/DashboardHeader/DashboardHeader";
import Sidebar from '../../components/Sidebar/Sidebar';
import secureLocalStorage from "react-secure-storage";
import './Job.style.scss';
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';


const AllJobs = () => {
    document.title = "Jobs | PROMOTE"

    const token = TokenService.getLocalAccessToken();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const [jobData, setJobData] = useState([]);
    const role = secureLocalStorage.getItem("role");
    const [loading, setLoading] = useState(false)
    const profileCompleted = secureLocalStorage.getItem('profile_completed')
    const MySwal = withReactContent(Swal)


    const { response, isLoading, status, reload } = useAxios({
        method: "get",
        url: 'get_all_job/',
        headers: {
            "Authorization": `Token ${token}`
        }
    });

    async function getStartupJob(){
        try{
            axios.get(ApiUrl + 'my_job/', {headers: {
                "Authorization": `Token ${token}`
            }}).then(response => setData(response.data)).catch(error => console.log(error));
        }catch(e){
            console.log(e)
        }
    }

    async function JobDeleteApi(id) {
        setLoading(true)
        const token = TokenService.getLocalAccessToken();
    
        try {
            await axios.delete(ApiUrl + `my_job/?id=${id}`,
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your Campaign has been deleted',
                        showConfirmButton: false,
                        timer: 3000,
                    })

                    reload()
                    setJobData([])
                })
                .catch(function (error) {
                    if(error.request.status === 400){
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'danger',
                            title: 'There was error deleting your campaign',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                }).finally(() => (
                    setLoading(false)
                ));
        } catch (error) {
            console.log(error)
        }
    };

    const handleClick = (img) =>{
        Swal.fire({
            imageUrl: imgApi + img,
            imageAlt: 'Custom image',
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                image: 'img-pop-up',
                popup:'html-img'
            }
        })
    }


    const getJobDetails = (data) => {
        setJobData(data);
    }

    useEffect(()=>{
        if (token === null) {
            nav('/login')
        }
    }, [])


    useEffect(() => {
        if (response !== null && role === 1) {
            setData(response)
        }else if(response !== null && role === 2){
            getStartupJob()
        }else if (status === 401) {
            nav('/login')
        }
        
    }, [isLoading]);

    useEffect(()=>{
        if(!profileCompleted){
            if(role == 1) {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/influencer/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }  else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/business/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }     
        }
    }, [])

    return (
        <>
        <Header />
        <Sidebar />
        <div className='mt-20 mb-10 sm:ml-64'>
            <div className='container mx-auto'>
                <h2 className="p-4 text-2xl font-bold">My Campaigns</h2>
                <div className='flex flex-row justify-center items-start flex-wrap sm:flex-nowrap space-x-4 px-4'>
                    <div className='sm:basis-4/12 basis-full w-full sm:order-1 order-2'>
                        <div className='bg-secondary p-4 text-white rounded-t-lg'>
                            {role === 2 && <p className='text-md'>All Campaigns</p>}
                        </div>
                        <div className='bg-white p-2 my-job rounded-b-lg shadow-lg border'>
                            { data.length !== 0 ? data.map(data => {
                                return (
                                        <div className=" p-6 m-4 bg-fourth rounded-lg border border-gray-200 shadow-md job" key={data.id} >
                                            {/* <svg className="mb-2 w-10 h-10 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clipRule="evenodd"></path><path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"></path></svg> */}
                                            <div className='flex flex-row justify-content-center items-center'>
                                                <div className=''>
                                                    <h5 className="text-xl font-semibold tracking-tight text-secondary job-title">{data.title}</h5>
                                                    <p className='text-gray-500 job-desc'><small>{data.short}</small></p>
                                                </div>
                                            </div>
                                            <hr className='my-4'/>
                                            <div className='startup'>
                                                {/* <img src={imgApi + data.startup_logo} className=" inline-block h-6 w-6"/> */}
                                                <p className='text-gray-500 inline-block'><small>{data.startup_name}</small></p>
                                            </div>
                                            <div className='text-gray-500'>
                                                <small>
                                                    <div className='flex justify-start items-center'>
                                                        <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                            <i className="fa-solid fa-location-dot"></i>
                                                        </p>
                                                        <p className='inline-block'>
                                                            {data.location}
                                                        </p>
                                                    </div>
                                                </small>
                                            </div>
                                            <div className='mb-3 text-gray-500'>
                                                <small>
                                                    <div className='flex justify-start items-center'>
                                                        <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                            <i className="fa-solid fa-people-group"></i>
                                                        </p>
                                                        <p className='inline-block'>
                                                            {data.application_numbers} influencer(s) needed
                                                        </p>
                                                    </div>
                                                </small>
                                            </div>
                                            <div className=''>
                                                <button className='bg-primary w-full p-2 text-white rounded-lg hidden lg:block sm:block' onClick={() => getJobDetails([data])}>
                                                    View Campaign
                                                </button>
                                                <Link className='bg-primary block w-full p-2 mt-4 rounded-lg text-white text-center w-full hover:bg-secondary sm:hidden block' to={`/campaign-update/${data.title}`} state={{
                                                        id: data.id,
                                                        startup_name: data.startup_name,
                                                        startup:data.startup,
                                                        startup_logo: data.startup_logo,
                                                        min_influencer_numbers: data.min_influencer_numbers,
                                                        application_numbers: data.application_numbers,
                                                        price: data.price,
                                                        title: data.title,
                                                        job: data.job,
                                                        location: data.location,
                                                        created_at: data.created_at,
                                                        short: data.short,
                                                        promotion_req: data.promotion_req,
                                                        business_overview: data.business_overview,
                                                        working_with: data.working_with,
                                                        image1: imgApi + data.image1,
                                                    }}>
                                                    View Campaign
                                                </Link>
                                            </div>
                                        </div>
                                        )
                                    }):   
                                    <div className='flex flex-col justify-center items-center h-3/4'>
                                        <i className="fa-solid fa-suitcase text-gray-400"></i>
                                        <p className='text-gray-400 mt-2'>
                                            No jobs available at the moment
                                        </p>
                                    </div>  }
                        </div>
                    </div>
                    <div className='sm:basis-8/12 basis-full sm:order-2 order-1'>
                        <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                            <div className={ role == 2 ? 'basis-full hidden sm:block' : 'basis-full hidden sm:block'}>
                                <div className='bg-secondary p-4 text-white rounded-t-lg'>
                                    {role === 2 && <p className='text-md'>Campaign Details</p>}
                                </div>
                                <div className='bg-white my-job-details p-2 rounded-b-lg border shadow-lg'>
                                {jobData.length !== 0 ? jobData.map(jd => { 
                                    return (
                                        <div className='p-6'>
                                            <div className='mb-10'>
                                                <h5 className="text-2xl font-bold text-secondary">{jd.title}</h5>
                                                <div className='startup'>
                                                    {/* <img src={imgApi + jd.startup_logo} className=" inline-block h-6 w-6"/> */}
                                                    <p className='text-gray-500 inline-block ml-2'><small>{jd.startup_name}</small></p>
                                                </div>
                                            </div>
                                            <div className='job-spec'>
                                                <div className='flex flex-row flex-wrap'>
                                                    <div className='basis-1/2'>
                                                        <div className='text-gray-500'>
                                                            <small>
                                                                <div className='flex justify-start items-center' title='Job Payment'>
                                                                    <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                                        <i className="fa-solid fa-sterling-sign"></i>
                                                                    </p>
                                                                    <p className='inline-block'>

                                                                        <b>Price</b>: {jd.price}
                                                                    </p>
                                                                </div>
                                                            </small>
                                                        </div>
                                                        <div className='text-gray-500'>
                                                            <small>
                                                                <div className='flex justify-start items-center' title='Min. followers'>
                                                                    <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                                        <i className="fa-solid fa-people-group"></i>
                                                                    </p>
                                                                    <p className='inline-block'>
                                                                        <b>Min. followers</b>: {jd.min_influencer_numbers}
                                                                    </p>
                                                                </div>
                                                            </small>
                                                        </div>
                                                        <div className='text-gray-500'>
                                                            <small>
                                                                <div className='flex justify-start items-center' title='Min. followers'>
                                                                    <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                                        <i className="fa-solid fa-people-group"></i>
                                                                    </p>
                                                                    <p className='inline-block'>
                                                                       <b>No. of Influencers</b>: {jd.application_numbers}
                                                                    </p>
                                                                </div>
                                                            </small>
                                                        </div>
                                                        <div className='text-gray-500'>
                                                            <small>
                                                                <div className='flex justify-start items-center'>
                                                                    <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                                    <i className="fa-solid fa-list-check"></i>
                                                                    </p>
                                                                    <p className='inline-block'>
                                                                        <b>Promotion Requirements</b>: {jd.promotion_req}
                                                                    </p>
                                                                </div>
                                                            </small>
                                                        </div>
                                                        <div className='text-gray-500'>
                                                            <small>
                                                                <div className='flex justify-start items-center'>
                                                                    <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                                        <i className="fa-solid fa-location-dot"></i>
                                                                    </p>
                                                                    <p className='inline-block'>
                                                                        <b>Location</b>: {jd.location}
                                                                    </p>
                                                                </div>
                                                            </small>
                                                        </div>
                                                   
                                                    </div>
                                                    <div className='basis-1/2 flex justify-center'>
                                                        <img src={imgApi + jd.image1} alt={jd.startup_name + ' Campaign'} className="job-img cursor-pointer" onClick={() => handleClick(jd.image1)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='job-overview mt-10'>
                                                <p className='font-semibold mb-3'>
                                                    Campaign Overview
                                                </p>
                                                <p className='font-regular whitespace-pre-wrap'>
                                                    {jd.business_overview}
                                                </p>
                                                <p className='font-regular whitespace-pre-wrap mt-3'>
                                                    {jd.job}
                                                </p>
                                            </div>
                                            <div>
                                            {role === 2 ? 
                                                <div>
                                                    <Link className='bg-primary block w-full p-2 mt-4 rounded-lg text-white text-center w-full hover:bg-secondary' to={`/campaign-update/${jd.title}`} state={{
                                                        id: jd.id,
                                                        startup_name: jd.startup_name,
                                                        startup:jd.startup,
                                                        startup_logo: jd.startup_logo,
                                                        min_influencer_numbers: jd.min_influencer_numbers,
                                                        application_numbers: jd.application_numbers,
                                                        price: jd.price,
                                                        title: jd.title,
                                                        job: jd.job,
                                                        location: jd.location,
                                                        created_at: jd.created_at,
                                                        end_date: jd.end_date,
                                                        short: jd.short,
                                                        promotion_req: jd.promotion_req,
                                                        business_overview: jd.business_overview,
                                                        working_with: jd.working_with,
                                                        image1: imgApi + jd.image1,
                                                    }}> 
                                                        Update this Campaign
                                                    </Link>
                                                    <button className='bg-red-700 block w-full p-2 mt-4 rounded-lg text-white text-center w-full hover:bg-red-500' onClick={() => {JobDeleteApi(jd.id);}}>Delete this Campaign</button>
                                                </div> 
                                                : 
                                                <div></div>}
                                            </div>
                                        </div>
                                        
                                    )
                                 }) : 
                                    <div className='flex flex-col justify-center items-center h-full'>
                                        <i className="fa-solid fa-suitcase text-gray-400"></i>
                                        <p className='text-gray-400 mt-2'>
                                            Please click a job to view it
                                        </p>
                                    </div> 
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
}

export default AllJobs;