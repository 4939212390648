import { useEffect, useState, useContext } from 'react';
import TokenService from '../../API/token.service';
import useAxios, { imgApi } from '../../API/Api.config';
import { Link, useNavigate} from 'react-router-dom';
import api from '../../API/axiosConfig';
import { userContext } from "../../components/Context/UserContext";
import { useLocation } from 'react-router-dom';
import './advicehub.style.scss';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'




const AdviceById = () => {

    const data = useLocation()

    return (  
        <>
            <Header/>
            <Sidebar/>
            <div className='sm:ml-64 mt-20 mb-10' id='advice-hub'>
                <div className="advice-start-image" style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("+ imgApi + data.state.image +")"}}></div>
                <div className='container mx-auto'>
                    <h2 className="p-4 pb-0 text-2xl font-bold">{data.state.title}</h2>
                    <div className='px-4 mt-3'>
                        <span className="bg-primary text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                            {data.state.category}
                        </span>
                        | &nbsp;
                        <span>
                            {new Date(data.state.created_at).toLocaleDateString()}
                        </span>
                    </div>
                    <div className='px-4 advice-text my-4' dangerouslySetInnerHTML={{__html:data.state.information}}></div>
                    {/* <hr className='my-6'/>
                    <div>
                        <p>Like this post</p>
                        <div className='mt-4'>
                            <h5>
                                <b>
                                    Comments
                                </b>
                            </h5>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}
 
export default AdviceById;