import { useState, useEffect, useContext } from "react";
import { ApiUrl } from "../../API/Api.config";
import axios from "axios";
import InputField from "../../components/InputField/InputField";
import React from 'react';
import { useNavigate } from "react-router-dom";
import TokenService from "../../API/token.service";
import { Link } from "react-router-dom";
import { userContext } from "../../components/Context/UserContext";
import secureLocalStorage from "react-secure-storage";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import image1 from '../../assets/images/login/login.jpg';
import logo from '../../assets/images/logo1.png';
import './login.style.scss'
import { GoogleLogin } from '@react-oauth/google';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Login = () => {
    document.title = "Login | PROMOTE"

    const ENVIRONMENT = 0

    if (ENVIRONMENT === 1) {
        console.log = () => { }
        console.error = () => { }
        console.debug = () => { }
    }

    const [user, setUser] = useState({});
    const [response, setResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const [soical, setSoical] = useState(false);
    const [profileCompleted, setProfileCompleted] = useState(true);
    const [error, setError] = useState('');
    const { role, addRole } = useContext(userContext)
    const nav = useNavigate();
    const MySwal = withReactContent(Swal)

    const onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setUser(values => ({ ...values, [name]: value }))
        setError('')
    };



    async function resendEmailConfirmation(email) {
        try {
            await axios.post(ApiUrl + 'resend-verification-email/', {
                email: email
            },
                {
                    headers: {
                        "Content-type": "application/json;",
                    }
                }).then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: "Email Verification Sent",
                        showConfirmButton: false,
                        timer: 10000,
                        customClass: {
                            'container': 'z-50'
                        },
                    })
                })
        } catch {

        }

    }

    async function LoginAPI(email, password) {

        try {
            await axios.post(ApiUrl + 'auth/token/', {
                email: email,
                password: password,
            },
                {
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    }
                })
                .then(function (response) {
                    if (response.data.access_token !== '') {
                        setResponse(response.data);
                        addRole(response.data.role)
                        setProfileCompleted(response.data.profile_completed)
                        secureLocalStorage.setItem("role", response.data.role);
                        secureLocalStorage.setItem("payment_status", response.data.payment_status)
                        secureLocalStorage.setItem("username", response.data.username)
                        secureLocalStorage.setItem("myuserid", response.data.userid)
                        secureLocalStorage.setItem("profile_completed", response.data.profile_completed)
                        secureLocalStorage.setItem("user_id", response.data.id)
                        secureLocalStorage.setItem("first_name", response.data.first_name)
                        secureLocalStorage.setItem("last_name", response.data.last_name)
                        if (response.data.access_token) {
                            TokenService.setUser(response.data);
                        }
                        if (response.data.profile_completed) {
                            if (response.data.role == 1) {
                                nav('/campaigns')
                            } else {
                                nav('/add-campaign')
                            }
                        } else {
                            if (response.data.role == 1) {
                                nav('/influencer/onboarding')
                            } else {
                                nav('/business/onboarding')
                            }
                        }
                    } else {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: response.data.verfication_status,
                            confirmButtonText: 'Resend Email Verification',
                            confirmButtonColor: '#222831',
                            showConfirmButton: response.data.verfication_status.includes('email') ? true : false,
                            timer: 10000,
                            customClass: {
                                'container': 'z-50'
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                resendEmailConfirmation(email)
                            }
                        })
                    }
                    setLoading(true)
                })
                .catch(function (error) {
                    setError(error)
                    setLoading(false)
                })
        } catch (error) {
            console.log(error)
        }

    };

    async function facebookLogin(data) {

        try {
            await axios.post(ApiUrl + 'dj-rest-auth/facebook/', {
                access_token: data
            },
                {
                    headers: {
                        "Content-type": "application/json",
                    }
                })
                .then(function (response) {
                    if (response.data.access_token) {
                        TokenService.setUser(response.data);
                        setSoical(true)

                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    };

    async function LinkedinLogin(data) {

        try {
            await axios.post(ApiUrl + 'dj-rest-auth/linkedin/', {
                access_token: data
            },
                {
                    headers: {
                        "Content-type": "application/json",
                    }
                })
                .then(function (response) {
                    if (response.data.access_token) {
                        TokenService.setUser(response.data);
                        setSoical(true)

                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    };

    async function googleLogin(data) {

        try {
            await axios.post(ApiUrl + 'dj-rest-auth/google/', {
                access_token: data
            },
                {
                    headers: {
                        "Content-type": "application/json",
                    }
                })
                .then(function (response) {
                    console.log(response.data)
                    if (response.data.access_token) {
                        // TokenService.setUser(response.data.key);
                        // window.location.href = '/user-info'
                        TokenService.setUser(response.data.login)
                        secureLocalStorage.setItem("username", response.data.user.first_name)
                        secureLocalStorage.setItem("myuserid", response.data.user.id)
                        addRole(1)
                        setProfileCompleted(false)
                        setSoical(true)
                    }
                    console.log(response.data)
                })
                .catch(function (error) {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    };

    const { linkedInLogin } = useLinkedIn({
        clientId: '868ox9rswrs9gv',
        // redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: { LinkedinLogin },
        onError: (error) => {
            console.log(error);
        },
    });


    const HandleSubmit = (event) => {
        event.preventDefault();
        LoginAPI(user.email, user.password)
    }

    const responseGoogle = (response) => {
        googleLogin(response);

    }

    const responseFacebook = (response) => {
        facebookLogin(response.accessToken);
        console.log(response)
    }

    return (
        <>
            <div id="login">
                <section className="bg-fourth" >
                    <div className="container mx-auto h-full">
                        <div className="flex flex-col justify-center items-center h-full">
                            <div className="bg-white rounded-lg w-full sm:max-w-[75rem] full-card">
                                <div className="flex flex-wrap h-full">
                                    <div className="sm:basis-6/12 basis-0/12 section-1-cover" style={{ backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.0)), url(" + image1 + ")" }}></div>
                                    <div className="sm:basis-6/12 basis-full p-10">
                                        <div className="flex flex-col w-full h-full items-center justify-center">
                                            <div className="flex flex-row justify-center w-full">
                                                <img className="max-w-full border-none h-14 mb-4" src={logo} />
                                            </div>
                                            <h3 className="text-2xl text-center mt-2 "><b>Welcome back</b></h3>
                                            <div className="w-full">
                                                <form onSubmit={HandleSubmit} className="mt-10">
                                                    <div>
                                                        <InputField type={'text'} inputName={'email'} inputValue={user.email} onChange={onChange} id={"email"} labelName={"Email Address"} />
                                                    </div>
                                                    <div className="mt-4">
                                                        <InputField type={'password'} inputName={'password'} inputValue={user.password} onChange={onChange} labelName={"Password"} />
                                                    </div>
                                                    <div>
                                                        {error && (
                                                            <div className="text-normal text-red-500">
                                                                <p>Email or password is incorrect</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <Link to="/password-reset/">
                                                            <span className="text-xs text-gray-500 hover:underline underline-offset-2">Can't remember your password? Reset here</span>
                                                        </Link>
                                                    </div>
                                                    <div className="mt-4">
                                                        <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black">Login</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="mt-4 flex items-center justify-between w-full">
                                                <span className="border-b w-1/5 md:w-1/4"></span>
                                                <div className="text-xs text-gray-500 uppercase">
                                                    <b>
                                                        or
                                                    </b>
                                                </div>
                                                <span className="border-b w-1/5 md:w-1/4"></span>

                                            </div>
                                            <div className="mt-4 w-full">
                                                <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-secondary rounded-lg hover:bg-third focus:outline-none focus:bg-black">
                                                    <Link to="/influencer/register" className="text-white">
                                                        Don’t have an account? <br /><small>Sign up for free.</small>
                                                    </Link>
                                                </button>
                                            </div>
                                            <hr className="h-px my-8 bg-gray-200 border-0 w-full"></hr>
                                            <div className="bg-fourth p-5 rounded-lg w-full d-flex justify-center items-center">
                                                <p className="text-center text-sm">
                                                    If you are an Influencer, <br />you may sign in using your social accounts:
                                                </p>
                                                <div className="mt-4 flex gap-4 justify-center">
                                                    <GoogleLogin
                                                        onSuccess={credentialResponse => {
                                                            responseGoogle(credentialResponse.credential);
                                                        }}
                                                        onError={() => {
                                                            console.log('Login Failed');
                                                        }}
                                                    />
                                                    {/* <FacebookLogin
                                                        appId="851990559613106"
                                                        onSuccess={(response) => {
                                                            console.log('Login Success!', response);
                                                        }}
                                                        onFail={(error) => {
                                                            console.log('Login Failed!', error);
                                                        }}
                                                        onProfileSuccess={(response) => {
                                                            console.log('Get Profile Success!', response);
                                                        }}
                                                        style={{
                                                            backgroundColor: '#4267b2',
                                                            color: '#fff',
                                                            fontSize: '16px',
                                                            padding: '12px 24px',
                                                            border: 'none',
                                                            borderRadius: '4px',
                                                        }}
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}


export default Login;
