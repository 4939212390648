import { set, useForm } from "react-hook-form";
// import userInfoAPI from "../../API/UserInfoApi";
import useAxios, { ApiUrl, imgApi } from '../../API/Api.config';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from "../../components/DashboardHeader/DashboardHeader";
import Sidebar from '../../components/Sidebar/Sidebar';
import Dropzone from "../../components/Dropzone/Dropzone";
import TokenService from "../../API/token.service";
import axios from "axios";
import chroma from "chroma-js";
import Select from 'react-select'
import secureLocalStorage from "react-secure-storage";
import './UserInfo.style.scss';
import 'reactjs-popup/dist/index.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Tabs } from 'flowbite-react'

const UpdateUserInfo = () => {
    document.title = "Update Profile | Promote"

    // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    const profile = secureLocalStorage.getItem("profile_completed");
    const userid = secureLocalStorage.getItem("user_id");
    const prevUsername = secureLocalStorage.getItem("username");
    const prevFirstName = secureLocalStorage.getItem("first_name");
    const prevLastName = secureLocalStorage.getItem("last_name");
    const token = TokenService.getLocalAccessToken()
    const [data, setData] = useState([]);
    const [pimage, setpimage] = useState("");
    const nav = useNavigate();
    const [paymentType, setPaymentType] = useState([]);
    const [interest, setInterest] = useState([]);
    const [userInterest, setUserInterest] = useState([]);
    const [interestOption, setinterestOption] = useState([]);
    const [demoData, setDemoData] = useState([]);
    const [audience, setAudience] = useState([]);
    const [userAudience, setUserAudience] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [content, setContent] = useState([]);
    const [userContent, setUserContent] = useState([]);
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const getData = useLocation();
    const [ pp, setpp ] = useState("");
    const [audienceLocation, setAudienceLocation] = useState("");
    const MySwal = withReactContent(Swal)

    const overlayStyle = { width: '50%' }
    const contentStyle = window.innerWidth > 768 ? { width: '30%' } : { width: '85%' };
    const position = window.innerWidth > 768 ? 'right center' : 'center';

    const setInterests = async (event) => {
        console.log(event)
        setinterestOption([])

            event.map((obj) => (
             
                
                setinterestOption([
                    ...interestOption,
                    
                    {
                        'id': obj.id,
                        'interest': obj.value,
                        // 'image': obj.image
                    }
                ])
            ))
        
    }

    const handleOptionRemove = (event) => {
        console.log(event)
    } 

    const setMediaAudience = async (event) => {
        console.log(event)
        event.map((obj) => (
            
            setAudience([
                ...audience,
                {
                    'id': obj.id,
                    'category': obj.value,
                }
            ])
        ))
    }

    const setMediaContent = async (event) => {
        console.log(event)
        event.map((obj) => (
            
            setContent([
                ...content,
                {
                    'id': obj.id,
                    'type': obj.value,
                    // 'image': obj.image
                }
            ])
        ))
    }


    async function getPaymentType() {
        try {
            await axios.get(ApiUrl + 'payment_type/', { headers: { 'Accept': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => setPaymentType(response.data))
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                });
        } catch (e) {
            console.log(e);
        }
    }

    async function influencerInterest() {
        try {
            axios.get(ApiUrl + 'all_interests/', { headers: { 'Accept': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    response.data.map((obj) => {
                        setInterest(prevState => [
                            ...prevState,
                            {
                                'id' : obj.id,
                                'label' : obj.interest,
                                'value' : obj.interest,
                                'image' : obj.image
                            }
                        ]);
                    })
                    getData.state?.interest.map((obj) =>{
                        setUserInterest(prevState => [
                            ...prevState,
                            {
                                'label' : obj.interest,
                                'value' : obj.interest
                            }
                        ]);

                        setinterestOption(prevState => [
                            ...prevState,
                            {
                                'id': obj.id,
                                'interest': obj.interest,
                                // 'image' : obj.image
                            }
                        ])

                    })
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                });
        } catch (e) {
            console.log(e)
        }
    }

    async function getAudienceData() {
        try {
            await axios.get(ApiUrl + 'audience_category/', {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                response.data.map((obj) => {
                    setDemoData(prevState => [
                        ...prevState,
                        {
                            'id' : obj.id,
                            'label' : obj.category,
                            'value' : obj.category,
                        }
                    ]);
                })
                getData.state?.social_media_audience.map((obj) =>{
                    setUserAudience(prevState => [
                        ...prevState,
                        {
                            'label' : obj.category,
                            'value' : obj.category
                        }
                    ]);

                    setAudience(prevState => [
                        ...prevState,
                        {
                            'id': obj.id,
                            'category': obj.category
                        }
                    ])
                })
            }).catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }

    async function getContentData() {
        try {
            await axios.get(ApiUrl + 'collaboration_type/', {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                response.data.map((obj) => {
                    setContentData(prevState => [
                        ...prevState,
                        {
                            'id' : obj.id,
                            'label' : obj.type,
                            'value' : obj.type,
                        }
                    ]);
                })
                getData.state?.type_of_collaboration.map((obj) =>{
                    setUserContent(prevState => [
                        ...prevState,
                        {
                            'label' : obj.type,
                            'value' : obj.type
                        }
                    ]);

                    setContent(prevState => [
                        ...prevState,
                        {
                            'id': obj.id,
                            'category': obj.type
                        }
                    ])
                })
            }).catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }

    async function userInfoUpdateAPI(data) {

        try {
            await axios.patch(ApiUrl + `user_info/?pk=${userid}`, data,
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your Profile has been updated',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                    setTimeout(function(){
                        nav('/user-info');
                    }, 3000);
                    secureLocalStorage.setItem("profile_completed", true)
                })
                .catch(function (error) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'error',
                        title: 'There was a problem updating your profile',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    };

    async function UpdateInfluencerUsername(firstname, lastname) {
        try {
            axios.get(ApiUrl + `change_username/?first_name=${firstname}&last_name=${lastname}`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your Profile has been updated',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                    setTimeout(function(){
                        nav('/user_info');
                    }, 3000);
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    } else {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was a problem updating your profile',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    const validationSchema = Yup.object().shape({
        age: Yup.number()
            .min(16),
        price_per_post: Yup.number()
            .min(1)
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, setValue, getValues, formState: { errors, isSubmitting, isSubmitted }, reset } = useForm();


    const handleSubmitForm = (data) => {
        document.getElementById('submitBtn').click()
            userInfoUpdateAPI(data)
    }

    const handleSelectPayment = (e) => {
        const selected = e.target.value;
        setValue('payment_type', selected)
    }

    const handleSelectGender = (e) => {
        const selected = e.target.value;
        setValue('gender', selected)
    }

    const handleSelectCharitable = (e) => {
        const selected = e.target.value;
        setValue('charitable', selected)
    }

    const updateUsername = (e) => {
        e.preventDefault()
        UpdateInfluencerUsername(firstname, lastname)
    }

    const ChangeAudienceLocation = (data) => {
        
        setValue('audience_location', data)

        geocodeByAddress(data)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) =>
            setValue('audience_geolocation', lat + ','+ lng)
        );
    }


    useEffect(() => {
        if (getData) {
            setValue('education_location', getData.state?.education_location)
            setValue('location', getData.state?.location)
            setValue('payment_type', getData.state?.payment_type)
            setValue('gender', getData.state?.gender)
            setValue('charitable', getData.state?.charitable)
        }
    }, [getData]);

    useEffect(() => { setValue('interest', interestOption) }, [interestOption])
    useEffect(() => { setValue('social_media_audience', audience) }, [audience])
    useEffect(() => { setValue('type_of_collabration', content) }, [audience])

    useEffect(()=>{
        if(pp !== '') {
            setValue('profile_image', pp)
        }
    },[pp])

    useEffect(() => {
        getPaymentType()

    }, [data])

    useEffect(() => {
        influencerInterest()
        getAudienceData()
        getContentData()
        console.log(isSubmitting)
    }, [])

    useEffect(()=> {
        setFirstName(prevFirstName ? prevFirstName : prevUsername)
        setLastName(prevLastName)
    }, [])

    useEffect(() => { }, [data, paymentType])

    // useEffect(() => {
    //     getValues('audience_location')
    // }, []);

    return (
        <>

            <Header />
            <Sidebar />

            <div className=" sm:ml-64 ml-0 mt-20 user-edit">
                <div className="container mx-auto ">
                    <div className="relative flex flex-col mt-10 overflow-hidden max-h-full">
                        <h2 className="p-4 pl-0 text-2xl font-bold">Update Profile</h2>
                        <Tabs.Group
                            aria-label="Update profile tabs"
                            className="tab-btn"
                            style="underline"
                            >
                            <Tabs.Item 
                            title="Profile"
                            active={true}
                            >
                               
                                <div className="bg-secondary p-4 rounded-t-lg text-white">
                                    <h3 className="font-bold text-white">
                                        Basic Information
                                    </h3>
                                </div>
                                <div className="w-full p-6 bg-white rounded-b-lg shadow-lg">
                                    <form className="" onSubmit={handleSubmit(handleSubmitForm)} >
                                        <div className="p-6 border ">
                                            <Dropzone setpp={setpp} oldImage={getData.state?.profile_image}></Dropzone>
                                        </div>
                                        <hr className="mb-4" />
                                        <div className="">
                                            <label htmlFor="bio" className="block text-black font-bold">Bio</label>
                                            <textarea name="bio" cols="30" rows="5" id="bio" defaultValue={getData.state?.bio} {...register('bio')} className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary"></textarea>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4">
                                            <div className="grid grid-rows-5 sm:gap-y-4 gap-3">
                                                <div className="">
                                                    <label htmlFor="age" className="block text-black font-bold">Age</label>
                                                    <input type="number" defaultValue={getData.state?.age} {...register('age', { min: 16, max: 70 })} name="age" id="age" className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" />
                                                    {errors.age && (
                                                        <div className="mb-3 text-normal text-red-500">
                                                            {errors.age.message}
                                                        </div>)}
                                                </div>
                                                <div className="">
                                                    <label htmlFor="career" className="block text-black font-bold">Career</label>
                                                    <input type="text" name="bio" defaultValue={getData.state?.career} {...register('career')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                </div>
                                                <div className="">
                                                    <label htmlFor="gender" className="block text-black font-bold">Gender</label>
                                                    <select className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" id="select" onChange={handleSelectGender} defaultValue={getData?.state?.show_payment_type}>
                                                        <option value={'Female'} selected={getData?.state?.show_payment_type === "Female" ?  "selected" : ""}>Female</option>
                                                        <option value={'Male'} selected={getData?.state?.show_payment_type === "Male" ? "selected" : ""}>Male</option>
                                                        <option value={'Rather Not Say'} selected={getData?.state?.show_payment_type === "Rather Not Say" ? "selected" : ""}>Rather Not Say</option>         
                                                    </select>
                                                </div>
                                                <div className="">
                                                    <label htmlFor="charitable" className="block text-black font-bold">Charitable</label>
                                                    <select className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" id="select" onChange={handleSelectCharitable} defaultValue={getData?.state?.show_payment_type}>
                                                        <option value={true} selected={getData?.state?.charitable === true ?  "selected" : ""}>Yes</option>
                                                        <option value={false} selected={getData?.state?.charitable === false ? "selected" : ""}>No</option>       
                                                    </select>
                                                </div>
                                                <div className="">
                                                    <label htmlFor="interest" className="block text-black font-bold mb-2">Interests (3 max)</label>
                                                    <Select isMulti 
                                                    id="select" 
                                                    options={interest} 
                                                    value={userInterest}
                                                    menuPortalTarget={document.body}
                                                    onChange={(e) => {setUserInterest(e); setInterests(e)}}
                                                    closeMenuOnSelect={false}
                                                    isClearable
                                                    styles={{
                                                        input: (provided) => ({
                                                            ...provided,
                                                            color: 'black',
                                                            boxShadow: "none",
                                                            outline: "none",
                                                            ':hover': {
                                                                '--tw-ring-color': 'black !important'
                                                            },
                                                            ':focus': {
                                                                '--tw-ring-color': 'black !important'
                                                            },
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            border: '1px solid black',
                                                            ':hover': {
                                                                border: '1px solid #008e95 !important'
                                                            },
                                                            boxShadow: "none",
                                                            outline: "none"
                                                        }),
                                                        
                                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                            const color = chroma('#008e95');
                                                            return {
                                                            ...styles,
                                                            backgroundColor: isDisabled
                                                                ? undefined
                                                                : isSelected
                                                                ? data.color
                                                                : isFocused
                                                                ? color.alpha(0.1).css()
                                                                : undefined,
                                                                ':active': {
                                                                    ...styles[':active'],
                                                                    backgroundColor: !isDisabled
                                                                    ? isSelected
                                                                    ? color
                                                                    : color.alpha(0.3).css()
                                                                    : undefined,
                                                                },
                                                            };
                                                        },
                                                        multiValueRemove:(provided) => ({
                                                            ...provided,
                                                            ':hover' :{
                                                                backgroundColor: '#008e95',
                                                                color: '#000000'
                                                            }
                                                        })
                                                    }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid grid-rows-5 sm:gap-y-4 gap-y-3">
                                                <div className="">
                                                    <label htmlFor="location" className="block text-black font-bold mb-2">Location</label>
                                                    <GooglePlacesAutocomplete
                                                        apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                                        selectProps={{
                                                            onChange: (value) => { setValue('location', value.value.description) },
                                                            defaultInputValue: getData.state?.location ? getData.state?.location : '',
                                                            styles:{
                                                                input: (provided) => ({
                                                                    ...provided,
                                                                    color: 'black',
                                                                    boxShadow: "none",
                                                                    outline: "none",
                                                                    ':hover': {
                                                                        '--tw-ring-color': 'black !important'
                                                                    },
                                                                    ':focus': {
                                                                        '--tw-ring-color': 'black !important'
                                                                    },
                                                                }),
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    border: '1px solid black',
                                                                    ':hover': {
                                                                        border: '1px solid #008e95 !important'
                                                                    },
                                                                    boxShadow: "none",
                                                                    outline: "none"
                                                                }),
                                                                
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                                    const color = chroma('#008e95');
                                                                    return {
                                                                    ...styles,
                                                                    backgroundColor: isDisabled
                                                                        ? undefined
                                                                        : isSelected
                                                                        ? data.color
                                                                        : isFocused
                                                                        ? color.alpha(0.1).css()
                                                                        : undefined,
                                                                        ':active': {
                                                                            ...styles[':active'],
                                                                            backgroundColor: !isDisabled
                                                                            ? isSelected
                                                                            ? color
                                                                            : color.alpha(0.3).css()
                                                                            : undefined,
                                                                        },
                                                                    };
                                                                },
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="">
                                                    <label htmlFor="education_location" className="block text-black font-bold mb-2">Education location</label>
                                                    <GooglePlacesAutocomplete
                                                        apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                                        selectProps={{
                                                            onChange: (value) => { setValue('education_location', value.value.description) },
                                                            defaultInputValue: getData.state?.education_location ? getData.state?.education_location : '',
                                                            styles:{
                                                                input: (provided) => ({
                                                                    ...provided,
                                                                    color: 'black',
                                                                    boxShadow: "none",
                                                                    outline: "none",
                                                                    ':hover': {
                                                                        '--tw-ring-color': 'black !important'
                                                                    },
                                                                    ':focus': {
                                                                        '--tw-ring-color': 'black !important'
                                                                    },
                                                                }),
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    border: '1px solid black',
                                                                    ':hover': {
                                                                        border: '1px solid #008e95 !important'
                                                                    },
                                                                    boxShadow: "none",
                                                                    outline: "none"
                                                                }),
                                                                
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                                    const color = chroma('#008e95');
                                                                    return {
                                                                    ...styles,
                                                                    backgroundColor: isDisabled
                                                                        ? undefined
                                                                        : isSelected
                                                                        ? data.color
                                                                        : isFocused
                                                                        ? color.alpha(0.1).css()
                                                                        : undefined,
                                                                        ':active': {
                                                                            ...styles[':active'],
                                                                            backgroundColor: !isDisabled
                                                                            ? isSelected
                                                                            ? color
                                                                            : color.alpha(0.3).css()
                                                                            : undefined,
                                                                        },
                                                                    };
                                                                },
                                                            }}
                                                        }
                                                    />
                                                </div>
                                                <div className="">
                                                    <label htmlFor="price_per_post" className="block text-black font-bold">Price per post(£)</label>
                                                    <input type="number" defaultValue={getData.state?.price_per_post} {...register('price_per_post')} id="price_per_post" name="price_per_post" className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" />
                                                    {errors.price_per_post && (
                                                        <div className="mb-3 text-normal text-red-500">
                                                            {errors.price_per_post.message}
                                                        </div>)}
                                                </div>
                                                <div className="">
                                                    <label htmlFor="followers_number" className="block text-black font-bold">Number of followers</label>
                                                    <input type="number" name="followers_number" defaultValue={getData.state?.followers_number} {...register('followers_number')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                    {errors.followers_number && (
                                                        <div className="mb-3 text-normal text-red-500">
                                                            {errors.followers_number.message}
                                                        </div>)}
                                                </div>
                                                <div className="">
                                                    <label htmlFor="payment_type" className="block text-black font-bold">Payment Type</label>
                                                    <select className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" id="select" onChange={handleSelectPayment} defaultValue={getData?.state?.show_payment_type}>
                                                        <option value={getData?.state?.show_payment_type} selected={getData?.state?.show_payment_type ? "" : "selected"} >Not Specified</option>
                                                        {paymentType && paymentType.map(obj => {
                                                            return (
                                                                <option value={obj.id} selected={getData?.state?.show_payment_type === obj.payment ? "selected" : ""}>{obj.payment}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="my-4"/>
                                        <div>
                                            <div className="bg-fourth text-black p-4 mb-4 rounded-lg">
                                                <h3 className="font-bold text-lg">More Information</h3>
                                            </div>
                                            <div className="grid sm:grid-cols-2 grid-cols-1 gap-6">
                                                <div className="">
                                                    <label htmlFor="interest" className="block text-black font-bold mb-2">Social Media Audience</label>
                                                    <Select isMulti 
                                                    id="select" 
                                                    options={demoData} 
                                                    value={userAudience}
                                                    menuPortalTarget={document.body}
                                                    onChange={(e) => {setUserAudience(e); setMediaAudience(e)}}
                                                    closeMenuOnSelect={false}
                                                    isClearable
                                                    styles={{
                                                        input: (provided) => ({
                                                            ...provided,
                                                            color: 'black',
                                                            boxShadow: "none",
                                                            outline: "none",
                                                            ':hover': {
                                                                '--tw-ring-color': 'black !important'
                                                            },
                                                            ':focus': {
                                                                '--tw-ring-color': 'black !important'
                                                            },
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            border: '1px solid black',
                                                            ':hover': {
                                                                border: '1px solid #008e95 !important'
                                                            },
                                                            boxShadow: "none",
                                                            outline: "none"
                                                        }),
                                                        
                                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                            const color = chroma('#008e95');
                                                            return {
                                                            ...styles,
                                                            backgroundColor: isDisabled
                                                                ? undefined
                                                                : isSelected
                                                                ? data.color
                                                                : isFocused
                                                                ? color.alpha(0.1).css()
                                                                : undefined,
                                                                ':active': {
                                                                    ...styles[':active'],
                                                                    backgroundColor: !isDisabled
                                                                    ? isSelected
                                                                    ? color
                                                                    : color.alpha(0.3).css()
                                                                    : undefined,
                                                                },
                                                            };
                                                        },
                                                        multiValueRemove:(provided) => ({
                                                            ...provided,
                                                            ':hover' :{
                                                                backgroundColor: '#008e95',
                                                                color: '#000000'
                                                            }
                                                        })
                                                    }}
                                                    />
                                                </div>
                                                <div className="">
                                                    <label htmlFor="interest" className="block text-black font-bold mb-2">Type of Collaboration</label>
                                                    <Select isMulti 
                                                    id="select" 
                                                    options={contentData} 
                                                    value={userContent}
                                                    menuPortalTarget={document.body}
                                                    onChange={(e) => {setUserContent(e); setMediaContent(e)}}
                                                    closeMenuOnSelect={false}
                                                    isClearable
                                                    styles={{
                                                        input: (provided) => ({
                                                            ...provided,
                                                            color: 'black',
                                                            boxShadow: "none",
                                                            outline: "none",
                                                            ':hover': {
                                                                '--tw-ring-color': 'black !important'
                                                            },
                                                            ':focus': {
                                                                '--tw-ring-color': 'black !important'
                                                            },
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            border: '1px solid black',
                                                            ':hover': {
                                                                border: '1px solid #008e95 !important'
                                                            },
                                                            boxShadow: "none",
                                                            outline: "none"
                                                        }),
                                                        
                                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                            const color = chroma('#008e95');
                                                            return {
                                                            ...styles,
                                                            backgroundColor: isDisabled
                                                                ? undefined
                                                                : isSelected
                                                                ? data.color
                                                                : isFocused
                                                                ? color.alpha(0.1).css()
                                                                : undefined,
                                                                ':active': {
                                                                    ...styles[':active'],
                                                                    backgroundColor: !isDisabled
                                                                    ? isSelected
                                                                    ? color
                                                                    : color.alpha(0.3).css()
                                                                    : undefined,
                                                                },
                                                            };
                                                        },
                                                        multiValueRemove:(provided) => ({
                                                            ...provided,
                                                            ':hover' :{
                                                                backgroundColor: '#008e95',
                                                                color: '#000000'
                                                            }
                                                        })
                                                    }}
                                                    />
                                                </div>
                                                <div className="">
                                                    <label htmlFor="location" className="block text-black font-bold mb-2">Audience Location</label>
                                                    <GooglePlacesAutocomplete
                                                        apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                                        selectProps={{
                                                            onChange: (value) => {ChangeAudienceLocation(value.value.description) },
                                                            defaultInputValue: getData.state?.audience_location ? getData.state?.audience_location : '',
                                                            styles:{
                                                                input: (provided) => ({
                                                                    ...provided,
                                                                    color: 'black',
                                                                    boxShadow: "none",
                                                                    outline: "none",
                                                                    ':hover': {
                                                                        '--tw-ring-color': 'black !important'
                                                                    },
                                                                    ':focus': {
                                                                        '--tw-ring-color': 'black !important'
                                                                    },
                                                                }),
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    border: '1px solid black',
                                                                    ':hover': {
                                                                        border: '1px solid #008e95 !important'
                                                                    },
                                                                    boxShadow: "none",
                                                                    outline: "none"
                                                                }),
                                                                
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                                    const color = chroma('#008e95');
                                                                    return {
                                                                    ...styles,
                                                                    backgroundColor: isDisabled
                                                                        ? undefined
                                                                        : isSelected
                                                                        ? data.color
                                                                        : isFocused
                                                                        ? color.alpha(0.1).css()
                                                                        : undefined,
                                                                        ':active': {
                                                                            ...styles[':active'],
                                                                            backgroundColor: !isDisabled
                                                                            ? isSelected
                                                                            ? color
                                                                            : color.alpha(0.3).css()
                                                                            : undefined,
                                                                        },
                                                                    };
                                                                },
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 w-ful justify-items-center">
                                            <button
                                                className="w-full py-2 text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black" id="submitBtn">
                                                    Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Tabs.Item>
                            <Tabs.Item
                                title="Username"
                            >
                                 <div className="bg-secondary p-4 rounded-t-lg text-white">
                                    <h3 className="font-bold text-white">
                                        User Information
                                    </h3>
                                </div>
                                <div className="w-full p-6 bg-white rounded-b-lg shadow-lg">
                                      {/* <div className='mb-3 p-2 rounded-lg text-white text-center text-sm bg-primary sm:w-[240px] w-full'>
                                    <Popup overlayStyle="w-12" trigger={() => <button className='text-white' id="connectBtn"><b className="text-white">Change Your Name</b> </button>}
                                        {...{ overlayStyle, contentStyle }}

                                        position={position}>
                                        <h1 className="p-1">Note: The Name Should Be Available </h1>
                                        <form>
                                            <div>
                                                <input DefualtValue={"Hi, it would be great to collaborate"} className="rounded-l-lg p-1 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white" onChange={(e) => setUsername(e.target.value)} />
                                                <button onClick={(e) => updateUsername(e)}
                                                    className="px-4 rounded-r-lg bg-primary text-white font-bold p-1 uppercase border-t border-b border-r"
                                                >Change</button>
                                            </div>
                                        </form>
                                    </Popup>
                                </div> */}
                                    <form>
                                        <div>
                                            <div className="grid sm:grid-cols-2 grid-cols-1 gap-6">
                                                <div className="">
                                                    <label htmlFor="first_name" className="block text-black font-bold">First Name</label>
                                                    <input type="text" name="bio" defaultValue={prevFirstName ? prevFirstName : prevUsername} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" onChange={(e) => setFirstName(e.target.value)}/>
                                                </div>
                                                <div className="">
                                                    <label htmlFor="last_name" className="block text-black font-bold">Last Name</label>
                                                    <input type="text" name="bio" defaultValue={prevLastName} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" onChange={(e) => setLastName(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 w-ful justify-items-center">
                                            <button className="w-full py-2 text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black" id="submitBtn" onClick={(e) => updateUsername(e)}>
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                
                            </Tabs.Item>
                          
                        </Tabs.Group>
                       
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateUserInfo;