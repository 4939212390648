import { useEffect, useState, useRef } from 'react';
import useAxios, { socketApiUrl, ApiUrl, profileImageLink, imgApi } from '../../API/Api.config';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import ImageChatApi from '../../API/ImageChatApi';
import TokenService from '../../API/token.service';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import secureLocalStorage from "react-secure-storage";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Tooltip, Button } from 'flowbite-react';

const Messages = () => {
    document.title = "Messaging | Promote"

    const token = TokenService.getLocalAccessToken()
    const nav = useNavigate()
    const [receiverChat, setReceiverChat] = useState(0)
    const [receiver, setReceiver] = useState(0);
    const [message, setMessage] = useState('');
    const [socketUrl, setSocketUrl] = useState('');
    const [messageHistory, setMessageHistory] = useState([]);
    const [data, setData] = useState([]);
    const [imageLink, setImageLink] = useState('');
    const [messageName, setMessageName] = useState('');
    const [sendImage, setSendImage] = useState(true);
    const [allMessages, setAllMessages] = useState([])
    const [allImageMessages, setAllImageMessages] = useState([])
    const [entireMessages, setEntireMessages] = useState([])
    const [friendData, setFriendData] = useState([]);
    const messages = useRef(allMessages)
    const [allMessageStatus, setAllMessageStatus] = useState([]);
    const didUnmount = useRef(false);
    const profileCompleted = secureLocalStorage.getItem('profile_completed');
    const role = secureLocalStorage.getItem('role');
    const myuserid = secureLocalStorage.getItem('user_id');
    const username = secureLocalStorage.getItem('username');
    const bottomRef = useRef(null);
    const [paymentStatus, setPaymentStatus] = useState([])
    const MySwal = withReactContent(Swal)

    const { lastMessage, readyState, sendJsonMessage, } = useWebSocket(socketUrl !== '' ? socketUrl : socketApiUrl +`ws/chat/api/${receiver}/?token=${token}`,
        {
            shouldReconnect: (closeEvent) => {
                /*
                useWebSocket will handle unmounting for you, but this is an example of a 
                case in which you would not want it to automatically reconnect
              */
                return didUnmount.current === false;
            },
            reconnectAttempts: 10,
            reconnectInterval: 3000,
        }
    );

    const {responseImage, isSending, ImageChatUpload} = ImageChatApi();

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    };

    const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": `Token ${token}`,
    }

    async function getUserConnection() {
        try {
            const response = await axios.get(`get_connection/`, { headers })
            if (response.data !== null) {
                setData(response.data);
                setTimeout(function(){
                    const query = window.location.href
                    const paramString = query.split('?')[1]
                    const sp = new URLSearchParams(paramString);
                    if (sp.get('name') && sp.get('name')!== ''){
                        document.getElementById(sp.get('name')).click()
                    }
                }, 1000)
            }

            if (response.status == 401) {
                nav('/login')
            }

        }
        catch (error) {
            if (error.response.status == 401) {
                // console.log(response.status);
                nav('/login')
            };
        }
    }

    async function getUseFriendrConnection() {
        try {
            const response = await axios.get(`get_friend_connection/`, { headers })
            if (response.data !== null) {
                setFriendData(response.data);
            }

            if (response.status == 401) {
                nav('/login')
            }

        }
        catch (error) {
            if (error.response.status == 401) {
                nav('/login')
            };
        }
    }

    async function getAllMessages(receiver) {
        try {
            axios.get(`get_all_messages/${receiver}`, { headers })
                .then(response => { 
                    setAllMessages(response.data); 
                    setTimeout(() => {
                        const element = document.getElementById('all-messages');
                        element.scrollTop = element.scrollHeight;    
                    }, 100);
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        nav('/login')
                    };
                });
        } catch (e) {
            console.log(e)
        }
    }

    async function getAllImageMessages(receiver) {
        try {
            axios.get(`chat_image/?receiver=${receiver}`, { headers })
                .then(response => { 
                    setAllImageMessages(response.data);
                  
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        nav('/login')
                    };
                });
        } catch (e) {
            console.log(e)
        }
    }

    async function getMessageStatus(receiver) {
        try {
            axios.get(`message_status/${receiver}`, { headers })
                .then(response => { })
                .catch(error => {
                    if (error.response.status == 401) {
                        nav('/login')
                    };
                    console.log(error)
                });
        } catch (e) {
            console.log(e)
        }
    }

    async function getAllMessageStatus() {
        try {
            axios.get(`all_message_status/`, { headers })
                .then(response => { setAllMessageStatus(response.data) })
                .catch(error => {
                    if (error.response.status == 401) {
                        nav('/login')
                    };
                    console.log(error)
                });
        } catch (e) {
            console.log(e)
        }
    }

    const handleUserDetails = (id) => {
        secureLocalStorage.setItem('userid', id);
        secureLocalStorage.setItem("profile_view", 2)
        window.open('/show-startup/');
    }

    const handleInfluencerDetails = (id) => {
        secureLocalStorage.setItem('userid', id);
        secureLocalStorage.setItem("profile_view", 1)
        window.open('/show-user/');
    }

    // const scrollToBottom = () => {
    //     setTimeout(() => {
    //         const element = document.getElementById('all-messages');
    //         element.scrollTop = element.scrollHeight;    
    //     }, 1500);
    // }

    // useEffect(() => {
    //     // 👇️ scroll to bottom every time messages change
    //     bottomRef.current?.scrollIntoView({ behavior: 'auto' });
    // }, [allMessages, lastMessage]);


    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory((prev) => prev.concat({
                type: "text",
                date: new Date(),
                text: JSON.parse(lastMessage.data).message,
                image: JSON.parse(lastMessage.data).image,
                sender: JSON.parse(lastMessage.data).sender,
            }));
        }

        console.log(lastMessage);
    }, [lastMessage, setMessageHistory]);

    useEffect(() => {
        getUserConnection()
        getUseFriendrConnection()
        getAllMessages()
        getAllMessageStatus()
        // updateFriendrConnectionTime()
    }, [])

    useEffect(() => {
    }, [socketUrl])

    const handleClickChangeSocketUrl = (receiver) => {
        setSocketUrl(socketApiUrl +`ws/chat/api/${receiver}/?token=${token}`);
        setReceiver(receiver);
        setMessageHistory([])
        getAllMessages();
        console.log(socketUrl)
    };

    const handleClickSendMessage = (event) => {
        event.preventDefault();

        if (document.getElementById('chatInput').value !== '') {
            sendJsonMessage({ "message": message, "image": "", 'message_to': receiver, 'sender': myuserid });
            document.getElementById('chatInput').value = ''
            setMessage('')
        }

        getUseFriendrConnection()
        getUserConnection()
        getAllMessages()

    };

    const handleImageUpload = (data) => {
        ImageChatUpload({
            'image': data.image,
            'receiver': data.receiver,
        })
    }


    const handleImage = async() => {
        
        const { value: file } = await Swal.fire({
            title: 'Select image',
            input: 'file',
            inputAttributes: {
              'accept': 'image/*',
              'aria-label': 'Upload your profile picture'
            }
        })

        if (file) {
            const base64 =  await convertBase64(file)
      
            handleImageUpload({
                'image': base64,
                'receiver': receiver,
            })

        }
    }
        
    const openImage = (img) => {
        Swal.fire({
            imageUrl: imgApi + img,
            imageAlt: 'Custom image',
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                image: 'img-pop-up',
                popup:'html-img'
            }
        })
    }
    
    const handleMessage = (event) => {
        if (window.innerWidth < 768) {
            document.getElementById('chatMsg').style.display = 'block';
            document.getElementById('chatMsg').style.position = 'absolute';
            
        }
    };

    const closeMessage = (event) => {
        if (window.innerWidth < 768) {
            document.getElementById('chatMsg').style.display = 'none';
            
        }
    };
    
   

    useEffect(()=>{
        setEntireMessages([...allMessages, ...allImageMessages]) ;
    },[allImageMessages, allMessages ])
    
    useEffect(()=>{
    },[entireMessages])

    useEffect(()=>{
        if(isSending === 'sent')
            sendJsonMessage({ "message": '', "image": String(responseImage) , 'message_to': receiver, 'sender': myuserid });
    },[isSending])
    
    useEffect(() => {}, [receiver])
    useEffect(() => {}, [responseImage])
    useEffect(() => {}, [imageLink])
    useEffect(() => {}, [allMessageStatus])
    useEffect(() => {getAllMessageStatus()}, [receiver])


    useEffect(() => {
        getAllMessages()
    }, [messageName])

    useEffect(()=>{
        if(!profileCompleted){
            if(role == 1) {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/influencer/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }  else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/business/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }     
        }
    }, [])


    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    return (
        <>
            <Header />
            <Sidebar />
            <div className='sm:ml-64 mt-20 mb-10 messages'>
                <div className='container mx-auto '>
                    <div>
                        <h2 className="p-4 pb-0 text-2xl font-bold">Messaging</h2>
                    </div>
                    <div className="">
                        <div className="grid sm:grid-cols-3 grid-cols-1 gap-4">
                            <div className='sm:col-span-1 col-span-1 p-4'>
                                <div className='bg-fourth h-full chat-ppl rounded-lg overflow-y-auto'>
                                    {friendData.length > 0 ? friendData.map(obj => {
                                        return (
                                            <div className='p-2 pb-0'>
                                                <div className="flex flex-row">
                                                    <div className=" rounded-lg flex flex-row p-4 bg-white border w-full">
                                                        <button className='p-2 text-start w-full rounded-lg text-black flex items-center' id={obj.user_name.replace('', '_')} onClick={() => { handleClickChangeSocketUrl(obj.userid); setMessageName(obj.user_name); handleMessage(); getAllMessages(obj.userid); getMessageStatus(obj.user_email); getAllImageMessages(obj.userid)}}  >
                                                            <div className='mr-4'>
                                                                <div className='profile-picture-chat rounded-full border border-primary'>
                                                                    <img src={obj.user_image[0].logo ? profileImageLink + obj.user_image[0].logo : 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png'} alt={obj.user_name +" image"} className='' loading='lazy' />
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-between items-center w-full'>
                                                                <div className='h-full flex items-center'>
                                                                    <p>
                                                                        {obj.user_name}
                                                                    </p>
                                                                </div>
                                                                <div className='h-full flex items-center justify-center'>
                                                                    {allMessageStatus.length > 0 && allMessageStatus.map(status => {
                                                                        return (status.readed === false && status.sender === obj.user_email ? <div className='notify text-left control'></div> : <div></div>)
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) :
                                        data.length > 0 ? data.map(obj => {
                                            return (
                                                <div className='p-2 pb-0'>
                                                    <div className="flex flex-row">
                                                        <div className="rounded-lg flex flex-row justify-center items-center p-4 bg-white border w-full">
                                                            <button className='p-2 text-start w-full rounded-lg text-black flex items-center' id={obj.friend_name.replace(' ', '_')} onClick={() => { handleClickChangeSocketUrl(obj.friendid); setMessageName(obj.friend_name); handleMessage(); getAllMessages(obj.friendid); getMessageStatus(obj.friend_email); getAllImageMessages(obj.friendid)}}  >
                                                                <div className='mr-4'>
                                                                    <div className=' profile-picture-chat rounded-full border border-primary'>
                                                                        <img src={obj.startup_image[0]?.profile_image ? profileImageLink + obj.startup_image[0]?.profile_image : 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png'} alt={obj.friend_name +" image"} className='' loading='lazy' />
                                                                    </div>
                                                                </div>
                                                                <div className='flex justify-between items-center w-full'>
                                                                    <div className='h-full flex items-center'>
                                                                        {obj.friend_name}
                                                                    </div>
                                                                    <div className='h-full flex items-center justify-center'>
                                                                        {allMessageStatus.length > 0 && allMessageStatus.map(status => {
                                                                            return (status.readed === false && status.sender === obj.friend_email ? <div className='notify'></div> : <div></div>)
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <div className='flex flex-col justify-center items-center h-full'>
                                            <i className="fa-solid fa-comment text-gray-400"></i>
                                            <p className='text-gray-400 p-4 text-center '>
                                                Start connecting with people or wait for people to message you
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='sm:col-span-2 col-span-1 p-4 sm:block hidden' id="chatMsg">
                                {readyState == ReadyState.OPEN && receiver > 0 ? 
                                    <>
                                        <div className='bg-fourth chat-all-msg rounded-lg'>
                                            <div className='bg-primary p-6 text-white shadow-lg rounded-t-lg'>
                                                <div className='flex flex-row gap-4'>
                                                    <div className='sm:basis-full basis-11/12'>
                                                        {role == 1 ? 
                                                            <button onClick={() => handleUserDetails(receiver)}>
                                                                <span className='underline underline-offset-2'>
                                                                    {messageName}    
                                                                    <Tooltip
                                                                        content="Please send a screenshot of the message you want to report to support@promote.io"
                                                                        placement="bottom"
                                                                        >
                                                                            <i className="fa-solid fa-circle-exclamation"></i>
                                                                    </Tooltip>
                                                                </span>
                                                            </button>
                                                            :
                                                            <div className='flex justify-between'>
                                                                <button onClick={()=>{handleInfluencerDetails(receiver)}}>
                                                                    {messageName}
                                                                </button>
                                                                    <Tooltip
                                                                        content="Please send a screenshot of the message you want to report to support@promote.io"
                                                                        placement="bottom"
                                                                        >
                                                                        <i className="fa-solid fa-circle-exclamation"></i>
                                                                    </Tooltip>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='sm:basis-0/12 basis-1/12 sm:hidden block'>
                                                        <button onClick={() => closeMessage()}>
                                                            <i className="fa-solid fa-xmark"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* {lastMessage ? <span className='p-4'>Last message: {JSON.parse(lastMessage.data).message}</span> : null} */}
                                            <div className='p-4 chat-msg overflow-auto' id='all-messages'>
                                                {entireMessages.length > 0 && entireMessages.sort((a,b) =>
                                                    new Date(a.created_at) - new Date(b.created_at)
                                                ).map(obj => {
                                                    return (
                                                        obj.receiver == receiver ?
                                                            <div key={obj.id} className='mt-2 chat-my-msg flex justify-end text-left'>
                                                                {obj.image &&
                                                                    <span className='bg-primary p-2 rounded-lg rounded-br-none img-chat cursor-pointer' onClick={()=> {openImage(obj.image)}}>
                                                                        <img src={imgApi + obj.image} alt="Chat image" className=''/>
                                                                    </span>
                                                                }

                                                                {obj.message &&
                                                                    obj.message.length > 100 && 
                                                                        <div className='bg-primary p-2 ml-2 rounded-lg rounded-br-none text-white text-sm w-3/4 break-words '>
                                                                            {obj.message ? obj.message : null}
                                                                        </div> 
                                                                }

                                                                {obj.message &&
                                                                    obj.message.length <= 100 && 
                                                                        <span className='bg-primary p-2 ml-2 rounded-lg rounded-br-none text-white text-sm'>
                                                                            {obj.message ? obj.message : null}
                                                                        </span>
                                                                }
                                                            </div>
                                                            :
                                                            <div key={obj.id} className='mt-2 chat-my-msg flex justify-start text-left'>

                                                                {obj.image &&
                                                                    <div className='flex justify-start items-center'>
                                                                        <span className='bg-gray-500 p-2 mr-2 rounded-lg rounded-bl-none img-chat cursor-pointer' onClick={() => {openImage(obj.image)}}>
                                                                            <img src={imgApi + obj.image} alt="Chat image" className=''/>
                                                                        </span>
                                                                    </div>
                                                                }

                                                                {obj.message &&
                                                                    obj.message.length > 100 && 
                                                                    <div className='flex justify-start items-center'>
                                                                        <div className='bg-gray-500 p-2 mr-2 rounded-lg rounded-bl-none text-white text-sm w-3/4 break-words'>
                                                                            {obj.message ? obj.message : null}
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {obj.message &&
                                                                    obj.message.length <= 100 && 
                                                                    <div className='flex justify-start items-center'>
                                                                        <span className='bg-gray-500 p-2 mr-2 rounded-lg rounded-bl-none text-white text-sm'>
                                                                        {obj.message ? obj.message : null}
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                    )
                                                })}

                                                {messageHistory.map((message, idx) => {
                                                    return (
                                                        // allMessages[0].sender !== allMessages[0].receiver ?
                                                        message.sender == myuserid ?                                                
                                                           <div key={idx} className='mt-2 chat-my-msg flex justify-end text-left overflow-auto'>
                                                                {message.image &&
                                                                
                                                                    <div className='bg-primary p-2 rounded-lg rounded-br-none img-chat cursor-pointer' onClick={()=> {openImage(message.image)}}>
                                                                        <img src={imgApi + message.image} alt="Chat image" className=''/>
                                                                    </div>

                                                                }

                                                                {message.text && 
                                                                    message.text.length > 100 && 
                                                                    <div className='bg-primary p-2 rounded-lg rounded-br-none text-white text-sm w-3/4 break-words'>
                                                                        {message.text ? message.text : null}
                                                                    </div>
                                                                }

                                                                {message.text &&
                                                                    message.text.length <= 100 && 
                                                                    <span className='bg-primary p-2 rounded-lg rounded-br-none text-white text-sm'>
                                                                        {message.text ? message.text : null}
                                                                    </span>
                                                                }
                                                         </div>
                                                         :
                                                         <div key={idx} className='mt-2 chat-my-msg flex justify-start text-left'>
                                                                {message.image &&
                                                                    <div className='flex justify-start items-center'>
                                                                        <div className='bg-gray-500 p-2 rounded-lg rounded-bl-none img-chat cursor-pointer' onClick={()=> {openImage(message.image)}}>
                                                                            <img src={imgApi + message.image} alt="Chat image" className=''/>
                                                                        </div>
                                                                        <Tooltip
                                                                        content="Kindly take a screenshot and send to support@promote.io to report this message"
                                                                        placement="top"
                                                                        >
                                                                              <i className="fa-solid fa-circle-exclamation"></i>
                                                                        </Tooltip>
                                                                    </div>
                                                                }

                                                                {message.text &&
                                                                    message.text.length > 100 && 
                                                                    <div className='flex justify-start items-center'>
                                                                        <div className='bg-gray-500 p-2 rounded-lg rounded-bl-none text-white text-sm w-3/4 break-words'>
                                                                            {message.text ? message.text : null}
                                                                        </div>
                                                                        <Tooltip
                                                                        content="Kindly take a screenshot and send to support@promote.io to report this message"
                                                                        placement="top"
                                                                        >
                                                                              <i className="fa-solid fa-circle-exclamation"></i>
                                                                        </Tooltip>
                                                                    </div>
                                                                    
                                                                }

                                                                {message.text &&
                                                                    message.text.length <= 100 && 
                                                                    <div className='flex justify-start items-center'>
                                                                        <span className='bg-gray-500 p-2 rounded-lg rounded-bl-none text-white text-sm'>
                                                                            {message.text ? message.text : null}
                                                                        </span>
                                                                        <Tooltip
                                                                        content="Kindly take a screenshot and send to support@promote.io to report this message"
                                                                        placement="top"
                                                                        >
                                                                              <i className="fa-solid fa-circle-exclamation"></i>
                                                                        </Tooltip>
                                                                    </div>

                                                                }
                                                         </div>
                                                    )
                                                })}
                                            </div>
                                            {/* <div ref={bottomRef} /> */}
                                            <div id="focus-last-message"></div>
                                            <form className='chat-input px-4'>
                                                <label for="chatInput" className="sr-only">Your message</label>
                                                <div className="flex items-center px-3 py-2 rounded-lg bg-white">
                                                    <button type="button" className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100" onClick={() => {handleImage(responseImage)}}>
                                                        <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd"></path></svg>
                                                        <span className="sr-only">Upload image</span>
                                                    </button>
                                                    <textarea id="chatInput" rows="1" className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-primary focus:border-primary max-h-[65px]" placeholder="Your message..." onChange={e => { setMessage(e.target.value) }}></textarea>
                                                    {readyState === ReadyState.OPEN && 
                                                        <button className="inline-flex justify-center p-2 text-primary rounded-lg cursor-pointer hover:bg-fourth" onClick={(e) => { handleClickSendMessage(e) }}>
                                                            <svg aria-hidden="true" className="w-6 h-6 rotate-90" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path></svg>
                                                            <span className="sr-only">Send message</span>
                                                        </button>
                                                    }
                                                    {readyState !== ReadyState.OPEN && 
                                                        <div role="status">
                                                            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-white animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                            </svg>
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                    :
                                    <div className='bg-fourth chat-all-msg p-4 rounded-lg' id='all-messages'>
                                        <div className='flex flex-col justify-center items-center h-full'>
                                            <i className="fa-solid fa-comment text-gray-400"></i>
                                            <p className='text-gray-400 mt-2'>
                                                Start messaging
                                            </p>
                                        </div>
                                    </div>
                                }
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Messages;