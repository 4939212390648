import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import { useStepperContext } from "../../../contexts/StepperContext";
import CheckboxButton from "../../../components/CheckboxButtons/CheckboxButtons";


function SocialMedia({setDataEmpty, heading, subHeading, apiName}){
    const { userData, setUserData } = useStepperContext();
    const [sc, setSc] = useState([]);
    const [allSc, setAllSc] = useState([]);

    async function getSocialNetworks() {
        try {
            await axios.get(ApiUrl + 'social_networks/', {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => setAllSc(response.data))
            .catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }
    
    const handleChange = (e) => {
        const { name, value, checked } = e.target;

        if(checked) {
            setSc([
                ...sc,
                {
                    id:name, 
                    social_network:value
                }
                                    
            ])

            setDataEmpty(false);            
        } else {
            sc.splice(sc.indexOf(name + 1), 1);

            if(sc.length <= 0) {
                setDataEmpty(true);
            } else {
                setDataEmpty(false);
            }
        }
    };
    
    useEffect(() => {
        getSocialNetworks();
    }, [])
    
    useEffect(() => {
        setUserData({ ...userData, [apiName]:sc });
    }, [sc])

    // useEffect(() => {}, [userData])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        {subHeading}
                    </small>
                </p>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card overflow-y">
                        <CheckboxButton
                        inputName={'social_network'}
                        id={'id'}
                        apiName={'social_network'}
                        apiName2={'social_network'}
                        onChange={handleChange}
                        icon={true}
                        options={allSc}
                        classNameLabel={""}
                        ></CheckboxButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SocialMedia;