import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { imgApi, ApiUrl } from '../../API/Api.config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TokenService from "../../API/token.service";
import secureLocalStorage from "react-secure-storage";
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Dropzone from '../../components/Dropzone/Dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
 

const JobApply = () => {

    document.title = "Campaign Application | PROMOTE"

    const profileCompleted = secureLocalStorage.getItem('profile_completed')
    const role = secureLocalStorage.getItem('role')
    const [loading, setLoading] = useState(false);
    const nav = useNavigate()
    const MySwal = withReactContent(Swal)
    const [ pp, setpp ] = useState("");

    // const convertBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const fileReader = new FileReader();
    //         fileReader.readAsDataURL(file)
    //         fileReader.onload = () => {
    //             resolve(fileReader.result);
    //         }
    //         fileReader.onerror = (error) => {
    //             reject(error);
    //         }
    //     })
    // }

    async function JobApplyApi(data) {
        setLoading(true)
        const token = TokenService.getLocalAccessToken();
    
        try {
            await axios.post(ApiUrl + 'job_apply/', data,
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your application has been sent',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                    setTimeout(function(){
                        nav('/campaigns');
                    }, 3000);
                })
                .catch(function (error) {
                    if(error.request.status === 400){
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'danger',
                            title: 'There was error submitting your application',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                }).finally(() => (
                    setLoading(false)
                ));
        } catch (error) {
            MySwal.fire({
                position: 'top',
                toast: true,
                icon: 'danger',
                title: 'There was error submitting your application',
                showConfirmButton: false,
                timer: 3000,
            })
        }
    };

    const handleClick = (img) =>{
        Swal.fire({
            imageUrl: imgApi + img,
            imageAlt: 'Custom image',
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                image: 'img-pop-up',
                popup:'html-img'
            }
        })
    }

    // const handleFileRead = async (event) => {
    //     const file = event.target.files[0]
    //     const base64 = await convertBase64(file)
    //     setValue('image', base64)
    // }

    const location = useLocation()

    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    useEffect(() => {
        setValue('job', location.state.id)
        setValue('startup', location.state.startup)
    }, [])

    useEffect(() => {
        setValue('image', pp)
    }, [pp])
    
    const handleSubmitForm = (data) => {
        JobApplyApi(data)
    }

    return (
        <>
           {/* {role == 1 &&
                <PureModal
                    header="Update Your Profile"
                    isOpen={!profileCompleted}
                    className="pure-modal-referral"
                >
                    <p>Please update your profile to continue using the website</p>
                    <div className='bg-primary p-4 mt-4 rounded-lg'>
                        <Link to={'/update_user_profile/'} className="text-white w-full block text-center">
                            Update my info
                        </Link>
                    </div>
                </PureModal>
            }
            {role == 2 &&
                <PureModal
                    header="Update Your Profile"
                    isOpen={!profileCompleted}
                    className="pure-modal-referral"
                >
                    <p>Please update your profile to continue using the website</p>
                    <div className='bg-primary p-4 mt-4 rounded-lg'>
                        <Link to={'/update_startup_profile/'} className="text-white w-full block text-center">
                            Update my info
                        </Link>
                    </div>
                </PureModal>
            } */}
            <Header />
            <Sidebar />
            <div className="sm:ml-64 mt-20 mb-10">
                <div className="container mx-auto ">
                    <div className="mt-10 bg-white">
                        <div className="">
                            <div className='p-6'>
                                <div className='mb-10'>
                                    <h5 className="text-2xl font-bold text-secondary">{location.state.title}</h5>
                                    <div className='startup'>
                                        {/* <img src={imgApi + location.state.startup_logo} className=" inline-block h-6 w-6" /> */}
                                        <p className='text-gray-500 inline-block'><small>{location.state.startup_name}</small></p>
                                    </div>
                                </div>
                                <div className='job-spec'>
                                    <div className='flex flex-row flex-wrap'>
                                        <div className='basis-1/2'>
                                            <div className='text-gray-500'>
                                                <small>
                                                    <div className='flex justify-start items-center' title='Job Payment'>
                                                        <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                            <i className="fa-solid fa-sterling-sign"></i>
                                                        </p>
                                                        <p className='inline-block'>
                                                            <b>Price</b>: {location.state.price}
                                                        </p>
                                                    </div>
                                                </small>
                                            </div>
                                            <div className='text-gray-500'>
                                                <small>
                                                    <div className='flex justify-start items-center' title='Min. followers'>
                                                        <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                            <i className="fa-solid fa-people-group"></i>
                                                        </p>
                                                        <p className='inline-block'>
                                                            <b>Min. followers</b>: {location.state.min_influencer_numbers}
                                                        </p>
                                                    </div>
                                                </small>
                                            </div>
                                            <div className='text-gray-500'>
                                                <small>
                                                    <div className='flex justify-start items-center' title='Min. followers'>
                                                        <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                            <i className="fa-solid fa-people-group"></i>
                                                        </p>
                                                        <p className='inline-block'>
                                                           <b>No. of Influencers</b>: {location.state.application_numbers}
                                                        </p>
                                                    </div>
                                                </small>
                                            </div>
                                            <div className='text-gray-500'>
                                                <small>
                                                    <div className='flex justify-start items-start'>
                                                        <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                        <i className="fa-solid fa-list-check"></i>
                                                        </p>
                                                        <p className='inline-block'>
                                                            <b>Promotion Requirements</b>: {location.state.promotion_req}
                                                        </p>
                                                    </div>
                                                </small>
                                            </div>
                                            <div className='text-gray-500'>
                                                <small>
                                                    <div className='flex justify-start items-center'>
                                                        <p className='flex justify-center items-center h-6 w-6 inline-block'>
                                                            <i className="fa-solid fa-location-dot"></i>
                                                        </p>
                                                        <p className='inline-block'>
                                                            <b>Location</b>: {location.state.location}
                                                        </p>
                                                    </div>
                                                </small>
                                            </div>
                                       
                                        </div>
                                        <div className='basis-1/2 flex justify-center'>
                                            <img src={imgApi + location.state.image1} alt={location.state.startup_name + ' Campaign'} className="job-img cursor-pointer" onClick={() => handleClick(location.state.image1)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='job-overview mt-10'>
                                    <p className='font-semibold mb-3'>
                                        Project Overview
                                    </p>
                                    <p className='font-regular whitespace-pre-wrap'>
                                        {location.state.business_overview}
                                    </p>
                                    <p className='font-regular whitespace-pre-wrap mt-3'>
                                        {location.state.job}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {role === 1 && <>

                        <div className="relative flex flex-col items-center justify-center">
                            <div className="w-full p-6 border rounded-lg shadow-lg">
                                <p className='text-2xl  px-4 font-semibold '>
                                    Apply to this Job
                                </p>
                                <small className="mb-2 px-4"><b>Please make sure your profile fits the promotion criteria</b></small>
                                <form className="mt-4" onSubmit={handleSubmit(handleSubmitForm)}>
                                    <div className="">
                                        <div className="">
                                            <div className="mt-3">
                                                <label htmlFor="description" className="block text-sm text-gray-800">Why are you a fit for this job?</label>
                                                <textarea cols="40" rows="5" name="job" {...register('description', { required: 'Description is required' })} className="block w-full h-full px-4 py-2 mt-2 text-black-700 border rounded-lg focus:border-black-400 focus:outline-none focus:ring-primary" />
                                                {errors.job && (
                                                    <div className="mb-3 text-normal text-red-500">
                                                        {errors.description.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <hr className="my-4" />
                                        <div className="flex flex-wrap sm:flex-nowrap w-full gap-4">
                                            <div className="sm:basis-1/2 basis-full">
                                                {/* <input type="file"
                                                    name="image"
                                                    onChange={handleFileRead}
                                                    className='mt-2'
                                                /> */}
                                                <label htmlFor="image" className="block text-sm text-gray-800">Image (optional)</label>
                                                <div className="border p-4 mt-2 rounded-lg">
                                                    <Dropzone setpp={setpp}></Dropzone>
                                                </div>
                                            </div>
                                            <div className="sm:basis-1/2 basis-full">
                                                <label htmlFor="video" className="block text-sm text-gray-800">Video Link (optional)</label>
                                                <input type="text" name="video" className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black mt-3" {...register('video')} />
                                            </div>
                                        </div>
                                        <div className="basis-full">
                                            <div className="mt-2 px-4">
                                                <div> <input type="checkbox" name="terms" className="w-4 h-4 text-primary bg-gray-100 rounded border-gray-300" required /> Agree to the terms of use and <Link to={"/"}>privacy policy</Link></div>
                                            </div>
                                            <div className="mt-6 px-4">
                                            <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black flex justify-center ">
                                                {loading == false &&
                                                    <>Submit</> 
                                                }
                                                {loading == true &&
                                                    <div role="status">
                                                        <svg aria-hidden="true" className="w-6 h-6 mr-2 text-gray-200 animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                        </svg>
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div></>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobApply;