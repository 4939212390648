import { useForm } from "react-hook-form";
import useAxios, { ApiUrl, imgApi } from '../../API/Api.config';
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Header from "../../components/DashboardHeader/DashboardHeader";
import Sidebar from '../../components/Sidebar/Sidebar';
import TokenService from "../../API/token.service";
import axios from "axios";
import chroma from "chroma-js";
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './referrals.style.scss';


const Referrals = () => {
    document.title = "Referrals | Promote"

    const token = TokenService.getLocalAccessToken()
    const role = secureLocalStorage.getItem("role");
    const user_id = secureLocalStorage.getItem('user_id')
    const [redeem, setRedeem] = useState(false)
    const [redeemCode, setRedeemCode] = useState(false)
    const [referralCode, setReferralCode] = useState("")
    const [referralError, setReferralError] = useState("")
    const [referralPackageError, setReferralPackageError] = useState("")
    const [myCode, setMyCode] = useState("")
    const [getCode, setGetCode] = useState(false)
    const [reward, setReward] = useState({})
    const [referrals, setReferrals] = useState([]);
    const [referralPackage, setReferralPackage] = useState([]);
    const profileCompleted = secureLocalStorage.getItem('profile_completed')
    const MySwal = withReactContent(Swal)
    const nav = useNavigate()

    const openGetCode = () => {
        setGetCode(true)
    }

    const openRedeemCode = () => {
        setRedeem(true)
    }

    const handleSelect = (e) => {
        const selected = e.target.value;
        setReferralPackage(selected)
    }

    const handleSubmitForm = (data) => {
        influencerRedeemCode(data)
    }

    const handleSubmitFormStartup = (data) => {
        startupRedeemCode(data)
    }

    const { register, handleSubmit, formState: { errors } } = useForm();

    async function influencerReferrals() {

        try {
            await axios.get(ApiUrl + "influencer_referrals/", { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setReferrals(response.data); })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralError("You can't use this code, please if you have any qustions send an email to us")
                    } else if (error.response.status === 401) {
                        nav('/logout')
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function influencerReward() {

        try {
            await axios.get(ApiUrl + "influencer_rewarded/", { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setReward(response.data); })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralError("You can't use this code, please if you have any qustions send an email to us")
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function influencerCode({ referrals }) {

        try {
            await axios.post(ApiUrl + "influencer_code/", { referrals }, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setReferralCode(response.data.code) })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralPackageError("You have already chosen a package")
                    }
                })
                .finally(response => {
                    getInfluencerCode()
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function getInfluencerCode() {

        try {
            await axios.get(ApiUrl + "influencer_code/", { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setMyCode(response.data.map(c => c.code)) })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralError("You can't use this code, please if you have any qustions send an email to us")
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function getStartupCode() {

        try {
            await axios.get(ApiUrl + "startup_code/", { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setMyCode(response.data.map(c => c.code)) })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralError("You can't use this code, please if you have any qustions send an email to us")
                    } else if (error.response.status === 401) {
                        nav('/logout')
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function influencerRedeemCode({ code }) {

        try {
            await axios.post(ApiUrl + "influencer_redeem_code/", { code }, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setRedeemCode(response.data.code) })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralError("You can't use this code, please if you have any qustions send an email to us")
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function startupReferrals() {

        try {
            await axios.get(ApiUrl + "startup_referrals/", { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setReferrals(response.data); })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralError("You can't use this code, please if you have any qustions send an email to us")
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function startupReward() {

        try {
            await axios.get(ApiUrl + "startup_rewarded/", { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setReward(response.data); })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralError("You can't use this code, please if you have any qustions send an email to us")
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function startupCode({ referrals }) {

        try {
            await axios.post(ApiUrl + "startup_code/", { referrals }, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setReferralCode(response.data.code) })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralError("You can't use this code, please if you have any qustions send an email to us")
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function startupRedeemCode({ code }) {

        try {
            await axios.post(ApiUrl + "startup_redeem_code/", { code }, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setRedeemCode(response.data.code) })
                .catch(error => {
                    if (error.response.status === 406) {
                        setReferralError("You can't use this code, please if you have any qustions send an email to us")
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        console.log(myCode.length)
    }, [myCode])

    useEffect(() => {
        console.log(referralCode)
    }, [referralCode]);

    useEffect(() => {
        if (role === 1) {
            influencerReferrals()
            influencerReward()
            getInfluencerCode()
        } else {
            startupReferrals()
            startupReward()
            getStartupCode()
        }

    }, [])


    useEffect(() => {
        if (!profileCompleted && user_id !== null) {
            if (role == 1) {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/influencer/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            } else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/business/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }
        }
    }, [])

    return (
        <>
            <Header />
            <Sidebar />
            <div className="sm:ml-64 ml-0 mt-20 user-upload">
                <div className="container">
                    <div className="relative flex flex-col mt-10 overflow-hidden max-h-full">
                        <h2 className="p-4 pl-0 text-2xl font-bold">Referrals</h2>
                    </div>
                    <div className="bg-white border rounded-lg p-4">
                        <div className="mt-4">
                            {role == 1 &&
                                <>
                                    <h4 className="text-lg text-black text-center font-bold">
                                        Refer your friends and earn upto a £100
                                    </h4>
                                </>
                            }
                            {role == 2 &&
                                <>
                                    <h4 className="text-lg text-black text-center font-bold ">
                                        Refer a business to Promote.io and receive your next month subscription in half price!
                                    </h4>
                                    <div className="flex justify-center items-center mt-4">
                                        <p className="text-sm w-3/4 text-center">
                                            Refer a business using the code below. If they sign up on the Starter or Pro plan receive £20 towards any influencer. If they sign up on our Enterprise plan then you will get you next month subscription half price!
                                        </p>

                                    </div>

                                </>
                            }
                            <div>
                                {myCode.length == 0 &&
                                    <>
                                        <label htmlFor="payment_type" className="block text-black font-bold">Select Referral Package</label>
                                        <select disabled={reward.code} className="block w-full px-4 py-2 mt-4 bg-white border rounded-lg focus:border-primary focus:ring-primary" id="select" onChange={handleSelect}>
                                            <option value="" selected >{reward.code ? 'You already have a code in use' : 'Not Specific'}</option>
                                            {referrals.length && referrals.map(obj => {
                                                return (
                                                    <option value={obj.id}>Gift value: £{obj.gift} - Number of minimum influencer to invite: {obj.count_to_get_gift}</option>
                                                )
                                            })
                                            }
                                        </select>
                                        {role === 1 &&
                                            <button className='bg-primary py-2 mt-3 rounded-lg w-full' onClick={() => influencerCode({ referrals: referralPackage })}>
                                                <div className="text-white w-full block text-center">
                                                    <b>Choose this Package</b>
                                                </div>
                                            </button>}
                                        {role === 2 &&
                                            <button className='bg-primary py-2 mt-3 rounded-lg w-full' onClick={() => startupCode({ referrals: referralPackage })}>
                                                <div className="text-white w-full block text-center">
                                                    <b>Choose this Package</b>
                                                </div>
                                            </button>}
                                        {referralCode.length > 0 &&
                                            <div>
                                                <div className="mt-3  font-bold text-green-700">
                                                    Your Code is: {referralCode}
                                                </div>
                                            </div>}
                                    </>
                                }

                                {myCode.length > 0 &&
                                    reward &&
                                    <div className="mt-3">
                                        {role === 1 &&
                                            <div className="border p-4 text-center">
                                                <b>Your Referral Link</b>
                                                <p>
                                                     https://promote.io/influencer/register?ref={myCode ? myCode : 'Not Set'}
                                                </p>
                                                <small>Use this link to refer your friends</small>
                                            </div>
                                        }
                                        {role === 2 &&
                                            <div className="border p-4 text-center">
                                                <b>Your Referral Link</b>
                                                <p>
                                                    https://promote.io/business/register?ref={myCode ? myCode : 'Not Set'}
                                                </p>
                                                <small>Use this link to refer your friends</small>
                                            </div>

                                        }
                                        <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 mt-3 text-sm text-center">

                                            <div className="border p-4">
                                                <b>Total Gift:</b> £{reward.total_gift_value ? reward.total_gift_value : 0}
                                            </div>
                                            <div className="border p-4">
                                                <b>Remaining Users:</b> {reward.totel_user_redeem_count ? reward.totel_user_redeem_count : 'Wait for someone to redeem'}
                                            </div>
                                            <div className="border p-4">
                                                <b>Code Used By:</b> {reward.redeem_count ? reward.redeem_count : 0} user
                                            </div>
                                            {/* <div className="border p-4">
                                                <b>Your Gift:</b> £{reward.total_gift ? reward.total_gift : 0}
                                            </div> */}
                                        </div>
                                        <button
                                            className="w-full py-2 mt-3 text-white transition-colors duration-200 transform bg-black cursor-not-allowed rounded-lg hover:bg-black focus:outline-none focus:bg-black">
                                            <b>Redeem Gift</b>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="bg-white border rounded-lg p-4 mt-4">
                        {role === 1 &&
                            <>
                                <h4 className="text-lg text-black text-center font-bold">
                                    Enter your friend’s referral code and so they can redeem their rewards
                                </h4>
                                <form onSubmit={handleSubmit(handleSubmitForm)} className="mt-5">
                                    <label htmlFor="code" className="block text-black font-bold">Enter Your Code</label>
                                    <div className="mt-4">
                                        <input type="text" {...register('code')} id="code" name="code" className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" />
                                    </div>
                                    <button
                                        className="w-full py-2 mt-3 text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black" id="submitBtn">
                                        <b>Submit</b>
                                    </button>
                                </form>
                            </>
                        }
                        {role === 2 &&
                            <>
                                <h4 className="text-lg text-black text-center font-bold">
                                    Enter a business’s referral code so they can redeem their rewards
                                </h4>
                                <form onSubmit={handleSubmit(handleSubmitFormStartup)} className="mt-5">
                                    <label htmlFor="code" className="block text-black font-bold">Enter Your Code</label>
                                    <div className="mt-4">
                                        <input type="text" {...register('code')} id="code" name="code" className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" />
                                    </div>
                                    <button
                                        className="w-full py-2 mt-3 text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black" id="submitBtn">
                                        <b>Submit</b>
                                    </button>
                                </form>
                            </>
                        }
                        {redeemCode &&
                            <div>
                                <div className="mt-3 font-bold text-red-700">
                                    Your code successfully submitted
                                </div>
                            </div>}
                        {referralError &&
                            <div>
                                <div className="mt-3 font-bold text-red-700">
                                    {referralError}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    )

}

export default Referrals;