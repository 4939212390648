import { useState, useEffect, useCallback } from "react";
import Stepper from "../../../components/Onboarding/Stepper";
import StepperControl from "../../../components/Onboarding/StepperControl";
import { UseContextProvider, useStepperContext } from "../../../contexts/StepperContext";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import SocialMedia from "../../../components/Onboarding/steps/SocialMedia";
import SocialMediaConnect from "../../../components/Onboarding/steps/SocialMediaConnect";
import UploadProfilePicture from "../../../components/Onboarding/steps/UploadProfilePicture";
import Yourself from "../../../components/Onboarding/steps/Yourself";
import AgeGender from "../../../components/Onboarding/steps/AgeGender";
import Interests from "../../../components/Onboarding/steps/Interests";
import DemoG from "../../../components/Onboarding/steps/DemoG";
import DemoG2 from "../../../components/Onboarding/steps/DemoG2";
import Content from "../../../components/Onboarding/steps/Content";
import Reward from "../../../components/Onboarding/steps/Reward";
import Expectation from "../../../components/Onboarding/steps/Expectation";
import Career from "../../../components/Onboarding/steps/Career";
import Complete from "../../../components/Onboarding/steps/Complete";

import hero from '../../../assets/images/onboarding/large.jpeg';
import './../onboarding.style.scss';

const InfluencerOnboarding = () => {

    document.title = "Influencer Onboarding | PROMOTE"

    const [currentStep, setCurrentStep] = useState(1);
    const [dataEmpty, setDataEmpty] = useState(true);
    const [showError, setShowError] = useState(false);
    const nav = useNavigate();
    const user_id = secureLocalStorage.getItem('user_id')

    const steps = [
        "Social Media",
        "Connect",
        "Picture",
        "About Yourself",
        "Age & Gender",
        "Interests",
        "Expectation",
        "Demographics \n (Optional)",
        "Demographics 2 \n (Optional)",
        "Content \n(Optional)",
        "Reward \n (Optional)",
        "Career \n (Optional)",
        "Complete",
    ];

    useEffect(() => {
        if (user_id == null) {
            nav('/logout')
        }
    }, [user_id]);

    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <SocialMedia setDataEmpty={setDataEmpty} apiName={'social_network'} heading={'Choose your social media platforms'} subHeading={'Please choose the social media platforms you are an active creator on (Select Multiple)'} />;
            case 2:
                return <SocialMediaConnect setDataEmpty={setDataEmpty} />;
            case 3:
                return <UploadProfilePicture setDataEmpty={setDataEmpty} apiName={'profile_image'} heading={'Upload Your Profile Picture'} />;
            case 4:
                return <Yourself setDataEmpty={setDataEmpty} apiName={'bio'} heading={'Tell us a bit about yourself '} influencer={1} />;
            case 5:
                return <AgeGender setDataEmpty={setDataEmpty} />;
            case 6:
                return <Interests setDataEmpty={setDataEmpty} apiName={'interest'} heading={'Personal Interests'} subHeading={'What type of content do you mainly focus on? Choose up to 3 options'} />;
            case 7:
                return <Expectation setDataEmpty={setDataEmpty} apiName={'price_per_post'} influencer={1} heading={'Price expectation'} subHeading={'Please select how much you would like to charge for each type of collaboration'} />;
            case 8:
                return <DemoG apiName={'social_media_audience'} heading={'Social media audience demographics'} subHeading={'Who makes up the majority of your audience? (multi select)'} />;
            case 9:
                return <DemoG2 apiName={'audience_location'} heading={'Social media audience demographics'} subHeading={'Where is your audience located?'} />;
            case 10:
                return <Content apiName={'type_of_collabration'} heading={'Type of content'} subHeading={'How do you usually produce content? (multi select)'} />;
            case 11:
                return <Reward apiName={'callaboration_options'} heading={'Collaboration reward'} subHeading={'How would you like to be paid? (multi select)'} />;
            case 12:
                return <Career heading={'Career and Education'} subHeading={'Please provide more details about your career and education'} />;
            case 13:
                return <Complete apiName={'user_info/'} redirect={"/logout"} closeBtn={'Logout'} heading={'You have successfully completed the sign-up phase. Our dedicated team is currently verifying your application, and we will promptly send you an email with the result.'} influencer={1} />;
            default:
        }
    };

    const handleClick = (direction, allowNext) => {
        let newStep = currentStep;

        if (!allowNext) {
            direction === "next" ? newStep++ : newStep--;
            // check if steps are within bounds
            newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
            setShowError(false)
        } else {
            setShowError(true)
        }


        // direction === "next" ? newStep++ : newStep--;
        // // check if steps are within bounds
        // newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);

    };

    return (
        <section className="section" id="onboarding" style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + hero + ")" }}>
            <div className="container ">
                <div className=" ">
                    <div className="mx-auto flex flex-col rounded-2xl bg-white pb-2 w-full shadow onboard-card">
                        {/* Stepper */}
                        <div className="pt-10 hidden sm:block">
                            <Stepper steps={steps} currentStep={currentStep} />
                        </div>
                        <div className="pt-10 sm:hidden block">
                            <p className="text-center">
                                Approx. 10 mins to complete
                            </p>
                        </div>
                        <div className="onboarding-steps">
                            <div className="my-5">
                                <div className="sm:py-10 py-5">
                                    <div className="flex justify-center items-center each-step">
                                        <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
                                    </div>
                                </div>
                                {/* navigation button */}
                                {currentStep !== steps.length && (
                                    <StepperControl
                                        dataEmpty={dataEmpty}
                                        handleClick={handleClick}
                                        currentStep={currentStep}
                                        allowNext={showError}
                                        steps={steps}
                                    />
                                )}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InfluencerOnboarding;