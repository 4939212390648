import { useState, useEffect, useContext } from "react";
import chroma from "chroma-js";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useStepperContext } from "../../../contexts/StepperContext";
import InputField from "../../../components/InputField/InputField";
import '../onboarding.style.scss';



function Career({heading, subHeading}){
    const { userData, setUserData } = useStepperContext();
    const [location, setLocation] = useState("");
    const [career, setCareer] = useState("");


    const handleChange = (e) => {
        const { value } = e.target;
        setCareer(value);
    };

    useEffect(() => {
        setUserData({ ...userData, ['education_location']:location, ['career']:career  });
    }, [career, location])

    useEffect(() => {
        console.log(userData)
    }, [userData])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        {subHeading}
                    </small>
                </p>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card">
                        <div className="grid sm:grid-cols-2 grid-cols-1 gap-6 w-full">
                            <div className="bg-white p-6 google-places rounded-lg">
                                <p className="text-center mb-2">
                                    <b>
                                        Select your Education Location
                                    </b>
                                </p>
                                <GooglePlacesAutocomplete
                                    apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: ['GB'],
                                        }
                                    }}
                                    selectProps={{
                                        onChange: (value) => { setLocation(value.value.description) },
                                        styles: {
                                            input: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                                boxShadow: "none",
                                                outline: "none",
                                                ':hover': {
                                                    '--tw-ring-color': 'black !important'
                                                },
                                                ':focus': {
                                                    '--tw-ring-color': 'black !important'
                                                },
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                border: '1px solid black',
                                                ':hover': {
                                                    border: '1px solid #008e95 !important'
                                                },
                                                boxShadow: "none",
                                                outline: "none",
                                                height: "48px"
                                            }),
                                            
                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                const color = chroma('#008e95');
                                                return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? undefined
                                                    : isSelected
                                                    ? data.color
                                                    : isFocused
                                                    ? color.alpha(0.1).css()
                                                    : undefined,
                                                    ':active': {
                                                        ...styles[':active'],
                                                        backgroundColor: !isDisabled
                                                        ? isSelected
                                                        ? color
                                                        : color.alpha(0.3).css()
                                                        : undefined,
                                                    },
                                                };
                                            },
                                        }
                                    }}                              
                                />

                            </div>
                            <div className="bg-white p-6 rounded-lg">
                                <p className="text-center mb-2">
                                    <b>
                                        Mention your Occupation
                                    </b>
                                </p>
                                <InputField type={"text"} inputName={"career"} labelName="Your Career" value={career} onChange={handleChange} ></InputField>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Career;