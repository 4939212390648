import React ,{ useState, useEffect } from "react";

import useAxios, { imgApi, ApiUrl } from "../../API/Api.config";
import axios from 'axios';
import TokenService from '../../API/token.service';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Modal, Button } from "flowbite-react";

const JobApplications = () => {

    document.title = "Campaign Applications | PROMOTE"

    const token = TokenService.getLocalAccessToken();
    const [data, setData] = useState([]);
    const nav = useNavigate()
    const [hideBool, setHideBool] = useState(0)
    const [recevier, setRecevier] = useState(0);
    const [loadingHide, setLoadingHide] = useState(false)
    const [loadingReject, setLoadingReject] = useState(false)
    const MySwal = withReactContent(Swal)
    const userid = secureLocalStorage.getItem("user_id")
    const profileCompleted = secureLocalStorage.getItem('profile_completed');
    const role = secureLocalStorage.getItem("role");

    const { response, isLoading, status, reload } = useAxios({
        method: "get",
        url: 'job_applications/',
        headers: {
            "Authorization": `Token ${token}`
        }
    });

    useEffect(() => {
        if (response !== null) {
            console.log(response)
            setData(response)
        } else if (status === 401) {
            nav('/login')
        }
    }, [response]);

    const handleUserDetails = (id) => {
        secureLocalStorage.setItem('userid', id);
        window.open('/show-user/');
    }

    async function handleHideClick(id, status ) {
        setLoadingHide(true)
        try {
            await axios.post(ApiUrl + `job_hiding_applications/?id=${id}&status=${status}`, {},
                {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response1) {
                    console.log(response)
                    reload()
                })
                .catch(function (error) {
                    if (error.request.status === 400) {}
                }).finally(() => (
                    setLoadingHide(false)
                ));
        } catch (error) {
            console.log(error)
        }
    }

    async function handleRejectClick(id) {
        setLoadingReject(true)
        try {
            await axios.post(ApiUrl + `job_rejected_applications/?id=${id}&status=1`, {},
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": 'Token ' + token
                    }
                })
                .then(function (response) {
                    reload()
                })
                .catch(function (error) {
                    if (error.request.status === 400) {}
                }).finally(() => (
                    setLoadingReject(false)
                ));
        } catch (error) {
            console.log(error)
        }
    }

    async function sendMessage(data, name) {
        // const token = TokenService.getLocalAccessToken();
    
        try {
            await axios.post(ApiUrl + `send_messages/`, data,
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your message has been sent and you will be redirected to messages page shortly',
                        showConfirmButton: false,
                        width: 600,
                        timer: 3000,
                    })
                    setTimeout(function(){
                        nav('/messages?name='+ name.replace(' ', '_'));
                    }, 3000);
                })
                .catch(function (error) {
                    if(error.request.status === 400){
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was an error sending the messages',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    };
    async function handleFavoritConnection(data) {
        try {
            await axios.post(ApiUrl + `favorite_content/${data.friend}/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + token
                }
            })
                .then(response => console.log(response))
                .catch(error => console.log(error))
        } catch (e) {
            console.log(e)
        }

    }

    const openModal = (modalId) => {
        console.log(modalId)
        document.getElementById(modalId).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80'
    }

    const closeModal = (modalId) => {
        console.log(modalId)
        document.getElementById(modalId).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center hidden'
    }

    const handleClickSendMessage = (id, message, name) => {
        sendMessage({"sender": userid, "message": message, 'receiver': id}, name)
        handleFavoritConnection({ 'friend': id });
    };

    const handleConnectButton = async (id, name, campaign_name) => {
     
        const connectTextDefault = `Hi ${name}, it would be great to collaborate with you for the campaign ${campaign_name}`

        handleClickSendMessage(id, connectTextDefault, name)
    }

    const handleStatus = (id, status) => {
        setHideBool(status)
        handleHideClick(id, status)
    }
    
    useEffect(()=>{},[hideBool])

    useEffect(()=>{
        if(!profileCompleted){
            if(role == 1) {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/influencer/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }  else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/business/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }     
        }
    }, [])

    return (
        <>
            <Header />
            <Sidebar />
            <div className="sm:ml-64 mt-20 mb-10">
                <div className="container mx-auto ">
                    <h2 className="p-4 text-2xl font-bold mb-5">Job Applications</h2>
                    {Object.entries(data).map(([key, value]) => {
                            return (
                                <>
                                    <h3 className="text-xl font-semibold mb-2 px-4">
                                        {key}
                                    </h3>
                                    <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                    {data[key].length > 0 ? data[key].map(data => {
                                            return (
                                                <>
                                              
                                                    <div className="w-full" key={data.id}>
                                                        <div className="bg-white p-6 mx-2 rounded-lg shadow-lg border">
                                                            <div className="job-apply-img flex justify-center" >
                                                                <img src={data.influencer_image? imgApi + data.influencer_image : 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png'} alt={data.username}  className="h-full"/>
                                                            </div>
                                                            <div className="mt-3">{data.username !== '' ? data.username : data.first_name }</div>
                                                            <p className="mb-2"><small><i className="fa-solid fa-calendar-days"></i> {new Date(data.apply_date).toDateString()}</small></p>
                                                            <hr className="my-4" />
                                                            <div className="grid sm:grid-cols-2 gap-2">
                                                                <button
                                                                    className="w-full p-2 tracking-wide text-black text-sm rounded-lg bg-gray-200 flex justify-center items-center" onClick={() => {handleStatus(data.id, 1);}}>
                                                                    {loadingHide == false &&
                                                                        <>Hide</> 
                                                                    }
                                                                    {loadingHide == true &&
                                                                        <div role="status">
                                                                            <svg aria-hidden="true" className="w-6 h-6 mr-2 text-gray-200 animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                                            </svg>
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                {/* <button
                                                                    className="w-full p-2 tracking-wide text-white text-sm rounded-lg bg-secondary" data-modal-target={"influencerModal"+ data.id} data-modal-toggle={"influencerModal"+ data.id}>
                                                                    View Application
                                                                </button> */}
                                                                <React.Fragment>
                                                                    <Button 
                                                                        onClick={()=> {openModal("ToggleModal"+ data.id)}}
                                                                        className="bg-primary-btn"
                                                                    >
                                                                            View Application
                                                                    </Button>
                                                                    <Modal
                                                                        show={false}
                                                                        id={"ToggleModal"+ data.id}
                                                                        onClose={()=> {closeModal("ToggleModal"+ data.id)}}
                                                                    >
                                                                        <Modal.Header>
                                                                            {data.username !== '' ? data.username : data.first_name } Influencer Application
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <div className="p-6 space-y-6">
                                                                                <p className="mb-2 font-semibold text-center">Reason why they are fit for this job?</p>
                                                                                <p className="text-center">{data.description}</p>
                                                                            </div>
                                                                            <hr className="h-px my-8 bg-gray-200 border-0"/>
                                                                            <div className="grid sm:grid-cols-2 gap-6 mb-5">
                                                                                <div className="text-center">
                                                                                    <p className="mb-2 font-semibold">Image</p>
                                                                                    <p className="mb-2">{data.image ? <a href={imgApi + data.image} target="_blank" className="text-primary">Image Link</a> : 'NA'}</p>
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    <p className="mb-2 font-semibold">Video</p>
                                                                                    <p className="mb-2">{data.video ? <a href={data.video} target="_blank" className="text-primary">Video Link</a> : 'NA'}</p>
                                                                                </div>
                                                                            </div>
                                                                        </Modal.Body>
                                                                        <Modal.Footer
                                                                            className="w-full justify-center"
                                                                        >
                                                                            <Button
                                                                                className="bg-primary-btn w-full"
                                                                                onClick={() => {setRecevier(data.user); handleConnectButton(data.user, (data.first_name !== '' ? data.first_name : data.username ), data.job_title); closeModal("ToggleModal"+ data.id)}}
                                                                            >
                                                                                Message
                                                                            </Button>
                                                                            <Button
                                                                                className="w-full"
                                                                                color="dark"
                                                                                onClick={() => { handleUserDetails(data.user); secureLocalStorage.setItem("profile_view", 1); closeModal("ToggleModal"+ data.id)}}
                                                                            >
                                                                                View Profile
                                                                            </Button>
                                                                            <Button
                                                                                className="w-full"
                                                                                color="failure"
                                                                                onClick={() => { handleRejectClick(data.id); closeModal("ToggleModal"+ data.id)}}
                                                                            >
                                                                                Reject
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                </React.Fragment>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id={"influencerModal"+ data.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                        <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                            <div className="relative bg-white rounded-lg shadow">
                                                                <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                    <h3 className="text-xl font-semibold text-black text-center">
                                                                        {data.username !== '' ? data.username : data.first_name } Influencer Application
                                                                    </h3>
                                                                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-hide={"influencerModal"+ data.id}>
                                                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                                        <span className="sr-only">Close modal</span>
                                                                    </button>
                                                                </div>
                                                                <div className="p-6 space-y-6">
                                                                    <p className="mb-2 font-semibold text-center">Reason why they are fit for this job?</p>
                                                                    <p className="text-center">{data.description}</p>
                                                                </div>
                                                                <hr className="h-px my-8 bg-gray-200 border-0"/>
                                                                <div className="grid sm:grid-cols-2 gap-6 mb-5">
                                                                    <div className="text-center">
                                                                        <p className="mb-2 font-semibold">Image</p>
                                                                        <p className="mb-2">{data.image ? <a href={imgApi + data.image} target="_blank" className="text-primary">Image Link</a> : 'NA'}</p>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <p className="mb-2 font-semibold">Video</p>
                                                                        <p className="mb-2">{data.video ? <a href={data.video} target="_blank" className="text-primary">Video Link</a> : 'NA'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className=" border-t border-gray-200 rounded-b-lg">
                                                                    
                                                                    <div className="grid sm:grid-cols-3 grid-cols-1 p-4 gap-4">
                                                                        <button
                                                                            className="w-full p-2 text-white rounded-lg bg-primary" data-modal-hide={"influencerModal"+ data.id} >
                                                                            Message
                                                                        </button> 
                                                                        <button
                                                                            className="w-full p-2 tracking-wide text-white text-sm bg-secondary rounded-lg" onClick={() => { handleUserDetails(data.user); secureLocalStorage.setItem("profile_view", 1) }}>
                                                                            View Profile
                                                                        </button>
                                                                     
                                                                        <button
                                                                            className="w-full p-2 tracking-wide text-white text-sm rounded-lg bg-red-700 flex justify-center" onClick={() => { handleRejectClick(data.id);}} data-modal-hide={"influencerModal"+ data.id}>
                                                                            {loadingReject == false &&
                                                                                <>Reject</> 
                                                                            }
                                                                            {loadingReject == true &&
                                                                                <div role="status">
                                                                                    <svg aria-hidden="true" className="w-6 h-6 mr-2 text-gray-200 animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                                                    </svg>
                                                                                    <span className="sr-only">Loading...</span>
                                                                                </div>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }) :
                                            <div className="basis-full">
                                                No applications have been submitted yet
                                            </div>
                                        } 
                                    </div>
                                    <hr className="h-px my-8 bg-gray-200 border-0"/>
                                </>
                            )
                        })}


                </div>
            </div>
        </>
    );
}

export default JobApplications;