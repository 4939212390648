import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
// import { GoogleLogin } from 'react-google-login';
import TokenService from "../../API/token.service";
import { ApiUrl } from "../../API/Api.config";
import InputField from "../../components/InputField/InputField";
import Checkbox from "../../components/Checkbox/Checkbox";
import Select from "../../components/Select/Select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import secureLocalStorage from "react-secure-storage";
import image1 from '../../assets/images/login/login.jpg';
import logo from '../../assets/images/logo1.png';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Tooltip, Button } from "flowbite-react";
import './register.style.scss'


const StartupRegister = () => {

    document.title = "Register | PROMOTE"

    const [user, setUser] = useState({});
    const nav = useNavigate()
    const [loading, setLoading] = useState(false);
    const [soical, setSoical] = useState(false);
    const { setError, register, handleSubmit, formState: { errors } } = useForm();
    const MySwal = withReactContent(Swal)


    const onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setUser(values => ({ ...values, [name]: value }))
    };
    //test

    const findUsOptions = [
        {
            'label': 'How did you find us?',
            'value': '',
            'disabled' : false
        },
        // {
        //     'label': 'Social Media',
        //     'value': 'social_media',
        //     'disabled' : false
        // },
        // {
        //     'label': 'Friends and Family',
        //     'value': 'friends_and_family',
        //     'disabled' : false
        // },
        {
            'label': 'Instagram / Facebook Ad',
            'value': 'instagram_facebook_ad',
            'disabled' : false
        },
        {
            'label': 'Tiktok Ad',
            'value': 'tiktok_ad',
            'disabled' : false
        },
        {
            'label': 'Friend\'s Referral',
            'value': 'friends_referral',
            'disabled' : false
        },
        {
            'label': 'Instagram',
            'value': 'instagram',
            'disabled' : false
        },
        {
            'label': 'Tiktok',
            'value': 'tiktok',
            'disabled' : false
        },
        {
            'label': 'Facebook',
            'value': 'facebook',
            'disabled' : false
        },
        {
            'label': 'LinkedIn',
            'value': 'linkedin',
            'disabled' : false
        },
    ]

    async function RegisterAPI(email, password1, password2, first_name, last_name, recive_email, how_you_find_us ) {
        setLoading(true)
        try {
            await axios.post(ApiUrl + 'auth/registration/', {
                email: email,
                password1: password1,
                password2: password2,
                first_name: first_name,
                last_name: last_name,
                recive_email: recive_email !== '' ? 1 : 0,
                how_you_find_us: how_you_find_us,
                role: 2
            },
                {
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    }
                })
                .then(function (response) {
                    secureLocalStorage.setItem("profile_completed", false)
                    secureLocalStorage.setItem("user_id", response.data.id)    
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Registration Successful <br/> Verification Email Sent',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                    setTimeout(function(){
                        nav('/login');
                    }, 3000);
                })
                .catch(function (error) {
                    const errors = error.response.data;
                    if (errors.email) {
                        setError('email', {
                            type: "server",
                            message: 'Email already exists',
                        });
                    }
                    if (errors.password1) {
                        setError('password1', {
                            type: "server",
                            message: 'Wrong password',
                        });
                    }
                    if (errors.password2) {
                        setError('password2', {
                            type: "server",
                            message: 'Wrong password',
                        });
                    }
                    if (errors.non_field_errors) {
                        setError('non_field_errors', {
                            type: "server",
                            message: errors.non_field_errors[0],
                        });
                    }
                    
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'error',
                        title: 'Registration Unsuccessful <br/> ',
                        showConfirmButton: false,
                        timer: 3000,
                    })

                })
                .finally(() => (
                    setLoading(false)
                ));
        } catch (error) {
            console.log(error)
        }
    };

    const handleSubmitForm = (event) => {
        RegisterAPI(user.email, user.password1, user.password2, user.first_name, user.first_name, user.recive_email, user.how_you_find_us)
    }

    const handleErrorSubmitForm = (event) => {
        RegisterAPI(user.email, user.password1, user.password2, user.first_name, user.first_name, user.recive_email, user.how_you_find_us)
    } 

    // useEffect(() => { }, [user])

    // useEffect(() => {
    //     if (loading) {
    //         window.location.href='/user-info'
    //     } else if (soical){
    //         window.location.href='/news_page'
    //     }

    // }, [loading, soical])

    return (
        <>
            <div id="register">
                <section className="bg-fourth">
                    <div className="container mx-auto h-full">
                        <div className="flex flex-col justify-center items-center h-full"> 
                            <div className="bg-white rounded-lg w-full sm:max-w-[75rem] full-card">
                                <div className="flex flex-wrap h-full">
                                    <div className="sm:basis-6/12 basis-full section-1-cover" style={{backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.0)), url("+ image1 +")"}}>
                                      
                                    </div>
                                    <div className="sm:basis-6/12 basis-full p-10">
                                        <p className="text-center">
                                            Welcome to
                                        </p>
                                        <div className="flex flex-row justify-center">
                                            <img className="max-w-full border-none h-14 mb-4" src={logo} />
                                        </div>

                                        <h3 className="text-xl text-center mt-2"><b>Sign up as Business</b></h3>
                                        <form className="mt-4" onSubmit={handleSubmit(handleSubmitForm, handleErrorSubmitForm)}>
                                            <div className="mt-2">
                                                <div className="flex space-x-4">
                                                    <div className="basis-full">
                                                        <InputField type={'text'} inputName={'first_name'} id={"first_name"} inputValue={user.first_name} onChange={onChange} labelName={"Business Name"}/>
                                                    </div>
                                                </div>
                                                <div className="text-normal text-red-500">{errors.first_name && errors.first_name.message}{errors.last_name && errors.last_name.message}</div>                                                 
                                            </div>
                                            <div className="mt-2">
                                                <InputField type={'email'} inputName={'email'} id={"email"} inputValue={user.email} onChange={onChange} labelName={"Email Address"}/>
                                                <div className="text-normal text-red-500">{errors.email && errors.email.message}</div>
                                            </div>
                                            <div className="mt-2 flex justify-between">
                                                <div className="w-full">
                                                    <InputField type={'password'} inputName={'password1'} id={"password1"} inputValue={user.password1} onChange={onChange} labelName={"Password"} className={"rounded-r-none rounded-l-lg"}/>
                                                    {errors.password1 && (
                                                        <div className="text-normal text-red-500">
                                                            {errors.password1.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="h-full">
                                                    <Tooltip className="whitespace-pre sm:w-auto w-full" arrow={false} content={"Secure password, no personal info.\r\nMust contain at least 8 characters,\r\nCan’t be a commonly used password,\r\nCan’t be entirely numeric"}>
                                                        <Button className="bg-primary-tooltip">
                                                            <i className="fa-solid fa-circle-info"></i>
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <InputField type={'password'} inputName={'password2'} id={"password2"} inputValue={user.password2} onChange={onChange} labelName={"Confirm Password"}/>
                                                {errors.password2 && (
                                                    <div className="text-normal text-red-500">
                                                        {errors.password2.message}
                                                    </div>
                                                )}
                                                {errors.non_field_errors && (
                                                    <div className="text-normal text-red-500">
                                                        {errors.non_field_errors.message}
                                                    </div>
                                                )}
                                            </div> 
                                            <div className="mt-2">
                                                <Select inputName={'how_you_find_us'} inputValue={user.how_you_find_us} labelName={"How did you find us?"} id={"find-us"} options={findUsOptions} onChange={onChange}></Select>
                                            </div>
                                            
                                            {/* <p className="mt-2 text-xs text-gray-600 pwd-text">* Your password can’t be too similar to your other personal information, must contain at least 8 characters, can’t be a commonly used password, can’t be entirely numeric.</p> */}
                                            <div className="mt-6">
                                                <div> 
                                                    <Checkbox inputName={'recive_email'} id={"recive_email"} inputValue={user.recive_email} onChange={onChange} labelName={"Receive Newsletters"}/>
                                                </div>
                                                <div> 
                                                    <Checkbox inputName={'terms'} id={"terms"} inputValue={"terms"} required={true} labelName={"I agree to terms & conditions and privacy policy"}/>
                                                </div>
                                            </div>
                                            <div className="mt-6">
                                                <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black flex justify-center ">
                                                {loading == false &&
                                                    <><b>Register</b></> 
                                                }
                                                {loading == true &&
                                                    <div role="status">
                                                        <svg aria-hidden="true" className="w-6 h-6 mr-2 text-gray-200 animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                        </svg>
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                                </button>
                                            </div>
                                        </form>
                                        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                                        <div className="">
                                            <p className="text-center text-sm mb-2">
                                            </p>
                                            <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-secondary rounded-lg hover:bg-third focus:outline-none focus:bg-black">
                                                <Link to="/login/" className="text-white">
                                                    <p>
                                                        Already have an account? Login here
                                                    </p>
                                                </Link>
                                            </button>
                                        </div>
                                        <div className="mt-4">
                                            <button className="bg-primary text-white px-4 py-2 w-full rounded-lg">
                                                <Link to="/influencer/register">
                                                    <div className="text-md">
                                                        <i className="fa-solid fa-users"></i> &nbsp;
                                                        Sign up as a Influencer
                                                    </div>
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    );
}

export default StartupRegister;