import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';
import chroma from "chroma-js";
import { ApiUrl } from '../../API/Api.config';
import TokenService from "../../API/token.service";
import { Link } from 'react-router-dom';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import Range from '../../components/Range/Range';
import Dropzone from '../../components/Dropzone/Dropzone';
import Checkbox from '../../components/Checkbox/Checkbox';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from "react-router-dom";
import './Job.style.scss'


const JobPost = () => {

    const [minFollowers, setMinFollowers] = useState(0);
    const token = TokenService.getLocalAccessToken();
    const [pp, setpp] = useState("");
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal)
    const [paymentStatus, setPaymentStatus] = useState([])
    const nav = useNavigate();
    const profileCompleted = secureLocalStorage.getItem('profile_completed');
    const role = secureLocalStorage.getItem("role");



    const { register, setValue, handleSubmit, formState: { errors } } = useForm();

    async function JobApi(data) {
        setLoading(true)
        const token = TokenService.getLocalAccessToken();

        try {
            await axios.post(ApiUrl + 'job_page/', data,
                {
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your Campaign has been created successfully',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                    setTimeout(function () {
                        nav('/my_campaigns');
                    }, 3000);
                })
                .catch(function (error) {
                    if (error.request.status === 400) {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was an error in creating your campaign',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                    console.log(error)
                }).finally(() => (
                    setLoading(false)
                ));
        } catch (error) {
            console.log(error)
        }
    };

    async function getPaymentStatus() {
        try {
            await axios.get(ApiUrl + 'payment_register/', {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Token ' + token,
                }
            }).then(response => {
                if (response.data[0]?.connection_number) {
                    setPaymentStatus(response.data[0]?.connection_number)
                    console.log(response.data[0]?.connection_number)
                } else {
                }
            }).catch(error => console.log(error));
        } catch (e) { console.log(e) }
    }

    useEffect(()=>{
        if (token === null) {
            nav('/login')
        }
        getPaymentStatus()
    }, [])

    useEffect(() => {
        setValue('image1', pp)
    }, [pp])

    useEffect(() => {
        setValue('min_influencer_numbers', minFollowers)
    }, [minFollowers])

    const handleSubmitForm = (data) => {
        console.log(data);
        JobApi(data)
    }


    return (
        <>
            <Header />
            <Sidebar />
            <div className="sm:ml-64 mt-20 mb-10" id="job-post">
                <div className="container mx-auto ">
                    <h2 className="p-6 pl-0 pb-0 text-2xl font-bold">Add a new promotional opportunity</h2>
                    {role === 2 &&
                        <div className='grid sm:grid-cols-3 grid-cols-1  gap-4 mt-5'>
                            {paymentStatus > 0 ?
                                <>
                                    <button className='w-full bg-primary rounded-lg flex justify-center text-center hover:bg-third'>
                                        <a href='/campaigns' className='text-white flex items-center p-3 '>
                                            <i className="fa-solid fa-suitcase"></i>&nbsp;
                                            View Other Campaigns
                                        </a>
                                    </button>
                                    <button className='w-full bg-secondary rounded-lg text-center flex justify-center hover:bg-third'>
                                        <a href='/campaign-applications' className='text-white p-3 flex items-center h-full'>
                                            <i className="fa-solid fa-file"></i>&nbsp;
                                            My Campaign Applications
                                        </a>
                                    </button>
                                    <button className='w-full bg-secondary rounded-lg text-center flex justify-center hover:bg-third'>
                                        <a href='/my-campaigns' className='text-white flex items-center p-3 '>
                                            <i className="fa-solid fa-suitcase"></i>&nbsp;
                                            Manage My Campaigns
                                        </a>
                                    </button>
                                </>
                                :
                                <button className='w-full bg-primary rounded-lg flex flex-col justify-center items-center text-center hover:bg-third p-3'>
                                    <a href='/dashboard/pricing-plan' className='text-white flex items-center'>
                                        <i className="fa-solid fa-plus"></i>&nbsp;
                                        Subscribe to a plan
                                    </a>
                                    <small className='text-red-600 text-xs'>Please choose a plan to create a campaign.</small>
                                </button>
                            }
                            {/* {paymentStatus <=0 && } */}
                        </div>}
                    <div className="relative flex flex-col items-center justify-center">
                        <div className="w-full p-6 bg-white mt-5 rounded-lg shadow-lg border">
                            <form className="" onSubmit={handleSubmit(handleSubmitForm)}>
                                <Dropzone setpp={setpp}></Dropzone>
                                <hr className="h-px my-8 bg-gray-200 border-0" />
                                <div className="grid sm:grid-cols-2 gap-6">
                                    <div className="">
                                        <label htmlFor="title" className="block text-sm text-gray-800">Title</label>
                                        <input type="text" name="title" {...register('title', { required: 'Title is required' })} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg" />
                                        {errors.title && (
                                            <div className="mb-3 text-normal text-red-500">
                                                {errors.title.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="">
                                        <label htmlFor="short" className="block text-sm text-gray-800">Short Description (180 max char)</label>
                                        <input type="text" name="short" {...register('short', { required: 'Short Desc is required' })} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg" />
                                        {errors.title && (
                                            <div className="mb-3 text-normal text-red-500">
                                                {errors.title.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="">
                                        <label htmlFor="description" className="block text-sm text-gray-800">Business Overview</label>
                                        <textarea rows={5} name="business_overview" {...register('business_overview', { required: 'Business Overview is required' })} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg" />
                                        {errors.job && (
                                            <div className="mb-3 text-normal text-red-500">
                                                {errors.business_overview.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="">
                                        <label htmlFor="job" className="block text-sm text-gray-800">Influencer criteria</label>
                                        <textarea rows={5} name="job" {...register('job', { required: 'Influencer criteria is required' })} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg" />
                                        {errors.job && (
                                            <div className="mb-3 text-normal text-red-500">
                                                {errors.job.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="">
                                        <label htmlFor="promotion_req" className="block text-sm text-gray-800">Promotional requirements</label>
                                        <input type="text" name="promotion_req" {...register('promotion_req', { required: 'Promotional requirements is required' })} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg" />
                                        {errors.promotion_req && (
                                            <div className="mb-3 text-normal text-red-500">
                                                {errors.promotion_req.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="">
                                        <label htmlFor="working_with" className="block text-sm text-gray-800">Who the influencer will be working with?</label>
                                        <input type="text" name="working_with" {...register('working_with')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg" />
                                        {errors.working_with && (
                                            <div className="mb-3 text-normal text-red-500">
                                                {errors.working_with.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="">
                                        <label htmlFor="min_influencer_numbers" className="block text-sm text-gray-800">Follower numbers</label>
                                        <Range
                                            min={0}
                                            max={10000}
                                            onChange={({ min, max }) => setMinFollowers(max)}
                                        />
                                        {/* <input type="number" name="min_influencer_numbers" {...register('min_influencer_numbers', { required: 'Min Influencer Numbers is required' })} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg" />
                                        {errors.min_influencer_numbers && (
                                            <div className="mb-3 text-normal text-red-500">
                                                {errors.min_influencer_numbers.message}
                                            </div>
                                        )} */}
                                    </div>

                                    <div className="">
                                        <label htmlFor="application_numbers" className="block text-sm text-gray-800">No of Influencers needed for this job</label>
                                        <input type="number" name="application_numbers" {...register('application_numbers', { required: 'application_numbers' })} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg" />
                                        {errors.application_numbers && (
                                            <div className="mb-3 text-normal text-red-500">
                                                {errors.application_numbers.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="">
                                        <label htmlFor="price" className="block text-sm text-gray-800">Price and/or complementary product or service</label>
                                        <input type="text" name="price" {...register('price', { required: 'Price is required' })} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg" />
                                        {errors.price && (
                                            <div className="mb-3 text-normal text-red-500">
                                                {errors.price.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="">
                                        <label htmlFor="location" className="block text-sm text-black mb-2">Location</label>
                                        <GooglePlacesAutocomplete
                                            apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                            apiOptions={{ language: 'en' }}
                                            selectProps={{
                                                onChange: (value) => { setValue('location', value.value.description) },
                                                styles: {
                                                    input: (provided) => ({
                                                        ...provided,
                                                        color: 'black',
                                                        boxShadow: "none",
                                                        outline: "none",
                                                        ':hover': {
                                                            '--tw-ring-color': 'black !important'
                                                        },
                                                        ':focus': {
                                                            '--tw-ring-color': 'black !important'
                                                        },
                                                    }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        border: '1px solid black',
                                                        ':hover': {
                                                            border: '1px solid #008e95 !important'
                                                        },
                                                        boxShadow: "none",
                                                        outline: "none"
                                                    }),

                                                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                        const color = chroma('#008e95');
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isDisabled
                                                                ? undefined
                                                                : isSelected
                                                                    ? data.color
                                                                    : isFocused
                                                                        ? color.alpha(0.1).css()
                                                                        : undefined,
                                                            ':active': {
                                                                ...styles[':active'],
                                                                backgroundColor: !isDisabled
                                                                    ? isSelected
                                                                        ? color
                                                                        : color.alpha(0.3).css()
                                                                    : undefined,
                                                            },
                                                        };
                                                    },
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <Checkbox inputName={'terms'} id={"terms"} inputValue={"terms"} required={true} labelName={"I agree to terms & conditions and privacy policy"} />
                                    </div>
                                </div>
                                <hr className="h-px my-8 bg-gray-200 border-0" />
                                <div className="mt-6">
                                    <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-lg hover:bg-black focus:outline-none focus:bg-black flex justify-center">
                                        {loading == false &&
                                            <>Submit</>
                                        }
                                        {loading == true &&
                                            <div role="status">
                                                <svg aria-hidden="true" className="w-6 h-6 mr-2 text-gray-200 animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobPost;