import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import hero from '../../assets/images/ethos/hero.jpg';
import Image1 from '../../assets/images/ethos/ethos-1.jpg';
import Image2 from '../../assets/images/ethos/ethos-2.jpg';
import Image3 from '../../assets/images/ethos/mission-2.jpeg';
import Image4 from '../../assets/images/ethos/mission.jpeg';
import Team1 from '../../assets/images/team/1.jpeg'
import Team2 from '../../assets/images/team/2.png'
import Team3 from '../../assets/images/team/3.png'
import Team4 from '../../assets/images/team/4.png'
import Team5 from '../../assets/images/team/5.jpeg'
import Team6 from '../../assets/images/team/6.jpeg'
import Team7 from '../../assets/images/team/7.jpg'
import Team8 from '../../assets/images/team/8.jpeg'
import { Link } from 'react-router-dom';
import './OurEthos.style.scss';



const OurEthos = () => {
    document.title = "Our Ethos | Promote"
    
    return ( 
        <>
        <Header />
        <div id='OurEthos'>
            <section id='section1' className='section' style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("+ hero +")"}}>
                <div className='container mx-auto h-full'>
                    <div className='flex flex-col justify-center items-center h-full w-full'>
                        <div className="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-lg">
                            <h5 className="text-4xl tracking-tight font-extrabold text-center text-primary">Our Ethos</h5>
                        </div>
                    </div>
                </div>
            </section>
            <section id='section2'>
                <div className='container mx-auto'>
                    <div className='flex flex-col justify-center items-center h-full w-full'>
                        <div className='flex flex-row ethos-1 space-x-8'>
                            <div className='basis-full flex flex-col justify-center text-center'>
                                <p>In recent years awareness of charitable causes and sustainability has grown rapidly but the start up industry has very little support. Promote provides a community for likeminded start ups and influencers that share a common goal of making the world a better place.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='section3'>
                <div className='container mx-auto'>
                    <div className='flex flex-col justify-center items-center h-full w-full'>
                        <div className='flex flex-row ethos-1 space-x-8'>
                            <div className='basis-2/2 section2-img' style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("+ Image1 +")"}}>
                                {/* <img src={Image1} alt="" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='section4' className='bg-gray-200'>
                <div className='container mx-auto'>
                    <div className='flex flex-col  justify-center items-center h-full w-full'>
                        <h2 className='mb-10 tracking-tight font-extrabold text-primary text-center'>How we value our customers</h2>
                        <div className='flex flex-row flex-wrap sm:flex-nowrap ethos-1 space-x-8'>
                            <div className='sm:basis-3/5 basis-full section2-img' style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("+ Image2 +")"}}></div>
                            <div className='sm:basis-2/5 basis-full flex flex-col justify-center'>
                                <p className='mb-2 sm:text-start text-center'>At Promote, we put our customers first. Whether you are a start up or an influencer, we want to make sure that you have access to all the tools that you need to succeed.</p>
                                <p className='sm:text-start text-center'>Being an influencer or an entrepreneur doesn’t have to be done alone. At Promote we want to create partnerships with individuals who can work together to achieve their goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='section4-1'>
                <div className='container mx-auto'>
                    <div className='flex flex-col justify-center items-center h-full w-full'>
                        <h2 className='mb-10 tracking-tight font-extrabold text-primary'>Rewilding event</h2>
                        <div className='flex flex-row flex-wrap sm:flex-nowrap ethos-1 sm:space-x-8'>
                            <div className='sm:basis-2/4 basis-full flex flex-col justify-center order-3 sm:order-1 mt-4 sm:mt-0'>
                                <p className='mb-2 sm:text-start text-center'> As a company we are very conscious about the environment and the decrease in the number of species throughout the UK. In July, Co-founders Oliver and Tom planted trees to help create flourishing habitats and strengthen the area’s ecosystem. Bird Cherry and Crab Apple trees were planted because of their environmental benefits.</p>
                                <p className='mb-2 sm:text-start text-center'>Bird Cherry’s spring flowers provide an early source of nectar and pollen for bees. Their wild cherries can be eaten by birds, badgers and yellow necked mice. The foliage is eaten by the caterpillars of many moth species. </p>
                                <p className='sm:text-start text-center'>Crab apple flowers are a good source of early pollen for insects especially bees. Birds such as fieldfare and song thrush enjoy the fruit. Many members of the mammal family also enjoy the fruit including mice, voles and badgers.</p>
                            </div>
                            <div className='sm:basis-1/4 basis-1/2 section2-img p-2 order-1 sm:order-2'  >
                                <img src={Image3} alt="" className=''/>
                            </div>
                            <div className='sm:basis-1/4 basis-1/2 section2-img p-2 order-2 sm:order-3' >
                                <img src={Image4} alt="" className=''/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="section5" className="bg-white">
                <div className="mx-auto max-w-screen-xl text-center">
                    <div className="mx-auto mb-8 max-w-screen-sm ">
                        <h2 className="mb-4 tracking-tight font-extrabold text-primary">Meet Our team</h2>
                        {/* <p className="font-light text-black team-desc">Some sentence about team</p> */}
                    </div> 
                    <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={Team1} alt="Oliver"/>
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-primary">
                                Oliver
                            </h3>
                            <p>CEO/Co-founder</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={Team2} alt="Tom"/>
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-primary">
                                Tom
                            </h3>
                            <p>Co-founder</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={Team8} alt="Hashem"/>
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-primary">
                                Hashem
                            </h3>
                            <p>CTO</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={Team3} alt=" Louis"/>
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-primary">
                                Louis
                            </h3>
                            <p>SEO & Marketing</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={Team5} alt=" Joe"/>
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-primary">
                                Joe
                            </h3>
                            <p>Sales</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={Team4} alt="Mathea"/>
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-primary">
                                Mathea
                            </h3>
                            <p>Human Resources</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={Team6} alt="Loay"/>
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-primary">
                                Loay
                            </h3>
                            <p>DevOps Engineer</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={Team7} alt="Rizvi"/>
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-primary">
                                Rizvi
                            </h3>
                            <p>Full Stack Developer</p>
                        </div>
                    </div>  
                </div>
            </section>
        </div>
        <Footer/>
        </>
     );
}
 
export default OurEthos;