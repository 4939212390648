import React, { useEffect, useState, useContext } from 'react';
import TokenService from '../../API/token.service';
import useAxios, { imgApi } from '../../API/Api.config';
import { Link, useNavigate} from 'react-router-dom';
import api from '../../API/axiosConfig';
import { userContext } from "../../components/Context/UserContext";
import './bespokeinfluencer.style.scss';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Modal, Button } from "flowbite-react";

const BespokeInfluencer = () => {
    document.title = "Bespoke Influencers | Promote"

    const token = TokenService.getLocalAccessToken()
    const role = secureLocalStorage.getItem("role");
    const profileCompleted = secureLocalStorage.getItem('profile_completed')
    const user_id = secureLocalStorage.getItem("user_id");
    const profile = secureLocalStorage.getItem("profile_completed");
    const name = secureLocalStorage.getItem("username");
    const MySwal = withReactContent(Swal)

    const { response, isLoading, status, error } = useAxios({
        method: "get",
        url: 'feature_page/',
        headers: {
            "Authorization": `Token ${token}`
        }
    });

    const openModal = (modalId) => {
        console.log(modalId)
        document.getElementById(modalId).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80'
    }

    const closeModal = (modalId) => {
        console.log(modalId)
        document.getElementById(modalId).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center hidden'
    }

    const extractUsernameFromUrl = (url) => {
        const regex = {
            instagram: /(?:http(?:s)?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_\.]+)/,
            facebook: /(?:http(?:s)?:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9\.]+)/,
            tiktok: /(?:http(?:s)?:\/\/)?(?:www\.)?tiktok\.com\/@([a-zA-Z0-9_\.]+)/,
            linkedin: /(?:http(?:s)?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9\-\.]+)/,
            youtube: /(?:http(?:s)?:\/\/)?(?:www\.)?youtube\.com\/(?:channel\/)?([a-zA-Z0-9_\-]+)/
        };
        
        let username = null;
        Object.keys(regex).some((platform) => {
            const match = url.match(regex[platform]);
            if (match) {
                username = match[1];
                return true;
            }
        });
        
        return username;
    };

    const createSocialMediaUrl = (platform, username) => {
        switch (platform) {
            case 'instagram':
                return `https://www.instagram.com/${username}`;
            case 'facebook':
                return `https://www.facebook.com/${username}`;
            case 'tiktok':
                return `https://www.tiktok.com/@${username}`;
            case 'linkedin':
                return `https://www.linkedin.com/in/${username}`;
            case 'youtube':
                return `https://www.youtube.com/channel/${username}`;
            default:
                return null;
        }
    };

    function getSocialMediaColor(platformName) {
        let color;
        switch (platformName.toLowerCase()) {
          case 'instagram':
            color = '#C13584'; // pink
            break;
          case 'facebook':
            color = '#1877F2'; // blue
            break;
          case 'tiktok':
            color = '#000000'; // black
            break;
          case 'linkedin':
            color = '#0077B5'; // blue
            break;
          case 'youtube':
            color = '#FF0000'; // red
            break;
          default:
            color = '#000000'; // black
        }
        return color;
    }

    useEffect(()=>{console.log(response)},[])

    useEffect(()=>{
        if(!profileCompleted){
            if(role == 1) {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/influencer/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }  else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/business/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }     
        }
    }, [])

    return (  
        <>
            <Header/>
            <Sidebar/>
            <div className='sm:ml-64 mt-20 mb-10' id='bespoke_influencer'>
                <div className='container mx-auto '>
                    <div>
                        <h2 className="p-4 pb-0 text-2xl font-bold">Tailored Influencers</h2>
                        <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mt-5 gap-4'>
                        {response !== null ?
                            response.length > 0 ? 
                                (response?.map(feature => (
                                    <>
                                    { feature.influencer ? 
                                        <>
                                           <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <div className='flex justify-center influencer-image-container bg-fourth'>
                                                    <img className="rounded-lg shadow-lg influencer-image" src={imgApi + feature.influencer.profile_image} alt={feature.influencer.first_name !== "" ? feature.influencer.first_name : feature.influencer.name + 'image'} />
                                                </div>
                                                <div className="p-5">
                                                    <div className='mt-2'>
                                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-black text-center"> {feature.influencer.first_name !== "" ? feature.influencer.first_name : feature.influencer.name}&nbsp;
                                                            <small>
                                                                <i className="fa-sharp fa-solid fa-circle-check text-green-400"></i>
                                                            </small>
                                                        </h5>
                                                    </div>
                                                    <div className='flex justify-center items-center'>
                                                        <React.Fragment>
                                                            <Button 
                                                                onClick={()=> {openModal("ToggleModal"+ feature.id)}}
                                                                className="bg-primary-btn"
                                                            >
                                                                    View Influencer
                                                            </Button>
                                                            <Modal
                                                                show={false}
                                                                id={"ToggleModal"+ feature.id}
                                                                onClose={()=> {closeModal("ToggleModal"+ feature.id)}}
                                                            >
                                                                <Modal.Header>
                                                                    {feature.influencer.first_name !== "" ? feature.influencer.first_name : feature.influencer.name}
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <p className='text-center'>
                                                                        {feature.influencer.bio}
                                                                    </p>
                                                                    <hr className="h-px my-8 bg-gray-200 border-0"/>
                                                                    <p className='text-center'>
                                                                        The Influencer is currently available on:
                                                                    </p>
                                                                    {feature.influencer.social_media.length > 0 ?
                                                                    <div className={'flex justify-center gap-4 mt-3' }>
                                                                        { Object.keys(feature.influencer.social_media[0]).map((oneKey,i)=>{
                                                                            if(oneKey !== 'id') {
                                                                                if((feature.influencer.social_media[0][oneKey] !== '') && (feature.influencer.social_media[0][oneKey] !== null)){
                                                                                    if(feature.influencer.social_media[0][oneKey]?.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase() !== 'NA'.toLowerCase()){
                                                                                        if(feature.influencer.social_media[0][oneKey].toLowerCase().includes(oneKey + ".com") === true) {
                                                                                            return (
                                                                                                <div key={i} className="p-2 rounded-lg hover:border-secondary" style={{background: getSocialMediaColor(oneKey)}}>
                                                                                                    <a href={createSocialMediaUrl(oneKey, extractUsernameFromUrl(feature.influencer.social_media[0][oneKey].toLowerCase().replace('@', '')))} target="_blank" className='text-center w-full block'>
                                                                                                        <i className={"fa-brands fa-"+oneKey+" text-white"}></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <div key={i} className="p-2 rounded-lg hover:border-secondary" style={{background: getSocialMediaColor(oneKey)}}>
                                                                                                    <a href={createSocialMediaUrl(oneKey, feature.influencer.social_media[0][oneKey].replace('@', ''))} target="_blank" className='text-center w-full block'>
                                                                                                        <i className={"fa-brands fa-"+oneKey+" text-white"} ></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    } 
                                                                                }
                                                                            }
                                                                        })}
                                                                    </div> 
                                                                    :
                                                                    <> 
                                                                        <div className='mt-2 bg-secondary rounded-lg p-2 px-4 '>
                                                                            <p className='text-white text-sm text-center'>
                                                                                No Social Media Accounts Found
                                                                            </p> 
                                                                        </div>
                                                                    </>}
                                                                    <hr className="h-px my-8 bg-gray-200 border-0"/>
                                                                    <div className=''>
                                                                        <p className='text-center'>
                                                                            Influencer Details:
                                                                        </p>
                                                                        <div className='grid grid-cols-2 mt-3 gap-4'>
                                                                            <div className='text-center bg-fourth p-4 rounded-lg'>
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                                <p>
                                                                                    {feature.followers}
                                                                                </p>
                                                                                <p className='font-bold text-xs'>
                                                                                    Followers
                                                                                </p>
                                                                            </div>
                                                                            <div className='text-center bg-fourth p-4 rounded-lg'>
                                                                                <i className="fa-solid fa-percent"></i>
                                                                                <p>
                                                                                    {feature.engagement_rate ? feature.engagement_rate + '%' : ''} 
                                                                                </p>
                                                                                <p className='font-bold text-xs'>
                                                                                    Engagment Rate
                                                                                </p>
                                                                            </div>
                                                                            <div className='text-center bg-fourth p-4 rounded-lg'>
                                                                                <i className="fa-sharp fa-solid fa-location-dot"></i>
                                                                                <p>
                                                                                    {feature.location}
                                                                                </p>
                                                                                <p className='font-bold text-xs'>
                                                                                    Location
                                                                                </p>
                                                                            </div>
                                                                            <div className='text-center bg-fourth p-4 rounded-lg'>
                                                                                <i className="fa-solid fa-sterling-sign"></i>
                                                                                <p>
                                                                                    {feature.price}
                                                                                </p>
                                                                                <p className='font-bold text-xs'>
                                                                                    Price
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Modal.Body>
                                                            </Modal>
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <div className='flex justify-center influencer-image-container bg-fourth'>
                                                    <img className="rounded-lg shadow-lg influencer-image "  src={imgApi + feature.influencer_image} alt={feature.influencer_name + 'image'} />
                                                </div>
                                                <div className="p-5">
                                                    <div className='mt-2'>
                                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-black text-center">{feature.influencer_name}</h5>
                                                    </div>
                                                    <div className='flex justify-center items-center'>
                                                        <React.Fragment>
                                                            <Button 
                                                                onClick={()=> {openModal("ToggleModal"+ feature.id)}}
                                                                className="bg-primary-btn"
                                                            >
                                                                    View Influencer
                                                            </Button>
                                                            <Modal
                                                                show={false}
                                                                id={"ToggleModal"+ feature.id}
                                                                onClose={()=> {closeModal("ToggleModal"+ feature.id)}}
                                                            >
                                                                <Modal.Header>
                                                                    {feature.influencer_name}
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <p className='text-center'>
                                                                        {feature.influencer_bio}
                                                                    </p>
                                                                    <hr className="h-px my-8 bg-gray-200 border-0"/>
                                                                    <p className='text-center'>
                                                                        The Influencer is currently available on:
                                                                    </p>
                                                                    <div className='flex justify-center gap-4 mt-3'>
                                                                        <div>
                                                                            {feature.facebook && 
                                                                                <div className="p-2 rounded-lg" style={{background: getSocialMediaColor('facebook')}}>
                                                                                    <a href={createSocialMediaUrl('facebook', feature.facebook.toLowerCase().replace('@', ''))}>
                                                                                        <i className="fa-brands fa-facebook text-white"></i>
                                                                                    </a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {feature.instagram && 
                                                                                <div className="p-2 rounded-lg" style={{background: getSocialMediaColor('instagram')}}>
                                                                                    <a href={createSocialMediaUrl('instagram', feature.instagram.toLowerCase().replace('@', ''))}>
                                                                                        <i className="fa-brands fa-instagram text-white"></i>
                                                                                    </a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {feature.tiktok && 
                                                                                <div className="p-2 rounded-lg" style={{background: getSocialMediaColor('tiktok')}}>
                                                                                    <a href={createSocialMediaUrl('tiktok', feature.tiktok.toLowerCase().replace('@', ''))}>
                                                                                        <i className="fa-brands fa-tiktok text-white"></i>
                                                                                    </a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {feature.linkedin && 
                                                                                <div className="p-2 rounded-lg" style={{background: getSocialMediaColor('linkedin')}}>
                                                                                    <a href={createSocialMediaUrl('linkedin', feature.linkedin.toLowerCase().replace('@', ''))}>
                                                                                        <i className="fa-brands fa-linkedin text-white"></i>
                                                                                    </a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {feature.youtube && 
                                                                                <div className="p-2 rounded-lg" style={{background: getSocialMediaColor('youtube')}}>
                                                                                    <a href={createSocialMediaUrl('youtube', feature.youtube.toLowerCase().replace('@', ''))}>
                                                                                        <i className="fa-brands fa-youtube text-white"></i>
                                                                                    </a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <hr className="h-px my-8 bg-gray-200 border-0"/>
                                                                    <div className=''>
                                                                        <p className='text-center'>
                                                                            Influencer Details:
                                                                        </p>
                                                                        <div className='grid grid-cols-2 mt-3 gap-4'>
                                                                            <div className='text-center bg-fourth p-4 rounded-lg'>
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                                <p>
                                                                                    {feature.followers}
                                                                                </p>
                                                                                <p className='font-bold text-xs'>
                                                                                    Followers
                                                                                </p>
                                                                            </div>
                                                                            <div className='text-center bg-fourth p-4 rounded-lg'>
                                                                                <i className="fa-solid fa-percent"></i>
                                                                                <p>
                                                                                    {feature.engagement_rate ? feature.engagement_rate + '%' : ''} 
                                                                                </p>
                                                                                <p className='font-bold text-xs'>
                                                                                    Engagment Rate
                                                                                </p>
                                                                            </div>
                                                                            <div className='text-center bg-fourth p-4 rounded-lg'>
                                                                                <i className="fa-sharp fa-solid fa-location-dot"></i>
                                                                                <p>
                                                                                    {feature.location}
                                                                                </p>
                                                                                <p className='font-bold text-xs'>
                                                                                    Location
                                                                                </p>
                                                                            </div>
                                                                            <div className='text-center bg-fourth p-4 rounded-lg'>
                                                                                <i className="fa-solid fa-sterling-sign"></i>
                                                                                <p>
                                                                                    {feature.price}
                                                                                </p>
                                                                                <p className='font-bold text-xs'>
                                                                                    Price
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Modal.Body>
                                                            </Modal>
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    </>
                                )))
                                :
                                <></>
                            :
                            <></>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BespokeInfluencer;