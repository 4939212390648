import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import hero from '../../assets/images/how-it-works/hero.jpg';
import Image1 from '../../assets/images/how-it-works/connection.png';
import Image2 from '../../assets/images/how-it-works/job_create.png';
import Image3 from '../../assets/images/how-it-works/jobs.png';
import Image4 from '../../assets/images/how-it-works/messaging.png';
import Image5 from '../../assets/images/how-it-works/profile-influencer.png';
import Image6 from '../../assets/images/how-it-works/profile-business.png';
import { Link } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import './WhatWeDo.style.scss';



const WhatWeDo = () => {
    document.title = "How it works | PROMOTE"
    const role_web = secureLocalStorage.getItem("role_web");
    console.log(role_web);
    
    return ( 
        <>
        <Header />
        <div id='OurEthos'>
            <section id='section1' className='section' style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("+ hero +")"}}>
                <div className='container mx-auto h-full'>
                    <div className='flex flex-col justify-center items-center h-full w-full'>
                        <div className="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-lg">
                            <h5 className="text-4xl tracking-tight font-extrabold text-center text-primary">How It Works</h5>
                        </div>
                    </div>
                </div>
            </section>
            <section id='section2'>
                <div className='container mx-auto'>
                    <div className='flex flex-col justify-center items-center h-full w-full'>
                    { role_web === 1 ? 
                        <>
                          <h3 className='text-4xl'>
                            <b>
                                Influencers
                            </b>
                        </h3>
                        <div className='bg-white sm:p-10 p-4 mt-10 rounded-lg'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center sm:order-1 order-2'>
                                    <p className='font-bold'>
                                        Sign-up
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                Make sure your profile is complete as possible. It increases the chance of businesses choosing you.
                                            </li>
                                            <li>
                                                Minimum requirement to set up a profile is:
                                                <ul className='list-disc pl-6'>
                                                    <li>
                                                        Profile picture
                                                    </li>
                                                    <li>
                                                        A social media link
                                                    </li>
                                                    <li>
                                                        Choose an interest (3max)
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 sm:order-2 order-1'>
                                    <div className='mt-4'>
                                        <img src={Image5} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white sm:p-10 p-4 rounded-lg mt-10'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4'>
                                    <div className='mt-4'>
                                        <img src={Image3} alt="" />
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center'>
                                    <p className='font-bold'>
                                        Job Forum
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                Scroll through the available promotions. Look at the criteria of the job to make sure you fit the description
                                            </li>
                                            <li>
                                                When applying make sure you give a detailed reasoning of why you want to promote the business. Attach a photo of a similar promotion you have completed or create an example promotion for them.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white sm:p-10 p-4 mt-10 rounded-lg'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center sm:order-1 order-2'>
                                    <p className='font-bold'>
                                        Forming a connection
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                Once the business has decided they want you they will form a connection.
                                            </li>
                                            <li>
                                                Once this connection is made you can message the business and check out their PROMOTE profile.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 sm:order-2 order-1'>
                                    <div className='mt-4'>
                                        <img src={Image1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white sm:p-10 p-4 rounded-lg mt-10'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 sm:order order'>
                                    <div className='mt-4'>
                                        <img src={Image4} alt="" />
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center'>
                                    <p className='font-bold'>
                                        Messaging the business 
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                Form a partnership over the messaging service and go into more detail about the price and the promotion.
                                            </li>
                                            <li>
                                                You may need to send your address or a nearby one if it is a product promotion.
                                            </li>
                                            <li>
                                                Finally, send over your bank details.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white sm:p-10 p-4 mt-10 mb-10 rounded-lg'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center sm:order-1 order-2'>
                                    <p className='font-bold'>
                                        Collaboration
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                The search allows you to find the likeminded influencers who you can enter into group chats and collaborations with.
                                            </li>
                                            <li>
                                                Make use of the 9 filters available when searching.
                                            </li>
                                        </ul>
                                    </div>
                                    <p className='font-bold mt-4'>
                                        Payment
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                Once the promotion is up on your social media then the business should transfer the payment 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 sm:order-2 order-1'>
                                    <div className='mt-4'>
                                        <img src={Image1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                        </>
                        :
                        <> 
                        <h3 className='text-4xl'>
                            <b>
                                Businesses
                            </b>
                        </h3>
                        <div className='bg-white sm:p-10 p-4 mt-10 rounded-lg'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center sm:order-1 order-2'>
                                    <p className='font-bold'>
                                        Sign-up
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                Make sure your profile is complete as possible. It allows your chosen influencer to get a better understanding of your business.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 sm:order-2 order-1'>
                                    <div className='mt-4'>
                                        <img src={Image6} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white sm:p-10 p-4 rounded-lg mt-10'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4'>
                                    <div className='mt-4'>
                                        <img src={Image2} alt="" />
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center'>
                                    <p className='font-bold'>
                                        Job Forum
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                When you create a promotion on the job forum it allows the influencer to apply and for you to choose.
                                            </li>
                                            <li>
                                                Make sure you give a detailed brief explaining exactly the influencers you are after and what you would like the promotion to entail.
                                            </li>
                                            <li>
                                                Make sure you fill in:
                                                <ul className='list-disc pl-6'>
                                                    <li>
                                                        Price
                                                    </li>
                                                    <li>
                                                        Location
                                                    </li>
                                                    <li>
                                                        Minimum followers
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Once the influencer has applied then you can scroll through their profiles. On deciding which influencers you would like you can form a connection with.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white sm:p-10 p-4 mt-10 rounded-lg'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center sm:order-1 order-2'>
                                    <p className='font-bold'>
                                        Search
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                The search allows you to find the exact influencer you are after with our highly optimized filter system. 
                                            </li>
                                            <li>
                                                Make use of the 9 filters available.
                                            </li>
                                            <li>
                                                Scroll through their PROMOTE profiles until you have found your ideal influencer and then form a connection.
                                            </li>
                                        </ul>
                                    </div>
                                    <p className='font-bold mt-4'>
                                        Collaboration
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                You can also use the search filter to find likeminded businesses and enter into collaborations to help one another achieve your goals.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 sm:order-2 order-1'>
                                    <div className='mt-4'>
                                        <img src={Image1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white sm:p-10 p-4 rounded-lg mt-10'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 sm:order order'>
                                    <div className='mt-4'>
                                        <img src={Image4} alt="" />
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center'>
                                    <p className='font-bold'>
                                        Connection
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                Depending on which subscription you chose restricts the number of connections.
                                            </li>
                                            <li>
                                                A connection should be made when you are happy for them to promote your business.
                                            </li>
                                            <li>
                                                A connection allows you to use our messaging service.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white sm:p-10 p-4 mt-10 mb-10 rounded-lg'>
                            <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 flex flex-col items-start justify-center sm:order-1 order-2'>
                                    <p className='font-bold'>
                                        Messaging the influencer 
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                Form a partnership over the messaging service and go into more detail about the price and the promotion.
                                            </li>
                                            <li>
                                                You may need to request their address or a nearby one if it is a product promotion
                                            </li>
                                            <li>
                                                Finally, ask for their bank details
                                            </li>
                                        </ul>
                                    </div>
                                    <p className='font-bold mt-4'>
                                        Payment
                                    </p>
                                    <div className='mt-4'>
                                        <ul className='list-disc pl-6 space-y-4'>
                                            <li>
                                                Once the promotion is up on their social media and you are happy then transfer the payment.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='sm:basis-1/2 basis-full sm:p-10 p-4 sm:order-2 order-1'>
                                    <div className='mt-4'>
                                        <img src={Image1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>}
                    </div>
                </div>
            </section>
        </div>
        <Footer/>
        </>
     );
}
 
export default WhatWeDo;