import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ApiUrl } from "../../../API/Api.config";
import { useStepperContext } from "../../../contexts/StepperContext";
import CheckboxButton from "../../../components/CheckboxButtons/CheckboxButtons";


function Goals({heading, subHeading, apiName}){
    const { userData, setUserData } = useStepperContext();
    const [goalData, SetgoalData] = useState([]);
    const [userGoal, SetUserGoal] = useState([]);
    async function getGoalData() {
        try {
            await axios.get(ApiUrl + 'compaign_goal/', {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => SetgoalData(response.data))
            .catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {
        const { id, checked } =  e.target

        if(checked) {
            SetUserGoal([...userGoal, goalData.at(id)])
        } else {
            userGoal.splice(userGoal.indexOf(id), 1);
        }
    };

    useEffect(() => {
        getGoalData();
    }, [])
    
    useEffect(() => {
        setUserData({ ...userData, [apiName]:userGoal });
    }, [userGoal])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        {subHeading}
                    </small>
                </p>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card">
                        <CheckboxButton 
                        inputName={'content'}
                        apiName={'goals'}
                        apiName2={'goals'}
                        onChange={handleChange}
                        options={goalData}
                        ></CheckboxButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Goals;