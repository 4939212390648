import { useState, useEffect, useCallback } from "react";
import Stepper from "../../../components/Onboarding/Stepper";
import StepperControl from "../../../components/Onboarding/StepperControl";
import { UseContextProvider, useStepperContext} from "../../../contexts/StepperContext";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import SocialMedia from "../../../components/Onboarding/steps/SocialMedia";
import SocialMediaConnect from "../../../components/Onboarding/steps/SocialMediaConnect";
import UploadProfilePicture from "../../../components/Onboarding/steps/UploadProfilePicture";
import Yourself from "../../../components/Onboarding/steps/Yourself";
import Interests from "../../../components/Onboarding/steps/Interests";
import DemoG from "../../../components/Onboarding/steps/DemoG";
import DemoG2 from "../../../components/Onboarding/steps/DemoG2";
import Content from "../../../components/Onboarding/steps/Content";
import Reward from "../../../components/Onboarding/steps/Reward";
import Expectation from "../../../components/Onboarding/steps/Expectation";
import Goals from "../../../components/Onboarding/steps/Goals";
import Charity from "../../../components/Onboarding/steps/Charity";
import Complete from "../../../components/Onboarding/steps/Complete";

import hero from '../../../assets/images/onboarding/large.jpeg';
import './../onboarding.style.scss';

const StartupOnboarding = () => {

    document.title = "Startup Onboarding | PROMOTE"

    const [currentStep, setCurrentStep] = useState(1);
    const [dataEmpty, setDataEmpty] = useState(true);
    const user_id = secureLocalStorage.getItem('user_id')
    const nav = useNavigate();

    const steps = [
        "Social Media",
        "Connect",
        "Picture",
        "Business Details",
        "Charity",
        "Audience",
        "Location",
        "Social Platforms",
        "Influencers",
        "Influencers 2",
        "Content",
        "Collaboration",
        "Goals",
        "Complete",
    ];

    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <SocialMedia setDataEmpty={setDataEmpty} apiName={'social_network'} heading={'Choose your social media platforms'} subHeading={'Please choose the social media platforms your business has an account on (Select Multiple)'}/>;
            case 2:
                return <SocialMediaConnect setDataEmpty={setDataEmpty}/>;
            case 3:
                return <UploadProfilePicture setDataEmpty={setDataEmpty} apiName={'logo'} heading={'Add your logo'} />;
            case 4:
                return <Yourself setDataEmpty={setDataEmpty} heading={'Business Details'} apiName={'bio'} subHeading={'Please provide more details about your business'} influencer={0}/>;
            case 5:
                return <Charity setDataEmpty={setDataEmpty} heading={'Charitable or Sustainable'} apiName={'charitable'} apiName2={'is_sustainable'} subHeading={'Please select whether your business has a sustainable or charitable aspect. If you select \'Sustainable\' or \'Charitable\' aspect please describe how below'} influencer={0}/>;
            case 6:
                return <DemoG apiName={'target_audience'} heading={'Target audience'} subHeading={'Please select your ideal target audience (multi select)'}/>;
            case 7:
                return <DemoG2 apiName={'location'} heading={'Business location'} subHeading={'Where does your business operate?'}/>;
            case 8:
                return <SocialMedia setDataEmpty={setDataEmpty} apiName={'social_network'} heading={'Social media platforms'} subHeading={'Which social media do you want to see your brand promoted on? (multi select)'}/>;
            case 9:
                return <Expectation heading={'Target influencers'} apiName={'influencer_followers_from'} apiName2={'influencer_followers_until'} subHeading={'How many followers should your influencer have?'} influencer={0}/>;
            case 10:
                return <Interests setDataEmpty={setDataEmpty} apiName={'influencers_interest'} heading={'Target influencers'} subHeading={'Which type of influencers would you like to collaborate with?'}/>;
            case 11:
                return <Content heading={'Promotional content'} apiName={'type_of_collabration'} subHeading={'What type of content would you like your brand to be presented through? (multi select)'}/>;
            case 12:
                return <Reward heading={'Collaboration Type'} apiName={'callaboration_options'} subHeading={'How would you like to pay your influencers? (multi select)'}/>;
            case 13:
                return <Goals heading={'Campaign goals'} apiName={'compaign_goal'} subHeading={'What goal do you want to achieve through influencer marketing? (multi select)'}/>;
            case 14:
                return <Complete apiName={'startup_info/'} redirect={"/dashboard/pricing-plan"} closeBtn={'Go to the next step'} heading={'You have successfully completed the sign-up phase.'} influencer={0}/>;
            default:
        }
    };
    
    const handleClick = (direction) => {
        let newStep = currentStep;
    
        direction === "next" ? newStep++ : newStep--;
        // check if steps are within bounds
        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
    };

    useEffect(() => {
        if(user_id == null) {
            nav('/logout')
        }
    }, [user_id]);

    return(
        <section className="section" id="onboarding" style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + hero + ")" }}>
            <div className="container ">
                <div className=" ">
                    <div className="mx-auto flex flex-col rounded-2xl bg-white pb-2 w-full shadow onboard-card">
                        {/* Stepper */}
                        <div className="pt-10 hidden sm:block">
                            <Stepper steps={steps} currentStep={currentStep} />
                        </div>
                        <div className="pt-10 sm:hidden block">
                            <p className="text-center">
                                Approx. 10 mins to complete
                            </p>
                        </div>
                        <div className="onboarding-steps">
                            <div className="my-5">
                                <div className="sm:py-10 py-5">
                                    <div className="flex justify-center items-center each-step">
                                        <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
                                    </div>
                                </div>
                                {/* navigation button */}
                                {currentStep !== steps.length && (
                                    <StepperControl
                                    dataEmpty={false}
                                    handleClick={handleClick}
                                    currentStep={currentStep}
                                    steps={steps}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default StartupOnboarding;