import  {useState, useEffect} from 'react';
import  React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import image1 from '../../assets/images/influencer/image-1.jpg';
import image2 from '../../assets/images/influencer/image-2.jpg';
import image3 from '../../assets/images/influencer/image-3.jpg';
// import productImage from '../../assets/images/influencer/product-image.jpg';
import infuencerImage1 from '../../assets/images/influencer/beinfluencer1.jpg';
import infuencer1 from '../../assets/images/influencer/influencer-1.jpg';
import infuencer2 from '../../assets/images/influencer/influencer-2.jpg';
import infuencer3 from '../../assets/images/influencer/influencer-3.jpg';
import infuencer4 from '../../assets/images/influencer/influencer-4.jpg';
import infuencer5 from '../../assets/images/influencer/influencer-5.jpg';
import playStore from '../../assets/images/influencer/playstore-badge.png';
import logo from '../../assets/images/logo1.png';
import './influencerHome.style.scss';
import axios from 'axios';
import { ApiUrl, imgApi } from '../../API/Api.config';
import video from '../../assets/images/influencer/influencer.mp4';
import secureLocalStorage from "react-secure-storage";
import CookieConsent from "react-cookie-consent";


const InfluencerHome = () => {

    const [allJobs, setAllJobs] = useState([]);
    const [jobs, setJobs]= useState([]);
    const role = secureLocalStorage.getItem('role')

    async function getJobs() {
        try {
            await axios.get(ApiUrl + 'job_page_influencer/', {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => setAllJobs(response.data))
                .catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }

    const truncateSentence = (sentence) => {
        if (sentence.length > 100) {
          return sentence.slice(0, 100) + "...";
        }
        return sentence;
    }

    useEffect(()=>{
        getJobs()
    }, []);
    
    useEffect(()=>{
        setJobs(()=> allJobs.slice(0,4))
        console.log(allJobs)
    }, [allJobs])


    document.title = "Influencer | Promote"

    return ( 
        <>
        <Header/>
        <div id='influencerHome'>
            <section id='section1' className='section'>
                <div className='container mx-auto h-full'>
                    <div className='flex flex-col justify-start items-center h-full w-full'>
                        <div className='flex flex-row flex-wrap sm:flex-nowrap w-full h-full sm:space-x-8'>
                            <div className='sm:basis-1/4 basis-full'>
                                <h1>
                                    <div className='p-2 text-primary big-text'>PARTNER.</div><div className='p-2'>CREATE.</div><div className='bg-third p-2 big-text text-white'>INFLUENCE.</div>
                                </h1>
                                <div className=''>
                                    {/* <button type="button" className="text-white bg-secondary hover:bg-third hover:text-white focus:ring-5 focus:ring-primary flex justify-center text-base  text-center ">
                                        <a href={role ? "/user-info" : "/login"} className='flex text-white items-center h-full px-6 py-3.5'>
                                            Start&nbsp;<b>influencing</b>&nbsp;the world <i className="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </button> */}
                                    <div className='flex items-center space-x-4'>
                                        <div>
                                            <a 
                                                href="https://apps.apple.com/gb/app/promote-influencers/id6474446086?itsct=apps_box_badge&amp;itscg=30200" 
                                                style={{ display: 'inline-block', overflow: 'hidden', borderRadius: '13px', width: '160px', height: '83px' }}
                                            >
                                                <img 
                                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1707868800" 
                                                    alt="Download on the App Store" 
                                                    style={{ borderRadius: '13px', width: '160px', height: '83px' }}
                                                />
                                            </a>
                                        </div>
                                        <div>
                                        <a 
                                                href="https://play.google.com/store/apps/details?id=com.promoteMobileApp.PromoteApp" 
                                                style={{ display: 'inline-block', overflow: 'hidden', borderRadius: '5px', width: '160px', height: '50px' }}
                                            >
                                                <img 
                                                    src={playStore} 
                                                    alt="Download on the Playstore" 
                                                    style={{ borderRadius: '5px', width: '160px', height: '50px' }}
                                                />
                                            </a>   
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            {/* <div className='basis-1/4'></div> */}
                            <div className='sm:basis-3/4 basis-full'>
                                <div className='flex flex-row flex-wrap sm:flex-nowrap justify-end h-full sm:space-x-4'>
                                    <video controls autoPlay muted loop id='vid' className='border-4 border-primary'>
                                        <source src={video} type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className='text-2xl w-full bg-fourth p-8 text-center text-black'>
                    Turn your passion into profit as an influencer with <span className='text-primary'>promote</span>
                </h5>
            </section>
            <section id='section2'>
                <div className='container mx-auto h-full'>
                    <div className='flex flex-col justify-start items-center h-full w-full'>
                        <h2 className='text-center'>Find a product to <span className='text-primary'>promote</span></h2>
                        <div className='flex flex-row flex-wrap sm:flex-nowrap w-full sm:space-x-4'>
                            {jobs.map(obj => {
                                return (
                                <div className='sm:basis-1/3 basis-full sm:mt-0 mt-4' key={obj.id} >
                                    <div className="bg-white border border-gray-200 shadow-md card job-cards">
                                        <div className='p-5 product-image'>
                                            <div className='p-img' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + imgApi + obj.image1 + ")" }}></div>
                                            {/* <img className=" " src={imgApi + obj.image1} alt="" /> */}
                                        </div>
                                        <div className="p-5 pt-0 product-desc">
                                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">{obj.title}</h5>
                                            <p className="mb-3 font-normal text-gray-700">{obj.short ? truncateSentence(obj.short) : obj.short}</p>
                                            <small>
                                                <p><b>Job by</b>: {obj.startup_name}</p>
                                                <p><b>Reward</b>: {obj.price}</p>
                                                <p><b>Minimum Followers</b>: {obj.min_influencer_numbers}</p>
                                                <p className='promo-req'><b>Promotion Requirments</b>: {obj.promotion_req}</p>
                                            </small>
                                            {/* <button className=' mt-5 text-sm font-medium bg-primary rounded-lg focus:ring-4 focus:outline-none hover:bg-fourth w-full'>
                                                <a href={role ? "/campaigns" : "/login"} className=" flex items-center justify-center hover:text-black text-center text-white w-full py-2 px-3">
                                                    <b>Promote now</b> 
                                                    <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                </a>
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                        <div className='button-div'>
                            <a href="#section1" className="inline-flex items-center justify-center w-full h-full py-2 px-3 text-sm font-medium text-center text-white bg-secondary rounded-lg hover:bg-third focus:ring-4 focus:outline-none">
                                <b>Find more brands to collaborate with</b> 
                                <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section id='section3' className='section'>
                <div className='container mx-auto h-full'>
                    <div className='flex flex-col justify-start items-center'>   
                        <div className='w-full'>
                            <div className='w-full'>
                                <div className="block p-4 w-full bg-secondary rounded-lg border border-gray-200 shadow-md">
                                    <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                                        <div className='sm:basis-1/3 basis-1/2 text-center p-2 flex flex-col justify-center items-center  border-r-2 border-b-2 sm:border-b-0'>
                                            <p className='text-white'><b>65+</b></p>
                                            <p className='text-white'>Businesses signed up to Promote.</p>
                                        </div>
                                        <div className='sm:basis-1/3 basis-1/2 text-center p-2 flex flex-col justify-center items-center border-l-2 sm:border-r-2 border-b-2 sm:border-b-0'>
                                            <p className='text-white'><b>Trusted By</b></p>
                                            <p className='text-white'>1000's of creators</p>
                                        </div>
                                        <div className='sm:basis-1/3 basis-full p-2 text-center flex flex-col justify-center items-center  sm:border-l-2 border-t-2 sm:border-t-0'>
                                            <p className='text-white'><b>Approaching 50</b></p>
                                            <p className='text-white'>Collaborations</p>
                                        </div>
                                    </div>
                                    {/* <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">1000+ Influncers | 6.5% Avg. engagement rate</h5> */}
                                    <p className="font-normal text-gray-700 dark:text-gray-400"></p>
                                </div>
                            </div>
                        </div>
                        <h2 className='text-center'>Benefits of being an <span className='text-primary'>influencer</span></h2>
                        <div className='mt-10'>
                            <p className='main-text'>Everyone has the potential to influence others no matter the number of followers! Promote your favourite products for brands that share your ethos in exchange for cash and become a part of the new era of advertising.</p>
                        </div>
                        <div className='flex w-full mt-5 flex-wrap sm:flew-nowrap mt-10'>
                            <div className='sm:basis-1/3 basis-full px-4 text-center'>
                                <div className='flex justify-center'>
                                    <span className="flex justify-center items-center point-ring rounded-full text-secondary bg-primary">
                                        <i className="fa-solid fa-award"></i>
                                    </span>
                                </div>
                                <h3 className="text-center mb-1 mt-5 text-lg font-semibold text-secondary">You are your own boss!</h3>
                                <p className="font-normal text-third">Post authentic content that excites you and your followers.</p>
                            </div>
                            <div className='sm:basis-1/3 basis-full px-4 text-center sm:mt-0 mt-4'>
                                <div className='flex justify-center'>
                                    <span className="flex justify-center items-center point-ring rounded-full text-secondary bg-primary">
                                        <i className="fa-solid fa-money-bill-1-wave"></i>
                                    </span>
                                </div>
                                <h3 className="text-center mb-1 mt-5 text-lg font-semibold text-secondary">Secondary income stream!</h3>
                                <p className="font-normal text-third">Earn money from your amazing promotions around your day-to-day life.</p>
                            </div>
                            <div className='sm:basis-1/3 basis-full px-4 text-center sm:mt-0 mt-4'>
                                <div className='flex justify-center'>
                                    <span className="flex justify-center items-center point-ring rounded-full text-secondary bg-primary">
                                        <i className="fa-solid fa-link"></i>
                                    </span>
                                </div>
                                <h3 className="text-center mb-1 mt-5 text-lg font-semibold text-secondary">Building connections!</h3>
                                <p className=" font-normal text-third">Find like-minded individuals and watch your network grow.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='section4' className='section bg-fourth'>
                <div className='container mx-auto'>
                    <div className='flex flex-col'>
                        <h2 className='text-center'>
                            What our influencers say about <span className='text-primary'>Promote</span>
                        </h2>
                        <div id="controls-carousel-2" className="relative" data-carousel="static">
                            <div className="overflow-hidden relative influencer-review">
                                <div className="duration-700 ease-in-out absolute inset-0 transition-all transform -translate-x-full z-10" data-carousel-item="">
                                    <figure className="max-w-screen-lg mx-auto text-center flex flex-col items-center justify-center h-full">
                                        <svg className="h-12 mx-auto mb-3 text-primary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                                        </svg> 
                                        <blockquote>
                                            <p className="font-medium text-black">Working with Promote was so much fun. All the team are very attentive. The service is smooth and straightforward and the communication is efficient. I would 100% recommend</p>
                                        </blockquote>
                                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                                            <div className="flex items-center flex-col">
                                                <img className="w-16 h-16 rounded-full" src={infuencer1} alt="profile picture"/>
                                                <div className="font-medium text-black mt-3">Maisa Alves</div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20" data-carousel-item="active">
                                    <figure className="max-w-screen-lg mx-auto text-center flex flex-col items-center justify-center h-full">
                                        <svg className="h-12 mx-auto mb-3 text-primary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                                        </svg> 
                                        <blockquote>
                                            <p className="font-medium text-black">Finding promote has been so helpful, not only have they been so lovely and supportive but finding small business to support and that want to work with me has been great! I’ve been able to try new things out and build relationships with companies I wouldn’t of known about without promote. I’m so grateful for the opportunities they have opened for me and looking forward to more brand collabs in the new year 🥰</p>
                                        </blockquote>
                                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                                            <div className="flex items-center flex-col">
                                                <img className="w-16 h-16 rounded-full" src={infuencer4} alt="profile picture"/>
                                                <div className="font-medium text-black mt-3">Chloe</div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20" data-carousel-item="active">
                                    <figure className="max-w-screen-lg mx-auto text-center flex flex-col items-center justify-center h-full">
                                        <svg className="h-12 mx-auto mb-3 text-primary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                                        </svg> 
                                        <blockquote>
                                            <p className="font-medium text-black">Thank you so much for finding me this beautiful collaboration it fits my page aesthetic and you were so helpful throughout the process - can’t wait to work with you again! Highly recommend.</p>
                                        </blockquote>
                                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                                            <div className="flex items-center flex-col">
                                                <img className="w-16 h-16 rounded-full" src={infuencer2} alt="profile picture"/>
                                                <div className="font-medium text-black mt-3">Becca</div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20" data-carousel-item="active">
                                    <figure className="max-w-screen-lg mx-auto text-center flex flex-col items-center justify-center h-full">
                                        <svg className="h-12 mx-auto mb-3 text-primary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                                        </svg> 
                                        <blockquote>
                                            <p className="font-medium text-black">I love working with Promote, every day there are new opportunities to collaborate with amazing brands. It is also a great opportunity to get seen, get experience in creating and expand your portfolio</p>
                                        </blockquote>
                                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                                            <div className="flex items-center flex-col">
                                                <img className="w-16 h-16 rounded-full" src={infuencer5} alt="profile picture"/>
                                                <div className="font-medium text-black mt-3">Simona</div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20" data-carousel-item="active">
                                    <figure className="max-w-screen-lg mx-auto text-center flex flex-col items-center justify-center h-full">
                                        <svg className="h-12 mx-auto mb-3 text-primary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                                        </svg> 
                                        <blockquote>
                                            <p className="font-medium text-black">This platform is perfect to help you connect to brands and get paid work. It's super easy to use and navigate. I'm so happy I've found it and have recommended it to my friends to use as well</p>
                                        </blockquote>
                                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                                            <div className="flex items-center flex-col">
                                                <img className="w-16 h-16 rounded-full" src={infuencer3} alt="profile picture"/>
                                                <div className="font-medium text-black mt-3">Donna</div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </div>
                           
                            </div>
                            <div className='sm:static relative'>
                                <button type="button" className="flex absolute top-0 left-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-prev="">
                                    <span className="inline-flex justify-center items-center w-10 h-10 rounded-full bg-secondary text-white group-focus:ring-4 group-focus:ring-white">
                                        <svg aria-hidden="true" className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
                                        <span className="sr-only">Previous</span>
                                    </span>
                                </button>
                                <button type="button" className="flex absolute top-0 right-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-next="">
                                    <span className="inline-flex justify-center items-center w-10 h-10 rounded-full bg-secondary text-white group-focus:ring-4 group-focus:ring-white">
                                        <svg aria-hidden="true" className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                                        <span className="sr-only">Next</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CookieConsent buttonStyle={{ backgroundColor: "#008e95", fontSize: "13px" }}>This website uses cookies to enhance the user experience.</CookieConsent>
        <Footer/>
        </div>
        </>
    );
}
 
export default InfluencerHome;