import React, { useState } from 'react'
import { useEffect } from 'react'
import logo from '../../assets/images/logo1.png';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import useAxios, { ApiUrl, profileImageLink } from '../../API/Api.config'
import TokenService from '../../API/token.service'
import chroma from "chroma-js";
import axios from 'axios';
import Select from 'react-select'
import ReactStars from "react-rating-stars-component";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import secureLocalStorage from "react-secure-storage";
import './taskcenter.style.scss'
import { get } from 'react-hook-form';

const TaskCenter = () => {
    document.title = "Task Center | Promote"

    const [data, setData] = useState([]);
    const role = secureLocalStorage.getItem('role')
    const profileCompleted = secureLocalStorage.getItem('profile_completed')
    const [connection, setConnection] = useState([]);
    const [contractData, setContractData] = useState({});
    const [selectedJob, setSelectedJob] = useState("");
    const [selectedInfluencer, setSelectedInfluencer] = useState({});
    const [influencerRating, setInfluencerRating] = useState(0);
    const [influencerReview, setInfluencerReview] = useState("");
    const [influencerNote, setInfluencerNote] = useState("");
    const [points, setPoints] = useState(0);
    const [paymentDone, setPaymentDone] = useState({ 'payment_by_startup': 1 });
    const [influencerApprove, setInfluencerApprove] = useState({ 'influencer_approval': 1 });
    const [influencerReject, setInfluencerReject] = useState({ 'startup_approval': 3, 'contract_sent': 0 });
    const [startupApprove, setStartupApprove] = useState({ 'startup_done_task_approval': 1 });
    const [startupReject, setStartupReject] = useState({ 'startup_done_task_approval': 3, 'influencer_done_task': 0 });
    const [startupId, setStartupId] = useState(0);
    const [influencerId, setInfluencerId] = useState(0);
    const userid = secureLocalStorage.getItem("user_id");
    const MySwal = withReactContent(Swal)
    const token = TokenService.getLocalAccessToken();

    const { isLoading, response, error, status, reload } = useAxios({
        url: 'task_completed/',
        method: 'get',
        headers: {
            "Authorization": `Token ${token}`
        }
    })

    async function getStartupJob() {
        try {
            axios.get(ApiUrl + 'my_job/', {
                headers: {
                    "Authorization": `Token ${token}`
                }
            }).then(response => {
                response.data.map((obj) => {
                    setData(prevState => [
                        ...prevState,
                        {
                            'id': obj.id,
                            'label': obj.title,
                            'value': obj.title,
                        }
                    ]);
                })

                // setData(response.data)

            }).catch(error => console.log(error));
        } catch (e) {
            console.log(e)
        }
    }


    // async function getInfluencerPoints(){
    //     try{
    //         axios.get(ApiUrl + 'influencer_points/', {headers: {
    //             "Authorization": `Token ${token}`
    //         }}).then(response => {
    //             setPoints(response.data[0].points)

    //         }).catch(error => console.log(error));
    //     }catch(e){
    //         console.log(e)
    //     }
    // }

    async function getUserConnection() {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": `Token ${token}`,
        }

        try {
            const response = await axios.get(`get_connection/`, { headers })
            if (response.data !== null) {
                response.data.map((obj) => {
                    setConnection(prevState => [
                        ...prevState,
                        {
                            'id': obj.friendid,
                            'label': obj.friend_name,
                            'value': obj.friend_name,
                        }
                    ]);
                })
            }
        }
        catch (error) {
            if (error.response.status == 401) {
                // console.log(response.status);
            };
        }
    }

    async function createTask(modalid, btnId) {

        try {
            document.getElementById(btnId).innerHTML = 'Loading';

            await axios.post(ApiUrl + `task_completed/`,
                {
                    'task': selectedJob,
                    'startup': userid,
                },
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    },
                })
                .then(function (response) {

                    reload()
                })
                .catch(function (error) {
                    console.log(error)
                })
                .finally(function () {
                    document.getElementById(modalid).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center hidden'

                    document.getElementById(btnId).innerHTML = 'Submit'
                });
        } catch (error) {
            console.log(error)
        }
    };

    async function deleteTask(id) {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ff0000',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(ApiUrl + `task_completed/?pk=${id}`,
                        {
                            headers: {
                                "Content-type": "application/json",
                                "Authorization": `Token ${token}`
                            },
                        })
                        .then(function (response) {
                            reload()
                            MySwal.fire({
                                position: 'top',
                                toast: true,
                                icon: 'success',
                                title: 'Task Center Deleted',
                                showConfirmButton: false,
                                timer: 3000,
                            })
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                        .finally(function () { });
                }
            })

        }
        catch { }
    }

    async function submitTask(data, id, required, modalId, btnId, notifyId) {

        document.getElementById(btnId).innerHTML = 'Loading';

        if (notifyId) {
            if (role == 1) {
                data['startup'] = notifyId
            } else {
                data['influencer'] = notifyId
            }
        }

        if (required == 1) {
            if (Object.keys(data).length > 0 && (data.contract_field1 !== '' || data.contract_field2 !== '') && (data.contract_field3 !== '' && data.contract_field4 !== '')) {
                try {
                    await axios.patch(ApiUrl + `task_completed/?pk=${id}`, data,
                        {
                            headers: {
                                "Content-type": "application/json",
                                "Authorization": `Token ${token}`
                            },
                        })
                        .then(function (response) {
                            reload()
                        })
                        .catch(function (error) {
                            if (error.request.status === 400) {
                            }
                        })
                        .finally(function () {
                            document.getElementById(modalId).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center hidden'

                            document.getElementById(btnId).innerHTML = 'Submit'
                        });
                } catch (error) {
                    console.log(error)
                }
            } else {
                MySwal.fire({
                    position: 'top',
                    toast: true,
                    icon: 'error',
                    title: 'Please fill up the contract',
                    showConfirmButton: false,
                    timer: 3000,
                })

                document.getElementById(btnId).innerHTML = 'Submit'
            }
        } else {
            try {
                await axios.patch(ApiUrl + `task_completed/?pk=${id}`, data,
                    {
                        headers: {
                            "Content-type": "application/json",
                            "Authorization": `Token ${token}`
                        },
                    })
                    .then(function (response) {
                        reload()
                    })
                    .catch(function (error) {
                        if (error.request.status === 400) {
                        }
                    })
                    .finally(function () {
                        document.getElementById(modalId).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center hidden'

                        document.getElementById(btnId).innerHTML = 'Submit'
                    });
            } catch (error) {
                console.log(error)
            }
        }
    };


    async function submitNotify() {
        try {
            await axios.patch(ApiUrl + `notification_system/`, {},
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    },
                })
                .then(function (response) {
                })
                .catch(function (error) {
                })
                .finally(function () {
                });
        } catch (error) {
            console.log(error)
        }
    }

    async function submitReview(id, rating, review, modalId, btnId) {

        try {
            document.getElementById(btnId).innerHTML = 'Loading';

            await axios.post(ApiUrl + `influencer_review/`,
                {
                    'influencer': id,
                    'star': rating,
                    'review': review,
                },
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    },
                })
                .then(function (response) {
                    reload()
                })
                .catch(function (error) {
                    if (error.request.status === 400) {
                    }
                })
                .finally(function () {
                    document.getElementById(modalId).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center hidden'

                    document.getElementById(btnId).innerHTML = 'Submit'
                });
        } catch (error) {
            console.log(error)
        }
    };

    const setJob = (event) => {
        setSelectedJob(event.id)
    }

    const setInfluencer = (event) => {
        console.log(event)
        setSelectedInfluencer({ 'influencer': event.id })
    }

    const setContract = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setContractData(values => ({ ...values, [name]: value }))
        setContractData(prevState => ({ ...prevState, 'contract_sent': true }))
    }

    const ratingChanged = (newRating) => {
        setInfluencerRating(newRating)
    };

    const handleReviewChange = (event) => {
        setInfluencerReview(event.target.value)
    }

    const handleNoteChange = (event) => {
        setInfluencerNote({ 'influencer_notes': event.target.value, 'startup_done_task_approval': 0, 'influencer_done_task': 1 })
    }

    const handleUserDetails = (id) => {
        secureLocalStorage.setItem('userid', id);
        secureLocalStorage.setItem('influencer_connect', 0);
        window.open('/show-user/', '_blank');
    }

    const openModal = (modalId) => {
        console.log(modalId)
        document.getElementById(modalId).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80'
    }

    const closeModal = (modalId) => {
        console.log(modalId)
        document.getElementById(modalId).className = 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center hidden'
    }

    // useEffect(()=>{
    //     console.log(response)
    //     if(response !== null){
    //         if (role == 1) {
    //             setStartupId(response.startup)
    //         } else if (role == 2) {
    //             setStartupId(response.influencer)
    //         }
    //     }
    // },[response])

    useEffect(() => {
        getStartupJob()
        getUserConnection()
        submitNotify()
        // getInfluencerPoints()
    }, [])

    useEffect(() => {
    }, [selectedJob])

    useEffect(() => {
        if (!profileCompleted) {
            if (role == 1) {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/influencer/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            } else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/business/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }
        }
    }, [])

    return (
        <>
            <DashboardHeader />
            <Sidebar />
            <div className='sm:ml-64 ml-0 mt-20 pb-10' id='task-center'>
                <div className='container mx-auto h-full'>
                    <div className="relative flex flex-col overflow-hidden max-h-full">
                        <h2 className="py-4 text-2xl font-bold">Task Centre</h2>
                        {role === 1 ?
                            // INFLUENCER TASK CENTER STARTS HERE
                            <>
                                {/* <button data-modal-target="pointsModal" data-modal-toggle="pointsModal" className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg text-sm px-5 py-2.5 text-center w-1/6" type="button">
                            Your Points - {points}<br/>
                            <small>Click to know more information</small>
                        </button> */}
                                {/* <div id="pointsModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                            <div className="relative w-full h-full max-w-2xl md:h-auto">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <div className="flex items-start justify-between p-4 border-b rounded-t">
                                        <h3 className="text-xl font-semibold text-gray-900">
                                        Points Center
                                        </h3>
                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="pointsModal">
                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                    <div className="p-6 space-y-6">
                                        <h4 className='font-bold text-center text-lg'>
                                            Complete the task below to redeem points
                                        </h4>
                                        <p>
                                            You can redeem 1000 points by promoting promote, while promoting kindly tag @promote_io and use the #YOUR_NAME_PROMOTE.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                <div className='rounded-lg mt-2'>
                                    <div className='grid sm:grid-cols-4 grid-cols-1 gap-4'>
                                        {response !== null ?
                                            response.length > 0 ?
                                                (response?.map(task => (
                                                    <>
                                                        <div className='text-center p-4 rounded-lg bg-white bg-white border'>
                                                            <h4 className='font-bold'>{task.task_info[0].title}</h4>
                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            <div className='bg-white rounded-lg border border-primary influencer-card'>
                                                                <div className=' flex flex-col justify-center items-center rounded-lg p-2'>
                                                                    <div className='grid grid-cols-2 gap-2'>
                                                                        <div className='flex justify-center'>
                                                                            <div className=' profile-picture-chat rounded-full'>
                                                                                <img src={task?.startup_info[0]?.logo ? profileImageLink + task?.startup_info[0]?.logo : 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png'} alt={(task?.influencer_info[0]?.user__first_name ? task?.influencer_info[0]?.user__first_name : task?.influencer_info[0]?.user__username) + " image"} className='' loading='lazy' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='h-full flex items-center '>
                                                                            {task?.startup_info[0]?.user__first_name ? task?.startup_info[0]?.user__first_name : task?.startup_info[0]?.user__username}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            {task.contract_sent ?
                                                                (task.influencer_approval !== 0 ?
                                                                    <button className="block text-white bg-secondary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button">
                                                                        <i className="fa-solid fa-check text-green-500"></i>&nbsp;&nbsp;Contract Approved
                                                                    </button>
                                                                    :
                                                                    <>
                                                                        <button className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" onClick={() => { openModal("contractTaskCenterModal" + task.id) }} type="button">
                                                                            <i className="fa-solid fa-file"></i>&nbsp;&nbsp;Check Contract
                                                                        </button>
                                                                        <div id={"contractTaskCenterModal" + task.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                                            <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                                                    <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                                        <h3 className="text-xl font-semibold text-gray-900">
                                                                                            Check Contract
                                                                                        </h3>
                                                                                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => { closeModal("contractTaskCenterModal" + task.id) }}>
                                                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                                            <span className="sr-only">Close modal</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="p-6 space-y-6">
                                                                                        <h4 className='font-bold text-center text-lg'>
                                                                                            Check the contract you want to send
                                                                                        </h4>
                                                                                        <div className='contract-box border p-4'>
                                                                                            <div className='contract-text'>
                                                                                                <div className='flex justify-center'>
                                                                                                    <img src={logo} alt="Promote logo" className='logo' />
                                                                                                </div>
                                                                                                <div className='text-start'>
                                                                                                    <p className='underline text-center mt-4'>
                                                                                                        Marketing Agreement
                                                                                                    </p>
                                                                                                    <p className='mt-5'>
                                                                                                        This Marketing Agreement (“Agreement”) is entered into on  {new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() + ''} by and between <b>{task?.startup_info[0]?.user__username ? task?.startup_info[0]?.user__username : task?.startup_info[0]?.user__first_name} (“Client”)</b> and <b>{task?.influencer_info[0]?.user__first_name ? task?.influencer_info[0]?.user__first_name : task?.influencer_info[0]?.user__username} (“Influencer”)</b> (collectively referred to as the “Parties”).
                                                                                                    </p>
                                                                                                    <div className='mt-5'>
                                                                                                        <b>GOODS AND/OR PAYMENT PROVIDED</b>
                                                                                                        <ol className='pl-4 mt-2 space-y-1 list-decimal list-inside'>
                                                                                                            <li className='mt-2'>
                                                                                                                Goods: {task?.contract_field1}

                                                                                                            </li>
                                                                                                            <li className='mt-2'>
                                                                                                                Payment: £{task?.contract_field2}

                                                                                                            </li>
                                                                                                        </ol>
                                                                                                    </div>
                                                                                                    <div className='mt-5'>
                                                                                                        <b>SERVICE PROVIDED</b>
                                                                                                        <ol className='pl-4 mt-2 space-y-1 list-decimal list-inside'>
                                                                                                            <li className='mt-2'>
                                                                                                                The Influencer agrees to post (Please include the type of post and what it shall feature): {task.contract_field3}
                                                                                                            </li>
                                                                                                            <li className='mt-2'>
                                                                                                                The Influencer agrees to post on (Please specify which social media platforms): {task.contract_field4}
                                                                                                            </li>
                                                                                                            <li className='mt-2'>
                                                                                                                The Influencer agrees that in every post they will tag the website and the Businesses social media account.
                                                                                                            </li>
                                                                                                            <li className='mt-2'>
                                                                                                                The Influencer agrees that in every post they will tag the social media account for Promote.io. (Instagram: promote_io, TikTok: promote.io, LinkedIn: promote.io)
                                                                                                            </li>
                                                                                                        </ol>
                                                                                                        <p className='mt-2'>The Parties agree that the initial posts must be posted within a week of receipt of the goods and/or payment provided.</p>
                                                                                                    </div>
                                                                                                    <div className='mt-5'>
                                                                                                        <b>COMPENSATION</b>
                                                                                                        <p className='mt-2'>The Parties agree that the compensation for the posts listed above will be the goods and/or payment provided.</p>
                                                                                                    </div>
                                                                                                    <div className='mt-5'>
                                                                                                        <b>TERM</b>
                                                                                                        <p className='mt-2'>This Agreement shall be effective on the date of signing and shall be valid for one calendar year.</p>
                                                                                                    </div>
                                                                                                    <div className='mt-5'>
                                                                                                        <b>CONFIDENTIALITY</b>
                                                                                                        <p className='mt-2'>All terms and conditions of this Agreement (and any confidential information provided by the Client to the Influencer or vice versa) during the term of the Agreement must be kept confidential, unless the disclosure is required pursuant to process of law.</p>
                                                                                                    </div>
                                                                                                    <div className='mt-5'>
                                                                                                        <b>RELATIONSHIP BETWEEN PARTIES</b>
                                                                                                        <ol className='pl-4 mt-2 space-y-1 list-decimal list-inside'>
                                                                                                            <li>The Parties agree that the Influencer in this Agreement is an independent contractor and no agency arrangement or agreement is created.</li>
                                                                                                            <li className='mt-2'>The Influencer shall not be considered an employee or a partner under any circumstances.</li>
                                                                                                        </ol>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <button className='bg-green-500 text-white p-2 rounded-lg btn-sm w-full' id={"contractTaskCenterApproveBtn" + task.id} onClick={(e) => { submitTask(influencerApprove, task.id, 0, "contractTaskCenterModal" + task.id, "contractTaskCenterApproveBtn" + task.id, task.startup) }}>
                                                                                                Approve the Contract
                                                                                            </button>
                                                                                            <button className='bg-red-700 text-white p-2 mt-2 rounded-lg btn-sm w-full' id={"contractTaskCenterRejectBtn" + task.id} onClick={(e) => { submitTask(influencerReject, task.id, 0, "contractTaskCenterModal" + task.id, "contractTaskCenterRejectBtn" + task.id, task.startup) }}>
                                                                                                Reject the Contract
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                <button className="block text-white bg-secondary text-sm font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                    <i className="fa-solid fa-hourglass-start"></i>&nbsp;&nbsp; Business creating a contract
                                                                </button>
                                                            }
                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            {task.influencer_approval !== 0 ?
                                                                (task.payment_by_startup !== 0 ?
                                                                    <button className="block text-white bg-secondary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button">
                                                                        <i className="fa-solid fa-check text-green-500"></i>&nbsp;&nbsp;Payment Sent
                                                                    </button>
                                                                    :
                                                                    <button className="block text-white bg-primary text-sm font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                        <i className="fa-solid fa-hourglass-start"></i>&nbsp;&nbsp; Waiting for Business Payment
                                                                    </button>
                                                                )
                                                                :
                                                                <button className="block text-white bg-third text-gray-400 text-sm font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                    <i className="fa-solid fa-file-invoice-dollar"></i>&nbsp;&nbsp; Business Payment
                                                                </button>
                                                            }

                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            {task.payment_by_startup !== 0 ?
                                                                (task.promote_payment_approval !== 0 ?
                                                                    (task.influencer_done_task !== 0 ?
                                                                        task.startup_done_task_approval !== 3 ?
                                                                            <>
                                                                                <button className="block text-white bg-secondary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button">
                                                                                    <i className="fa-solid fa-check text-green-500"></i>&nbsp;&nbsp; Task done and approved
                                                                                </button>
                                                                                <hr className="h-px my-4 bg-gray-200 border-0" />
                                                                                <div>
                                                                                    <button className="block text-white bg-red-500 text-gray-400 font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" onClick={() => { deleteTask(task.id) }}>
                                                                                        Delete this Task
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <button className="block text-white bg-secondary text-sm font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                                <i className="fa-solid fa-hourglass-start"></i>&nbsp;&nbsp;Business reviewing your task
                                                                            </button>
                                                                        :
                                                                        (task.startup_done_task_approval !== 0 ?
                                                                            (task.startup_done_task_approval !== 3 ?
                                                                                <button className="block text-white bg-secondary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button">
                                                                                    <i className="fa-solid fa-check text-green-500"></i>&nbsp;&nbsp; Task done
                                                                                </button>
                                                                                :
                                                                                <>
                                                                                    <button className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button" onClick={() => { openModal("postTaskCenterModal" + task.id) }}>
                                                                                        <i className="fa-solid fa-list-check"></i>&nbsp;&nbsp;Influencer Task
                                                                                    </button>
                                                                                    <div id={"postTaskCenterModal" + task.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                                                        <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                                                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                                                                <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                                                    <h3 className="text-xl font-semibold text-gray-900">
                                                                                                        Your Tasks
                                                                                                    </h3>
                                                                                                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide={"postTaskCenterModal" + task.id}>
                                                                                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                                                        <span className="sr-only">Close modal</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="p-6 space-y-6">
                                                                                                    <h4 className='font-bold text-center text-lg'>
                                                                                                        Your Previous was task was rejected by the Business
                                                                                                    </h4>
                                                                                                    <h4>
                                                                                                        Let them know what is different this time.
                                                                                                    </h4>
                                                                                                    <div className='border p-4'>
                                                                                                        <div className=' text-start'>
                                                                                                            <textarea cols="30" rows="5" id="review" name="review" onChange={(e) => { handleNoteChange(e) }} className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary"></textarea>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <button className='bg-secondary text-white p-2 rounded-lg btn-sm w-full' id={"postTaskCenterBtn" + task.id} onClick={(e) => { submitTask(influencerNote, task.id, 0, "postTaskCenterModal" + task.id, "postTaskCenterBtn" + task.id, task.startup) }}>
                                                                                                            I have completed the task
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                            :
                                                                            <>
                                                                                <button className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button" onClick={() => { openModal("postTaskCenterModal" + task.id) }}>
                                                                                    <i className="fa-solid fa-list-check"></i>&nbsp;&nbsp;Influencer Task
                                                                                </button>
                                                                                <div id={"postTaskCenterModal" + task.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                                                    <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                                                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                                                            <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                                                <h3 className="text-xl font-semibold text-gray-900">
                                                                                                    Your Tasks
                                                                                                </h3>
                                                                                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => { closeModal("postTaskCenterModal" + task.id) }}>
                                                                                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                                                    <span className="sr-only">Close modal</span>
                                                                                                </button>
                                                                                            </div>
                                                                                            <div className="p-6 space-y-6">
                                                                                                <h4 className='font-bold text-center text-lg'>
                                                                                                    Write up anything you want to say to the Business
                                                                                                </h4>
                                                                                                <div className='border p-4'>
                                                                                                    <div className=' text-start'>
                                                                                                        <textarea cols="30" rows="5" id="review" name="review" onChange={(e) => { handleNoteChange(e) }} className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary"></textarea>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <button className='bg-secondary text-white p-2 rounded-lg btn-sm w-full' id={"postTaskCenterBtn" + task.id} onClick={(e) => { submitTask(influencerNote, task.id, 0, "postTaskCenterModal" + task.id, "postTaskCenterBtn" + task.id, task.startup) }}>
                                                                                                        I have completed the task
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    )
                                                                    :
                                                                    <button className="block text-white bg-secondary text-sm font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                        <i className="fa-solid fa-hourglass-start"></i>&nbsp;&nbsp;Promote processing payment
                                                                    </button>
                                                                )
                                                                :
                                                                <button className="block text-white bg-third text-gray-400 cursor-not-allowed hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                    <i className="fa-solid fa-list-check"></i>&nbsp;&nbsp;Influencer Task
                                                                </button>
                                                            }
                                                        </div>
                                                    </>
                                                )))
                                                :
                                                <div className='col-span-4'>
                                                    Please wait for a business to add you to the their task centre
                                                </div>
                                            :
                                            <div className='col-span-4'>
                                                Please wait for a business to add you to the their task centre
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>
                            // INFLUENCER TASK CENTER ENDS HERE
                            :
                            // BUSINESS TASK CENTER STARTS HERE
                            <>
                                <button className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg text-sm px-5 py-2.5 text-center sm:w-1/6 w-full" type="button" onClick={() => { openModal('taskCenterModal') }}>
                                    <i className="fa-solid fa-plus"></i> Create a New Task Center
                                </button>
                                <div id="taskCenterModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                    <div className="relative w-full h-full max-w-2xl md:h-auto">
                                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                            <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                <h3 className="text-xl font-semibold text-gray-900">
                                                    Create a new Task Center
                                                </h3>
                                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="taskCenterModal" onClick={() => { closeModal('taskCenterModal') }}>
                                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                    <span className="sr-only">Close modal</span>
                                                </button>
                                            </div>
                                            <div className="p-6 space-y-6">
                                                <h4 className='font-bold text-center text-lg'>
                                                    Select the job you want to start the task center for?
                                                </h4>
                                                <Select
                                                    id="select"
                                                    options={data}
                                                    // value={selectedJob}
                                                    // menuPortalTarget={document.body}
                                                    onChange={(e) => { setSelectedJob(e); setJob(e) }}
                                                    isClearable
                                                    styles={{
                                                        input: (provided) => ({
                                                            ...provided,
                                                            color: 'black',
                                                            boxShadow: "none",
                                                            outline: "none",
                                                            ':hover': {
                                                                '--tw-ring-color': 'black !important'
                                                            },
                                                            ':focus': {
                                                                '--tw-ring-color': 'black !important'
                                                            },
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            border: '1px solid black',
                                                            ':hover': {
                                                                border: '1px solid #008e95 !important'
                                                            },
                                                            boxShadow: "none",
                                                            outline: "none"
                                                        }),

                                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                            const color = chroma('#008e95');
                                                            return {
                                                                ...styles,
                                                                backgroundColor: isDisabled
                                                                    ? undefined
                                                                    : isSelected
                                                                        ? data.color
                                                                        : isFocused
                                                                            ? color.alpha(0.1).css()
                                                                            : undefined,
                                                                ':active': {
                                                                    ...styles[':active'],
                                                                    backgroundColor: !isDisabled
                                                                        ? isSelected
                                                                            ? color
                                                                            : color.alpha(0.3).css()
                                                                        : undefined,
                                                                },
                                                            };
                                                        }
                                                    }}
                                                />
                                                <button className='bg-secondary text-white p-2 rounded-lg btn-sm w-full' id='createTaskBtn' onClick={(e) => { createTask('taskCenterModal', 'createTaskBtn') }}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='rounded-lg p-4 mt-4'>
                                    <div className='grid sm:grid-cols-4 grid-cols-1 gap-4'>
                                        {response ?
                                            response.length > 0 ?
                                                response?.map(task => (
                                                    <>
                                                        <div className='text-center p-4 rounded-lg bg-white bg-white border'>
                                                            <h4 className='font-bold'>{task.task_info[0].title}</h4>
                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            {task.influencer !== null ?
                                                                <div className='bg-white rounded-lg border border-primary influencer-card'>
                                                                    <div className=' flex flex-col justify-center items-center rounded-lg p-2'>
                                                                        <div className="flex justify-center items-center cursor-pointer" onClick={() => { handleUserDetails(task?.influencer); secureLocalStorage.setItem("profile_view", 1) }}>
                                                                            <div className='flex justify-center'>
                                                                                <div className=' profile-picture-chat rounded-full mr-4'>
                                                                                    <img src={task?.influencer_info[0]?.profile_image ? profileImageLink + task?.influencer_info[0]?.profile_image : 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png'} alt={(task?.influencer_info[0]?.user__first_name ? task?.influencer_info[0]?.user__first_name : task?.influencer_info[0]?.user__username) + " image"} className='' loading='lazy' />
                                                                                </div>
                                                                            </div>
                                                                            <div className='h-full flex items-center '>
                                                                                {task?.influencer_info[0]?.user__first_name ? task?.influencer_info[0]?.user__first_name : task?.influencer_info[0]?.user__username}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                    <button className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button" onClick={() => { openModal("influencerTaskCenterModal" + task.id) }}>
                                                                        <i className="fa-solid fa-user"></i>&nbsp;&nbsp;Add an influencer
                                                                    </button>
                                                                    <div id={"influencerTaskCenterModal" + task.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                                        <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                                                <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                                    <h3 className="text-xl font-semibold text-gray-900">
                                                                                        Add an influencer
                                                                                    </h3>
                                                                                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => { closeModal("influencerTaskCenterModal" + task.id) }}>
                                                                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                                        <span className="sr-only">Close modal</span>
                                                                                    </button>
                                                                                </div>
                                                                                <div className="p-6 space-y-6">
                                                                                    <h4 className='font-bold text-center text-lg'>
                                                                                        Select the influencer you want for this job
                                                                                    </h4>
                                                                                    <Select
                                                                                        id="select"
                                                                                        options={connection}
                                                                                        // value={selectedJob}
                                                                                        // menuPortalTarget={document.body}
                                                                                        onChange={(e) => { setSelectedInfluencer(e); setInfluencer(e) }}
                                                                                        isClearable
                                                                                        styles={{
                                                                                            input: (provided) => ({
                                                                                                ...provided,
                                                                                                color: 'black',
                                                                                                boxShadow: "none",
                                                                                                outline: "none",
                                                                                                ':hover': {
                                                                                                    '--tw-ring-color': 'black !important'
                                                                                                },
                                                                                                ':focus': {
                                                                                                    '--tw-ring-color': 'black !important'
                                                                                                },
                                                                                            }),
                                                                                            control: (provided) => ({
                                                                                                ...provided,
                                                                                                border: '1px solid black',
                                                                                                ':hover': {
                                                                                                    border: '1px solid #008e95 !important'
                                                                                                },
                                                                                                boxShadow: "none",
                                                                                                outline: "none"
                                                                                            }),

                                                                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                                                                const color = chroma('#008e95');
                                                                                                return {
                                                                                                    ...styles,
                                                                                                    backgroundColor: isDisabled
                                                                                                        ? undefined
                                                                                                        : isSelected
                                                                                                            ? data.color
                                                                                                            : isFocused
                                                                                                                ? color.alpha(0.1).css()
                                                                                                                : undefined,
                                                                                                    ':active': {
                                                                                                        ...styles[':active'],
                                                                                                        backgroundColor: !isDisabled
                                                                                                            ? isSelected
                                                                                                                ? color
                                                                                                                : color.alpha(0.3).css()
                                                                                                            : undefined,
                                                                                                    },
                                                                                                };
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <button className='bg-secondary text-white p-2 rounded-lg btn-sm w-full' id={"influencerTaskCenterBtn" + task.id} onClick={(e) => { submitTask(selectedInfluencer, task.id, 0, "influencerTaskCenterModal" + task.id, "influencerTaskCenterBtn" + task.id) }}>
                                                                                        Submit
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>

                                                            }
                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            {task.influencer !== null ?
                                                                <>
                                                                    {task.contract_sent ?
                                                                        (task.influencer_approval !== 3 ?
                                                                            <button className="block text-white bg-secondary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button">
                                                                                <i className="fa-solid fa-check text-green-500"></i>&nbsp;&nbsp;Contract Sent
                                                                            </button>
                                                                            :
                                                                            <>
                                                                                <button className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button" onClick={() => { openModal("contractTaskCenterModal" + task.id) }}>
                                                                                    <i className="fa-solid fa-file"></i>&nbsp;&nbsp;Check Contract
                                                                                </button>
                                                                                <div id={"contractTaskCenterModal" + task.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                                                    <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                                                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                                                            <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                                                <h3 className="text-xl font-semibold text-gray-900">
                                                                                                    Check Contract
                                                                                                </h3>
                                                                                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => { closeModal("contractTaskCenterModal" + task.id) }}>
                                                                                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                                                    <span className="sr-only">Close modal</span>
                                                                                                </button>
                                                                                            </div>
                                                                                            <div className="p-6 space-y-6">
                                                                                                <h4 className='font-bold text-center text-lg'>
                                                                                                    Your Previous Contract was rejected
                                                                                                </h4>
                                                                                                <h4>
                                                                                                    Make sure to change this time.
                                                                                                </h4>
                                                                                                <div className='contract-box border p-4'>
                                                                                                    <div className='contract-text'>
                                                                                                        <div className='flex justify-center'>
                                                                                                            <img src={logo} alt="Promote logo" className='logo' />
                                                                                                        </div>
                                                                                                        <div className='text-start'>
                                                                                                            <p className='underline text-center mt-4'>
                                                                                                                Marketing Agreement
                                                                                                            </p>
                                                                                                            <p className='mt-5'>
                                                                                                                This Marketing Agreement (“Agreement”) is entered into on  {new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() + ''} by and between <b>{task?.startup_info[0]?.user__username ? task?.startup_info[0]?.user__username : task?.startup_info[0]?.user__first_name} (“Client”)</b> and <b>{task?.influencer_info[0]?.user__first_name ? task?.influencer_info[0]?.user__first_name : task?.influencer_info[0]?.user__username} (“Influencer”)</b> (collectively referred to as the “Parties”).
                                                                                                            </p>
                                                                                                            <div className='mt-5'>
                                                                                                                <b>GOODS AND/OR PAYMENT PROVIDED</b>
                                                                                                                <ol className='pl-4 mt-2 space-y-1 list-decimal list-inside'>
                                                                                                                    <li className='mt-2'>
                                                                                                                        Goods:
                                                                                                                        <input type="text" id="search" name='contract_field1' className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" onChange={setContract} placeholder="Type your goods eg: Product" />
                                                                                                                    </li>
                                                                                                                    <li className='mt-2'>
                                                                                                                        Payment:
                                                                                                                        <input type="text" id="search" name='contract_field2' className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" onChange={setContract} placeholder="Type your payment eg: £20" />
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                            </div>
                                                                                                            <div className='mt-5'>
                                                                                                                <b>SERVICE PROVIDED</b>
                                                                                                                <ol className='pl-4 mt-2 space-y-1 list-decimal list-inside'>
                                                                                                                    <li className='mt-2'>
                                                                                                                        The Influencer agrees to post (Please include the type of post and what it shall feature): <input type="text" id="search" name='contract_field3' onChange={setContract} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" placeholder="Eg: 1 Reel, 1 Post" />
                                                                                                                    </li>
                                                                                                                    <li className='mt-2'>
                                                                                                                        The Influencer agrees to post on (Please specify which social media platforms): <input type="text" id="search" name='contract_field4' onChange={setContract} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" placeholder="Eg: Instagram, Facebook" />
                                                                                                                    </li>
                                                                                                                    <li className='mt-2'>
                                                                                                                        The Influencer agrees that in every post they will tag the website and the Businesses social media account.
                                                                                                                    </li>
                                                                                                                    <li className='mt-2'>
                                                                                                                        The Influencer agrees that in every post they will tag the social media account for Promote.io. (Instagram: promote_io, TikTok: promote.io, LinkedIn: promote.io)
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                <p className='mt-2'>The Parties agree that the initial posts must be posted within a week of receipt of the goods and/or payment provided.</p>
                                                                                                            </div>
                                                                                                            <div className='mt-5'>
                                                                                                                <b>COMPENSATION</b>
                                                                                                                <p className='mt-2'>The Parties agree that the compensation for the posts listed above will be the goods and/or payment provided.</p>
                                                                                                            </div>
                                                                                                            <div className='mt-5'>
                                                                                                                <b>TERM</b>
                                                                                                                <p className='mt-2'>This Agreement shall be effective on the date of signing and shall be valid for one calendar year.</p>
                                                                                                            </div>
                                                                                                            <div className='mt-5'>
                                                                                                                <b>CONFIDENTIALITY</b>
                                                                                                                <p className='mt-2'>All terms and conditions of this Agreement (and any confidential information provided by the Client to the Influencer or vice versa) during the term of the Agreement must be kept confidential, unless the disclosure is required pursuant to process of law.</p>
                                                                                                            </div>
                                                                                                            <div className='mt-5'>
                                                                                                                <b>RELATIONSHIP BETWEEN PARTIES</b>
                                                                                                                <ol className='pl-4 mt-2 space-y-1 list-decimal list-inside'>
                                                                                                                    <li>The Parties agree that the Influencer in this Agreement is an independent contractor and no agency arrangement or agreement is created.</li>
                                                                                                                    <li className='mt-2'>The Influencer shall not be considered an employee or a partner under any circumstances.</li>
                                                                                                                </ol>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <button className='bg-secondary text-white p-2 rounded-lg btn-sm w-full' id={"contractTaskCenterBtn" + task.id} onClick={(e) => { submitTask(contractData, task.id, 1, "contractTaskCenterModal" + task.id, "contractTaskCenterBtn" + task.id, task.influencer) }}>
                                                                                                    Send
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        :
                                                                        <>
                                                                            <button className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button" onClick={() => { openModal("contractTaskCenterModal" + task.id) }}>
                                                                                <i className="fa-solid fa-file"></i>&nbsp;&nbsp;Check Contract
                                                                            </button>
                                                                            <div id={"contractTaskCenterModal" + task.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                                                <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                                                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                                                        <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                                            <h3 className="text-xl font-semibold text-gray-900">
                                                                                                Check Contract
                                                                                            </h3>
                                                                                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => { closeModal("contractTaskCenterModal" + task.id) }}>
                                                                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                                                <span className="sr-only">Close modal</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="p-6 space-y-6">
                                                                                            <h4 className='font-bold text-center text-lg'>
                                                                                                Check the contract you want to send
                                                                                            </h4>
                                                                                            <div className='contract-box border p-4'>
                                                                                                <div className='contract-text'>
                                                                                                    <div className='flex justify-center'>
                                                                                                        <img src={logo} alt="Promote logo" className='logo' />
                                                                                                    </div>
                                                                                                    <div className='text-start'>
                                                                                                        <p className='underline text-center mt-4'>
                                                                                                            Marketing Agreement
                                                                                                        </p>
                                                                                                        <p className='mt-5'>
                                                                                                            This Marketing Agreement (“Agreement”) is entered into on  {new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() + ''} by and between <b>{task?.startup_info[0]?.user__username ? task?.startup_info[0]?.user__username : task?.startup_info[0]?.user__first_name} (“Client”)</b> and <b>{task?.influencer_info[0]?.user__first_name ? task?.influencer_info[0]?.user__first_name : task?.influencer_info[0]?.user__username} (“Influencer”)</b> (collectively referred to as the “Parties”).
                                                                                                        </p>
                                                                                                        <div className='mt-5'>
                                                                                                            <b>GOODS AND/OR PAYMENT PROVIDED</b>
                                                                                                            <ol className='pl-4 mt-2 space-y-1 list-decimal list-inside'>
                                                                                                                <li className='mt-2'>
                                                                                                                    Goods:
                                                                                                                    <input type="text" id="search" name='contract_field1' className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" onChange={setContract} placeholder="Type your goods eg: Product" />
                                                                                                                </li>
                                                                                                                <li className='mt-2'>
                                                                                                                    Payment:
                                                                                                                    <input type="text" id="search" name='contract_field2' className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" onChange={setContract} placeholder="Type your payment eg: £20" />
                                                                                                                </li>
                                                                                                            </ol>
                                                                                                        </div>
                                                                                                        <div className='mt-5'>
                                                                                                            <b>SERVICE PROVIDED</b>
                                                                                                            <ol className='pl-4 mt-2 space-y-1 list-decimal list-inside'>
                                                                                                                <li className='mt-2'>
                                                                                                                    The Influencer agrees to post (Please include the type of post and what it shall feature): <input type="text" id="search" name='contract_field3' onChange={setContract} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" placeholder="Eg: 1 Reel, 1 Post" />
                                                                                                                </li>
                                                                                                                <li className='mt-2'>
                                                                                                                    The Influencer agrees to post on (Please specify which social media platforms): <input type="text" id="search" name='contract_field4' onChange={setContract} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" placeholder="Eg: Instagram, Facebook" />
                                                                                                                </li>
                                                                                                                <li className='mt-2'>
                                                                                                                    The Influencer agrees that in every post they will tag the website and the Businesses social media account.
                                                                                                                </li>
                                                                                                                <li className='mt-2'>
                                                                                                                    The Influencer agrees that in every post they will tag the social media account for Promote.io. (Instagram: promote_io, TikTok: promote.io, LinkedIn: promote.io)
                                                                                                                </li>
                                                                                                            </ol>
                                                                                                            <p className='mt-2'>The Parties agree that the initial posts must be posted within a week of receipt of the goods and/or payment provided.</p>
                                                                                                        </div>
                                                                                                        <div className='mt-5'>
                                                                                                            <b>COMPENSATION</b>
                                                                                                            <p className='mt-2'>The Parties agree that the compensation for the posts listed above will be the goods and/or payment provided.</p>
                                                                                                        </div>
                                                                                                        <div className='mt-5'>
                                                                                                            <b>TERM</b>
                                                                                                            <p className='mt-2'>This Agreement shall be effective on the date of signing and shall be valid for one calendar year.</p>
                                                                                                        </div>
                                                                                                        <div className='mt-5'>
                                                                                                            <b>CONFIDENTIALITY</b>
                                                                                                            <p className='mt-2'>All terms and conditions of this Agreement (and any confidential information provided by the Client to the Influencer or vice versa) during the term of the Agreement must be kept confidential, unless the disclosure is required pursuant to process of law.</p>
                                                                                                        </div>
                                                                                                        <div className='mt-5'>
                                                                                                            <b>RELATIONSHIP BETWEEN PARTIES</b>
                                                                                                            <ol className='pl-4 mt-2 space-y-1 list-decimal list-inside'>
                                                                                                                <li>The Parties agree that the Influencer in this Agreement is an independent contractor and no agency arrangement or agreement is created.</li>
                                                                                                                <li className='mt-2'>The Influencer shall not be considered an employee or a partner under any circumstances.</li>
                                                                                                            </ol>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <button className='bg-secondary text-white p-2 rounded-lg btn-sm w-full' id={"contractTaskCenterBtn" + task.id} onClick={(e) => { submitTask(contractData, task.id, 1, "contractTaskCenterModal" + task.id, "contractTaskCenterBtn" + task.id, task.influencer) }}>
                                                                                                Send
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                                :
                                                                <button className=" block text-white bg-third cursor-not-allowed text-gray-400 font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                    <i className="fa-solid fa-file"></i>&nbsp;&nbsp;Check Contract
                                                                </button>
                                                            }
                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            {task.contract_sent ?
                                                                (task.influencer_approval !== 0 ?
                                                                    (task.payment_by_startup !== 0 ?
                                                                        <button className="block text-white bg-secondary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button">
                                                                            <i className="fa-solid fa-check text-green-500"></i>&nbsp;&nbsp;Payment Sent
                                                                        </button>
                                                                        :
                                                                        <>
                                                                            <button className=" block text-white bg-primary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button" onClick={() => { openModal("paymentTaskCenterModal" + task.id) }}>
                                                                                <i className="fa-solid fa-file-invoice-dollar"></i>&nbsp;&nbsp;Payment
                                                                            </button>
                                                                            <div id={"paymentTaskCenterModal" + task.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                                                <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                                                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                                                        <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                                            <h3 className="text-xl font-semibold text-gray-900">
                                                                                                Payment
                                                                                            </h3>
                                                                                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => { closeModal("paymentTaskCenterModal" + task.id) }}>
                                                                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                                                <span className="sr-only">Close modal</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="p-6 space-y-6">
                                                                                            <h4 className='font-bold text-left text-lg'>
                                                                                                Please do the bank transfer to bank address given below
                                                                                            </h4>
                                                                                            <ul className='text-left'>
                                                                                                <li><b>Account Name</b>: Reach.io</li>
                                                                                                <li><b>Bank Name</b>: Lloyds Bank</li>
                                                                                                <li><b>Account Number</b>: 63204362</li>
                                                                                                <li><b>Sort Code</b>: 30-98-97</li>
                                                                                                <li><b>BIC</b>: LOYDGB21031</li>
                                                                                                <li><b>IBAN</b>: GB45 LOYD 30989763204362</li>
                                                                                                <li><b>REF</b>: (Your Company Name)</li>
                                                                                            </ul>
                                                                                            <button className='bg-secondary text-white p-2 rounded-lg btn-sm w-full' id={"paymentTaskCenterBtn" + task.id} onClick={(e) => { submitTask(paymentDone, task.id, 0, "paymentTaskCenterModal" + task.id, "paymentTaskCenterBtn" + task.id, task.influencer) }}>
                                                                                                Sent the Payment
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                    :
                                                                    <button className="block text-white bg-secondary text-sm font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                        <i className="fa-solid fa-hourglass-start"></i>&nbsp;&nbsp; Influencer reviewing contract
                                                                    </button>
                                                                ) :
                                                                <button className="block text-white bg-third text-gray-400 cursor-not-allowed hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                    <i className="fa-solid fa-file-invoice-dollar"></i>&nbsp;&nbsp;Payment
                                                                </button>
                                                            }

                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            {task.payment_by_startup !== 0 ?
                                                                (task.promote_payment_approval !== 0 ?
                                                                    (task.influencer_done_task !== 0 ?
                                                                        (task.startup_done_task_approval !== 0 ?
                                                                            (task.startup_done_task_approval !== 3 ?
                                                                                <button className="block text-white bg-secondary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button">
                                                                                    <i className="fa-solid fa-check text-green-500"></i>&nbsp;&nbsp; Task done and approved
                                                                                </button>
                                                                                :
                                                                                <button className="block text-white bg-secondary hover:bg-secondary font-medium rounded-lg text-sm shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button">
                                                                                    <a href={'/messages?name=' + (task?.influencer_info[0]?.user__first_name ? task?.influencer_info[0]?.user__first_name : task?.influencer_info[0]?.user__username).replace(' ', '_')}>
                                                                                        <i className="fa-solid fa-ban text-red-600"></i>&nbsp;&nbsp; Click to message the influencer
                                                                                    </a>
                                                                                </button>
                                                                            )
                                                                            :
                                                                            <>
                                                                                <button className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button" onClick={() => { openModal("postTaskCenterModal" + task.id) }}>
                                                                                    <i className="fa-solid fa-list-check"></i>&nbsp;&nbsp;Influencer Task
                                                                                </button>
                                                                                <div id={"postTaskCenterModal" + task.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                                                    <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                                                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                                                            <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                                                <h3 className="text-xl font-semibold text-gray-900">
                                                                                                    Check Influencer task
                                                                                                </h3>
                                                                                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => { closeModal("postTaskCenterModal" + task.id) }}>
                                                                                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                                                    <span className="sr-only">Close modal</span>
                                                                                                </button>
                                                                                            </div>
                                                                                            <div className="p-6 space-y-6">
                                                                                                <h4 className='font-bold text-center text-lg'>
                                                                                                    Check Infuencer's posts
                                                                                                </h4>
                                                                                                <div className='contract-box border p-4'>
                                                                                                    <div className='contract-text text-start'>
                                                                                                        {task.influencer_notes}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <button className='bg-green-500 text-white p-2 rounded-lg btn-sm w-full' id={"postTaskCenterBtnApprove" + task.id} onClick={(e) => { submitTask(startupApprove, task.id, 0, "postTaskCenterModal" + task.id, "postTaskCenterBtnApprove" + task.id, task.influencer) }}>
                                                                                                        Approve the Task Done
                                                                                                    </button>
                                                                                                    <button className='bg-red-700 text-white p-2 mt-2 rounded-lg btn-sm w-full' id={"postTaskCenterBtnReject" + task.id} onClick={(e) => { submitTask(startupReject, task.id, 0, "postTaskCenterModal" + task.id, "postTaskCenterBtnReject" + task.id, task.influencer) }}>
                                                                                                        Reject the Task Done
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        :
                                                                        <button className="block text-white bg-secondary text-sm font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                            <i className="fa-solid fa-hourglass-start"></i>&nbsp;&nbsp;Influencer currently doing the task
                                                                        </button>
                                                                    )
                                                                    :
                                                                    <button className="block text-white bg-secondary text-sm font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                        <i className="fa-solid fa-hourglass-start"></i>&nbsp;&nbsp;Promote processing payment
                                                                    </button>
                                                                )
                                                                :
                                                                <button className="block text-white bg-third text-gray-400 cursor-not-allowed hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                    <i className="fa-solid fa-list-check"></i>&nbsp;&nbsp;Influencer Task
                                                                </button>
                                                            }
                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            {task.startup_done_task_approval !== 0 ?
                                                                <>
                                                                    <button className="block text-white bg-primary hover:bg-secondary font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" type="button" onClick={() => { openModal("reviewTaskCenterModal" + task.id) }}>
                                                                        <i className="fa-solid fa-star"></i>&nbsp;&nbsp;Write a Review
                                                                    </button>
                                                                    <div id={"reviewTaskCenterModal" + task.id} tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                                                                        <div className="relative w-full h-full max-w-2xl md:h-auto">
                                                                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                                                <div className="flex items-start justify-between p-4 border-b rounded-t">
                                                                                    <h3 className="text-xl font-semibold text-gray-900">
                                                                                        Influencer's review
                                                                                    </h3>
                                                                                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => { closeModal("reviewTaskCenterModal" + task.id) }}>
                                                                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                                        <span className="sr-only">Close modal</span>
                                                                                    </button>
                                                                                </div>
                                                                                <div className="p-6 space-y-6">
                                                                                    <h4 className='font-bold text-center text-lg'>
                                                                                        Write Infuencer's Review
                                                                                    </h4>
                                                                                    <div className=' border p-4'>
                                                                                        <div className='text-start'>
                                                                                            <ReactStars
                                                                                                count={5}
                                                                                                onChange={ratingChanged}
                                                                                                size={24}
                                                                                                activeColor="#ffd700"
                                                                                            />
                                                                                            <textarea cols="30" rows="5" id="review" name="review" onChange={(e) => { handleReviewChange(e) }} className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary"></textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <button className='bg-green-500 text-white p-2 rounded-lg btn-sm w-full' id={"reviewTaskCenterBtn" + task.id} onClick={() => { submitReview(task.influencer, influencerRating, influencerReview, "reviewTaskCenterModal" + task.id, "reviewTaskCenterBtn" + task.id) }}>
                                                                                            Submit Review
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </>
                                                                :
                                                                <button className=" block text-white bg-third cursor-not-allowed text-gray-400 font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" disabled type="button">
                                                                    <i className="fa-solid fa-star"></i>&nbsp;&nbsp;Write a Review
                                                                </button>
                                                            }
                                                            <hr className="h-px my-4 bg-gray-200 border-0" />
                                                            <div>
                                                                <button className="block text-white bg-red-500 text-gray-400 font-medium rounded-lg shadow-md px-5 py-2.5 text-center w-full influencer-card" onClick={() => { deleteTask(task.id) }}>
                                                                    Delete this Task
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                :
                                                <div className='col-span-4'>
                                                    No Tasks Centres
                                                </div>
                                            :
                                            <div className='col-span-4'>
                                                No Tasks Centres
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default TaskCenter