import React, { useEffect, useRef, ReactElement } from "react";
import ReactDOM from "react-dom";
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';

function MyMapComponent({
    center,
    zoom,
}) {
    const ref = useRef();

    useEffect(() => {

        var map = new window.google.maps.Map(ref.current, {
            center,
            zoom,
        });

        var marker = new MarkerWithLabel({
            position: center,
            clickable: true,
            draggable: false,
            map: map,
            labelContent: "", // can also be HTMLElement
            labelAnchor: new window.google.maps.Point(-90, 5),
            labelClass: "labels", // the CSS class for the label
            labelStyle: { opacity: 0.8 },
        })

        // const cityCircle = new window.google.maps.Circle({
        //     strokeColor: "#000000",
        //     strokeOpacity: 0.8,
        //     strokeWeight: 2,
        //     fillColor: "#000000",
        //     fillOpacity: 0.35,
        //     map,
        //     center: center,
        //     radius: 5000 * 100,
        //   });
    });

    return <div ref={ref} id="map" ></div>;
} 

export default MyMapComponent