import axios from 'axios';
import { useState, useEffect } from "react";
import { ApiUrl } from './Api.config'
import TokenService from "./token.service";

const ImageChatApi = () => {

    const [responseImage, setResponse] = useState(null);
    const [ isSending, setIsSending] = useState('');

    const token = TokenService.getLocalAccessToken();
    
    const ImageChatUpload = async (data) => {
        setIsSending('sending');
        try {

            await axios.post(ApiUrl + 'chat_image/', data,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response) {
                    // console.log(response.data.image);
                    setResponse(response.data.image);
    
                })
                .catch(function (error) {
                    console.log(error)
                }).finally(()=>{
                    setIsSending('sent');
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
    },[responseImage])
    
    return { responseImage, isSending, ImageChatUpload }
};

export default ImageChatApi