import React from 'react'
import { useForm } from "react-hook-form";
import { useEffect, useState } from 'react'
import Header from "../../components/DashboardHeader/DashboardHeader";
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { ApiUrl } from "../../API/Api.config";
import TokenService from '../../API/token.service';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FacebookLogin from '@greatsumini/react-facebook-login';

// import ReactHintFactory from 'react-hint'
// import 'react-hint/css/index.css'

// const ReactHint = ReactHintFactory(React)


const UpdateSoicalTest = () => {

    // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    // const { error, status, response, isLodaing } = useAxios('https://instagram.com/cxndyce')
    // useEffect(()=>{{console.log(status); console.log(response); console.log(error); console.log(isLodaing)}}, [error, status, response, isLodaing])


    const token = TokenService.getLocalAccessToken()
    const nav = useNavigate()
    const getData = useLocation()
    const role = secureLocalStorage.getItem("role");
    const MySwal = withReactContent(Swal)
    const [instaCode, setInstaCode] = useState("")
    const [dataResponse, setdataResponse] = useState()
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get("code")

    function OpenInstagramUrl() {
        window.open("https://api.instagram.com/oauth/authorize?client_id=948295476479630&redirect_uri=https://dev.promote.io/social-media-test/&scope=user_profile,user_media&response_type=code")
    }

    useEffect(() => { console.log(code) }, [code])



    useEffect(() => { setInstaCode(code); }, [])
    useEffect(() => { SendSocialData(code) }, [instaCode])

    async function SendSocialData(data) {
        if (code.length > 0) {
            await axios.get(ApiUrl + `instagram_integrate?code=${data}`, {
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            })
            .then(response => {
                // setdataResponse(response.data) 
                console.log(response.data)
            }).catch(error => 
                console.log(error))

            console.log('code excuted')
        }


    }


    async function SocialUpdateAPI(data) {
        try {
            await axios.post(ApiUrl + 'update_social/', data,
                {
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        'Authorization': `Token ${token}`
                    }
                })
                .then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your Profile has been updated',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                    setTimeout(function () {
                        nav('/user-info');
                    }, 3000);
                })
                .catch(function (error) {
                    if (error.request.status === 400) {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'success',
                            title: 'There was a problem updating your profile',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    };



    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const handleSubmitForm = (data) => {
        SocialUpdateAPI(data)
    }

    useEffect(() => {
        console.log(getData?.state?.social)
    }, [])



    return (
        <>
            <Header />
            <Sidebar />

            <div className="sm:ml-64 ml-0 mt-20 user-social">
                <div className="container mx-auto">
                    <div className="relative flex flex-col mt-10 overflow-hidden max-h-full">
                        <h2 className="p-4 pl-0 text-2xl font-bold">Update Social Media</h2>
                        <div className='bg-white rounded-lg border p-4'>
                            <h5 className='text-lg'>
                                Add your <b className='text-primary'>social media usernames</b> below<br></br>
                            </h5>
                            <div className=''>
                                <form onSubmit={(e)=>{e.preventDefault();}}>
                                    {/* <form onSubmit={handleSubmit(handleSubmitForm)}> */}
                                    <div className='grid grid-cols-3 gap-4'>
                                        <div className=" p-6 bg-white border border-gray-200 rounded-lg shadow" >
                                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 text-center"><i className={`fa-brands fa-facebook`}></i>  Facebook</h5>
                                            <div className='flex flex-col'>
                                                <input type="text" name="facebook" placeholder={'Please Enter Your Username'} defaultValue={getData?.state?.social[0]?.facebook ? getData?.state?.social[0]?.facebook : ''} {...register('facebook')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                <h5 className='text-center m-2'>OR</h5>
                                                {/* <button className='text-white rounded-md shadow p-1 bg-secondary' onClick={() => OpenInstagramUrl()}>connect</button>
                                                 */}
                                                <FacebookLogin
                                                    appId="592523072993931"
                                                    scope='instagram_basic,pages_show_list'
                                                    
                                                    onSuccess={(response) => {
                                                        console.log('Login Success!', response);
                                                    }}
                                                    onFail={(error) => {
                                                        console.log('Login Failed!', error);
                                                    }}
                                                    onProfileSuccess={(response) => {
                                                        console.log('Get Profile Success!', response);
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        <div className=" p-6 bg-white border border-gray-200 rounded-lg shadow" >
                                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 text-center"><i className={`fa-brands fa-instagram`}></i>  Instagram</h5>
                                            <div className='flex flex-col'>
                                                <input type="text" name="instagram" placeholder={'Please Enter Your Username'} defaultValue={getData?.state?.social[0]?.instagram ? getData?.state?.social[0]?.instagram : ''} {...register('instagram')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                <h5 className='text-center m-2'>OR</h5>
                                                <button className='text-white rounded-md shadow p-1 bg-secondary' onClick={() => OpenInstagramUrl()}>connect</button>
                                                {/* <button className='text-white rounded-md shadow p-1 mt-2 bg-secondary' onClick={() => SendSocialData(code)}>Verfiy</button> */}
                                                {/* {dataResponse?.id.length > 0 ?
                                                    <div className='mt-2'>
                                                        <div className='mt-1'>username: {dataResponse.username}</div>
                                                        <div className='mt-1'>Followers Number: </div>
                                                        <div className='mt-1'>Engagement Rate: </div>
                                                        <div className='mt-1'>Number of posts: </div>
                                                        <div className='mt-1'>Total numbers of likes: </div>
                                                    </div>
                                                    : <div></div>} */}
                                            </div>
                                        </div>
                                        <div className=" p-6 bg-white border border-gray-200 rounded-lg shadow" >
                                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 text-center"><i className={`fa-brands fa-tiktok`}></i>  Tiktok</h5>
                                            <div className='flex flex-col'>
                                                <input type="text" name="tiktok" placeholder={'Please Enter Your Username'} defaultValue={getData?.state?.social[0]?.tiktok ? getData?.state?.social[0]?.tiktok : ''} {...register('tiktok')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                <h5 className='text-center m-2'>OR</h5>
                                                <button className='text-white rounded-md shadow p-1 bg-secondary' >connect</button>
                                            </div>
                                        </div>
                                        <div className=" p-6 bg-white border border-gray-200 rounded-lg shadow" >
                                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 text-center"><i className={`fa-brands fa-linkedin`}></i>  Linkedin</h5>
                                            <div className='flex flex-col'>
                                                <input type="text" name="linkedin" placeholder={'Please Enter Your Username'} defaultValue={getData?.state?.social[0]?.linkedin ? getData?.state?.social[0]?.linkedin : ''} {...register('linkedin')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                                <h5 className='text-center m-2'>OR</h5>
                                                <button className='text-white rounded-md shadow p-1 bg-secondary'>connect</button>
                                            </div>
                                        </div>
                                        <div className=" p-6 bg-white border border-gray-200 rounded-lg shadow" >
                                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 text-center"><i className={`fa-brands fa-youtube`}></i>  Youtube</h5>
                                            <input type="text" name="youtube" placeholder={'Please Enter Your Username'} defaultValue={getData?.state?.social[0]?.youtube ? getData?.state?.social[0]?.youtube : ''} {...register('youtube')} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                        </div>
                                    </div>
                                    <div className="">
                                        <button className="place-self-center w-full py-2 mt-8 text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>








                </div>
            </div>



        </>
    );
}

export default UpdateSoicalTest;