import { useEffect, useState, useContext } from 'react';
import axios from "axios";
import { ApiUrl } from "../../API/Api.config";
import './VerifiedEmail.style.scss';


const VerfiedEmailPage = () => {
    document.title = "Email Verifcation | PROMOTE"

    const [verifyText, setverifyText] = useState("");
    

    async function VerifyAPI(key) {
        try {
            await axios.post(ApiUrl + 'verify-email/', {
                key: key
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            })
            .then(function (response) {
                
                if(response.data.detail == 'ok') {
                    setverifyText('Email Verified')
                    setTimeout(function(){
                        window.location.href = '/login';
                    }, 3000);
                }
            })
            .catch(function (error) {
                console.log(error.data.detail);
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        VerifyAPI(params.get("key"));
    }, [])

    return (
        <>
            <div id='VerifyEmail'>
                <section>
                    <div className='container h-full'>
                        <div className='flex justify-center items-center h-full'>
                            <div className="block  p-6 bg-white border border-gray-200 rounded-lg shadow">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 text-center">{verifyText ? 'Email has been Verified' : 'Email has not been Verified'}</h5>
                                <p className="font-normal text-gray-700 text-center">{verifyText ? 'You will be redirected to login page shortly' : 'Resend Email Verification'}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default VerfiedEmailPage;