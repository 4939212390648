export default function StepperControl({ handleClick, currentStep, steps, dataEmpty, allowNext }) {
    return (
        <>
            <div className="flex sm:flex-row flex-col sm:px-0 px-4 justify-center w-full gap-4">
                {currentStep > 1 &&
                    <button
                        onClick={() => handleClick("", false)}
                        className={`cursor-pointer rounded-lg bg-secondary w-full border-2 border-slate-300 bg-white py-2 px-4 sm:uppercase font-semibold text-slate-400 transition duration-200 ease-in-out hover:bg-secondary hover:text-white `}
                    >
                        <i className="fa-solid fa-arrow-left"></i> Back
                    </button>
                }

                <div className="w-full">
                    <button
                        onClick={() => handleClick("next", dataEmpty)}
                        className={"cursor-pointer rounded-lg w-full h-full " + (dataEmpty ? "bg-third" : "bg-primary") + " py-2 px-4 sm:uppercase font-semibold text-white transition duration-200 ease-in-out hover:text-white"}
                        disabled={dataEmpty ? 'disabled' : '' }
                    >
                        {currentStep === steps.length - 1 ? <>Confirm <i className="fa-solid fa-check"></i></> :
                            <>
                                {(allowNext || dataEmpty) ? (
                                    <span className="block text-center">
                                        Complete this step to continue
                                    </span>
                                ) : 
                                <>
                                    Next&nbsp;<i className="fa-solid fa-arrow-right"></i>
                                </>}

                            </>}
                    </button>

                </div>
            </div>
        </>
    );
}