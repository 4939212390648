import React from 'react';
import { ApiUrl } from "../../API/Api.config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Logout = () => {
    const nav = useNavigate();
    
    async function LogoutAPI() {

        try {
            await axios.get(ApiUrl + 'accounts/logout/', 
                {
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    }
                })
                .then(function (response) {
                    console.log(response)

                })
                .catch(function (error) {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)

        }

    };

    React.useEffect(() => {
        LogoutAPI()
        nav('/login')
        secureLocalStorage.clear()
    }, [])


    return ( 
        <div>
            Goodbye
        </div>
     );
}
 
export default Logout;