import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../../API/Api.config';
import { useForm } from 'react-hook-form';
import secureLocalStorage from "react-secure-storage";
import TokenService from '../../API/token.service';
import { useNavigate } from "react-router-dom";
import Header from "../../components/DashboardHeader/DashboardHeader";
import Sidebar from '../../components/Sidebar/Sidebar';

export default function Payment() {
    document.title = "Pricing Plans | Promote"

    // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    const { setValue, handleSubmit, formState: { error } } = useForm();

    const nav = useNavigate()
    const token = TokenService.getLocalAccessToken()
    const [sessionID, setSessionID] = useState("");
    const [data, setData] = useState([]);
    const [freePackage, setFreePackage] = useState([]);
    const [pay, setPay] = useState(undefined)
    const [plan, setPlan] = useState(null);
    const date = new Date();
    const getDay = date.getDate();
    const getMonth = date.getMonth() + 1;
    const getYear = date.getFullYear();
    const fullDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (getDay <= 9 ? '0' + getDay : getDay);

    async function getPlan() {
        try {
            await axios.get(ApiUrl + 'payment_register/', {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Token ' + token,
                }
            }).then(response => { setPlan(response.data[0].subscription) }).catch(error => console.log(error));
        } catch (e) { console.log(e) }
    }

    async function SubscriptionPay(data) {
        try {
            await axios.post(ApiUrl + 'payment/', data, {
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    'Authorization': 'Token ' + token
                }
            })
                .then(response => { console.log(response.data.url); setPay(response.data.url) })
                .catch(e => console.log(e))
        } catch (e) {
            console.log(e)
        }
    };

    async function SubscriptionView() {
        try {
            await axios.get(ApiUrl + 'subscription_plans/', {
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    'Authorization': 'Token ' + token
                }
            })
                .then(response => {
                    let newData = response.data.filter(i => i.free_period === false)
                    let free = response.data.filter(i => i.free_period === true)
                    setFreePackage(free)
                    setData(newData)
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {
            console.log(e)
        }
    };

    async function StoreSubscription(data) {

        // const params = new URLSearchParams(window.location.search);
        // const myParam = params.get('cancelled');

        // console.log('sdfsd' + myParam);
        try {
            await axios.post(ApiUrl + 'payment_register/', data,
                {
                    headers: { "content-type": "application/json; charset=UTF-8", 'Authorization': 'Token ' + token }
                })
                .then(response => { console.log(response.data); getPlan() })
                .catch(e => { console.log(e); console.log(data) })
        } catch (e) {
            console.log(e)
        }
    };


    useEffect(() => {
        SubscriptionView()
        getPlan()
    }, [])

    // useEffect(() => {
    //     console.log(plan);
    // }, [plan]);



    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setSessionID(query.get("success"));
        }

        if (query.get("canceled")) {
            setSessionID(query.get("canceled"));
        }
    }, []);


    useEffect(() => {
        if (sessionID) {
            const t = {
                'status': 'done',
                'stripe_id': sessionID,
                'start_date': fullDate,
            }
            const { subscription } = secureLocalStorage.getItem('data')
            const paymentData = {
                subscription,
                ...t
            }
            StoreSubscription(paymentData)
            secureLocalStorage.removeItem('payment_status')
            secureLocalStorage.setItem('payment_status', true)
        }
        // console.log(sessionID)

    }, [sessionID])


    const handlePay = (data) => {
        SubscriptionPay(data)
        secureLocalStorage.setItem('data', data)
    }

    useEffect(() => {
        if (pay) {
            window.location.href = pay
        }
    }, [pay])

    useEffect(() => { console.log(freePackage[0])}, [plan])

    //   function handleSubscriptionValue(product_id, free_date){
    //     setValue('product_id',product_id)

    //   }

    function handleSubscriptionValue(name, price, subscription, product_id, free_date, active_free_period = false) {
        setValue('name', name)
        setValue('unit_amount', price)
        setValue('subscription', subscription)
        setValue('product_id', product_id)
        setValue('free_period', free_date)
        setValue('active_free_period', active_free_period)
    }

    return (
        <>
            <Header />
            <Sidebar />
            <div className='sm:ml-64 mt-20 mb-10'>
                <div className='container mx-auto '>
                    <h2 className="p-4 text-2xl font-bold">Pricing plans</h2>
                    {/* <h3 className="px-4 text-primary text-xl font-bold">
                        "influencer marketing: The Key to unlocking your brand potential"
                    </h3> */}
                    <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mt-4 px-4'>
                        {data.map((obj) => {
                            return (
                                obj.name == 'Pro' ?
                                    <div className='h-full'>
                                        <div className={"p-4 w-full bg-secondary rounded-lg sm:p-8 h-full " + (plan == obj.id ? 'border border-primary shadow-lg' : '')}>
                                            <h5 className="mb-4 text-xl font-medium text-white text-center">{obj.name} plan</h5>
                                            <div className="text-white text-center">
                                                <span className="text-3xl font-semibold text-white">£&nbsp;</span>
                                                <span className="text-4xl font-extrabold tracking-tight text-white">{obj.price}</span>
                                                <span className="ml-1 text-xl font-normal text-gray-100">{obj.package_period}</span>
                                            </div>
                                            {/* <span className="text-red-700 italic text-center text-sm font-bold whitespace-pre-line">{plan === obj.id ? `You have enrolled for this Plan\nnow You can start chat, post jobs` : null}</span> */}
                                            <div className='mt-7 text-white text-center'>
                                                <p className='h-[72px]'>{obj.description}</p>
                                            </div>
                                            <ul role="list" className="my-7 space-y-3">
                                                <li className="flex space-x-3">
                                                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                    <span className="text-base font-normal leading-tight text-white">{obj.number_of_connections} Influencer Connections</span>
                                                </li>
                                                <li className="flex space-x-3">
                                                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                    <span className="text-base font-normal leading-tight text-white">{obj.feature_influencers_number} Tailored Influencers</span>
                                                </li>
                                                <li className="flex space-x-3">
                                                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                    <span className="text-base font-normal leading-tight text-white">Access to Influencer Search</span>
                                                </li>
                                                <li className="flex space-x-3">
                                                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                    <span className="text-base font-normal leading-tight text-white">Promotion on our Social Media</span>
                                                </li>
                                                <li className="flex space-x-3">
                                                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                    <span className="text-base font-normal leading-tight text-white">Monthly Review with specialist</span>
                                                </li>
                                            </ul>
                                            {plan == obj.id ?
                                                <div className="text-white bg-secondary font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center border">Current Plan</div>
                                                :
                                                <form onSubmit={handleSubmit(handlePay)}>
                                                    <div key={obj.id}>
                                                        <button type="submit" className="text-secondary bg-white font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center" onClick={() => handleSubscriptionValue(obj.name, obj.price, obj.id, obj.product_id, obj.free_date)}>Select Plan</button>
                                                    </div>
                                                </form>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='h-full'>
                                        <div className={"p-4 w-full bg-white rounded-lg border sm:p-8 h-full " + (plan == obj.id ? 'border-3 border-primary shadow-lg' : '')}>
                                            <h5 className="mb-4 text-xl font-medium text-primary text-center">{obj.name} plan</h5>
                                            {obj.name == 'Starter' ?
                                                <div className="text-gray-900 text-center">
                                                    <span className="text-3xl font-semibold text-black">£&nbsp;</span>
                                                    <span className="text-4xl font-extrabold tracking-tight text-black">{obj.price}</span>
                                                    <span className="ml-1 text-xl font-normal text-gray-500">{obj.package_period}</span>
                                                </div>
                                                :
                                                <div className="text-gray-900 text-center">
                                                    <span className="text-4xl font-extrabold tracking-tight text-black">Lets Talk</span>
                                                </div>
                                            }
                                            {/* <span className="text-red-700 italic text-center text-sm font-bold whitespace-pre-line">{plan === obj.id ? `You have enrolled for this Plan\nnow You can start chat, post jobs` : null}</span> */}
                                            <div className='mt-7 text-center'>
                                                <p className='h-[72px]'>{obj.description}</p>
                                            </div>
                                            {obj.name == 'Starter' ?
                                                <ul role="list" className="my-7 space-y-3">
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">{obj.number_of_connections} Influencer Connections</span>
                                                    </li>
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">{obj.feature_influencers_number} Tailored Influencers</span>
                                                    </li>
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">Access to Job Forum</span>
                                                    </li>
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">Access to Advice Hub</span>
                                                    </li>
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">Access to Task Manager</span>
                                                    </li>
                                                </ul>
                                                :
                                                <ul role="list" className="my-7 space-y-3">
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Check icon</title><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">Handpicked Influencers</span>
                                                    </li>
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Check icon</title><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">Campaign management</span>
                                                    </li>
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Check icon</title><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">Content ideas for promotion</span>
                                                    </li>
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Check icon</title><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">Monthly Campaign report</span>
                                                    </li>
                                                    <li className="flex space-x-3">
                                                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Check icon</title><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                                        <span className="text-base font-normal leading-tight text-gray-500">Weekly call with specialist</span>
                                                    </li>
                                                </ul>
                                            }
                                            <div key={obj.id}>
                                                {obj.name == 'Starter' ?
                                                    plan == obj.id ?
                                                        <div className="text-white bg-secondary font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Current Plan</div>
                                                        :
                                                        plan > 0 ?
                                                            <form onSubmit={handleSubmit(handlePay)}>
                                                                <div key={obj.id}>
                                                                    <button type="submit" className="text-white bg-primary font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center" onClick={() => handleSubscriptionValue(obj.name, obj.price, obj.id, obj.product_id, null)}>Select Plan</button>
                                                                </div>
                                                            </form>
                                                            :
                                                            <form onSubmit={handleSubmit(handlePay)}>
                                                                <div key={obj.id}>
                                                                    <button type="submit" className="text-white bg-primary font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center" onClick={() => handleSubscriptionValue(obj.name, obj.price, obj.id, obj.product_id, null)}>Select Plan</button>
                                                                </div>
                                                            </form>
                                                    :
                                                    plan == obj.id ?
                                                        <div className="text-white bg-secondary font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Current Plan</div>
                                                        :
                                                        <a href='#' className="text-white bg-primary font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Contact Sales</a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                            )
                        })}
                    </div>
                    <div id="detailed-pricing" class="w-full overflow-x-auto mt-20">
                        <div class="overflow-hidden sm:min-w-full min-w-max">
                            <div class="grid grid-cols-5 p-4 text-sm font-medium text-gray-900 bg-gray-100 border-t border-b border-gray-200 gap-x-16 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                                <div class="flex items-center">Compare Features</div>
                                <div className='text-center'>Free Trial Plan<br /><small>{plan == data[0]?.id ? 'Current Plan' : ''}</small></div>
                                <div className='text-center'>Starter Plan<br /><small>{plan == data[0]?.id ? 'Current Plan' : ''}</small></div>
                                <div className='text-center'>Pro Plan <br /><small>{plan == data[1]?.id ? 'Current Plan' : ''}</small></div>
                                <div className='text-center'>Enterprise Plan<br /><small>{plan == data[2]?.id ? 'Current Plan' : ''}</small></div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Influencer connections</div>
                                <div className='flex justify-center'>
                                    1 Influencer connections
                                </div>
                                <div className='flex justify-center'>
                                    {data[0]?.number_of_connections} Influencer connections
                                </div>
                                <div className='flex justify-center'>
                                    {data[1]?.number_of_connections} Influencer connections
                                </div>
                                <div className='flex justify-center'>
                                    Personalised
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Tailored Influencer</div>
                                <div className='flex justify-center items-center'>
                                    3 Influencer connections
                                </div>
                                <div className='flex justify-center items-center'>
                                    {data[0]?.feature_influencers_number} Influencer connections
                                </div>
                                <div className='flex justify-center items-center'>
                                    {data[1]?.feature_influencers_number} Influencer connections
                                </div>
                                <div className='flex justify-center items-center'>
                                    Personalised
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Access to Task Manager</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Message Influencer connections</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Email Notifications</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Access to Advice Hub</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Dashboard to manage campaign applications</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Influencer Payments & Contracts</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Email Support</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Promotion on our Social Media</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Content Licensing</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Promotional Content Ideas</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Group Calls with Influencers</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Monthly Campaign Report</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">24/7 Dedicated Support</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Dedicated Campaign Manager</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500">Call with our specialist</div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div className='flex justify-center items-center'>
                                    {data[1]?.call_with_our_specialist}
                                </div>
                                <div className='flex justify-center items-center'>
                                    {data[2]?.call_with_our_specialist}
                                </div>
                            </div>
                            <div class="grid grid-cols-5 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div class="text-gray-500"></div>
                                <div className='flex justify-center items-center'>
                                    {plan == freePackage[0]?.id ?
                                        <div class="text-white block w-full bg-secondary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center">Current Plan</div>
                                        :
                                        plan > 0 ?
                                            <button class="text-white block w-full bg-gray-500 focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center">Can't Select This Plan</button>
                                            :
                                            <form onSubmit={handleSubmit(handlePay)} className='w-full'>
                                                <button class="text-white block w-full bg-primary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center w-full" onClick={() => handleSubscriptionValue(freePackage[0]['name'], freePackage[0]['price'], freePackage[0]['id'], freePackage[0]['product_id'], freePackage[0]['free_date'], true)}>Start Free Trial</button>
                                            </form>
                                    }
                                </div>
                                <div className='flex justify-center items-center'>
                                    {plan == data[0]?.id ?
                                        <div class="text-white block w-full bg-secondary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center">Current Plan</div>
                                        :
                                        plan > 0 ?
                                            <form onSubmit={handleSubmit(handlePay)} className='w-full'>
                                                <button class="text-white block w-full bg-primary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center w-full" onClick={() => handleSubscriptionValue(data[0]['name'], data[0]['price'], data[0]['id'], data[0]['product_id'], null)}>Select Plan</button>
                                            </form>
                                            :
                                            <form onSubmit={handleSubmit(handlePay)} className='w-full'>
                                                <button class="text-white block w-full bg-primary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center w-full" onClick={() => handleSubscriptionValue(data[0]['name'], data[0]['price'], data[0]['id'], data[0]['product_id'], null)}>Select Plan</button>
                                            </form>
                                    }
                                </div>
                                <div className='flex justify-center items-center'>
                                    {plan == data[1]?.id ?
                                        <div class="text-white block w-full bg-secondary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center">Current Plan</div>
                                        :
                                        plan > 0 ?
                                            <form onSubmit={handleSubmit(handlePay)} className='w-full'>
                                                <button class="text-white block w-full bg-primary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center w-full" onClick={() => handleSubscriptionValue(data[1]['name'], data[1]['price'], data[1]['id'], data[1]['product_id'], data[1]['free_date'])}>Select Plan</button>
                                            </form>
                                            :
                                            <form onSubmit={handleSubmit(handlePay)} className='w-full'>
                                                <button class="text-white block w-full bg-primary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center w-full" onClick={() => handleSubscriptionValue(data[1]['name'], data[1]['price'], data[1]['id'], data[1]['product_id'], data[1]['free_date'])}>Select Plan</button>
                                            </form>
                                    }
                                </div>
                                <div className='flex justify-center items-center'>
                                    {plan == data[2]?.id ?
                                        <div class="text-white block w-full bg-secondary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center">Current Plan</div>
                                        :
                                        <a href="#" class="text-white block w-full bg-primary focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 text-center w-full">Contact Sales</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
