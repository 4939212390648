import React from 'react'
import axios from 'axios'
import { useForm } from "react-hook-form";
import TokenService from '../../API/token.service'
import { ApiUrl } from '../../API/Api.config';
import { useState } from 'react';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import secureLocalStorage from "react-secure-storage";
import { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { imgApi } from '../../API/Api.config';
import hero from '../../assets/images/users/cover.jpg';
import ReactStars from "react-rating-stars-component";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './UserInfo.style.scss';
import { Tabs } from 'flowbite-react'


const StartupById = () => {
    document.title = "Startup | Promote"

    const token = TokenService.getLocalAccessToken();
    const role = secureLocalStorage.getItem("role");
    const userid = secureLocalStorage.getItem("userid");
    const myid = secureLocalStorage.getItem("user_id");
    const profileView = secureLocalStorage.getItem("profile_view");
    const influencer_connect = secureLocalStorage.getItem("influencer_connect");
    const paymentStatus = secureLocalStorage.getItem('payment_status');
    const nav = useNavigate();
    const influencerid = userid
    const [social, setSocial] = useState([])
    const [data, setStartupData] = useState([]);
    const [images, setImages] = useState([])
    const MySwal = withReactContent(Swal)

    const { register, handleSubmit, formState: { errors } } = useForm();


    async function getSoical() {
        try {
            await axios.get(ApiUrl + `get_soical_account/${influencerid}`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => setSocial(response.data)).catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {

        }
    }

    async function getStartupInfo() {
        try {
            await axios.get(ApiUrl + `get_startup_id/?id=${userid ? userid : influencerid}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }
            })
                .then(response => {
                    if(response.data.length > 0) setStartupData(response.data)
                    else {
                        // swal({
                        //     title: "Alert",
                        //     text: "Sorry, this account not a startup account, go back to search screen to see Influencers account",
                        //     icon: "error",
                        //     className: "app-popup",
                        //     dangerMode: true,
                        // }).then(willSearch => {
                        //     if (willSearch) {
                        //         swal.close()
                        //         return window.location.href = '/search/'
                        //     }
                        // })
                    }
                })
                .catch(error => {
                    console.log(error.response.status)
                    if (error.response.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {
            console.log()
        }
    }

    const handleClick = (img) =>{
        Swal.fire({
            imageUrl: imgApi + img,
            imageAlt: 'Custom image',
            width:500,
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                image: 'img-pop-up',
                popup:'html-img'
            }
        })
    }

    function addHttpsToUrl(url) {
        if (!/^https:\/\//i.test(url)) {
          url = "https://" + url;
        }
        return url;
    }


    const extractUsernameFromUrl = (url) => {
        const regex = {
            instagram: /(?:http(?:s)?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_\.]+)/,
            facebook: /(?:http(?:s)?:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9\.]+)/,
            tiktok: /(?:http(?:s)?:\/\/)?(?:www\.)?tiktok\.com\/@([a-zA-Z0-9_\.]+)/,
            linkedin: /(?:http(?:s)?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9\-\.]+)/,
            youtube: /(?:http(?:s)?:\/\/)?(?:www\.)?youtube\.com\/(?:channel\/)?([a-zA-Z0-9_\-]+)/
        };
        
        let username = null;
        Object.keys(regex).some((platform) => {
            const match = url.match(regex[platform]);
            if (match) {
                username = match[1];
                return true;
            }
        });
        
        return username;
    };

   
    const createSocialMediaUrl = (platform, username) => {
        switch (platform) {
            case 'instagram':
                return `https://www.instagram.com/${username}`;
            case 'facebook':
                return `https://www.facebook.com/${username}`;
            case 'tiktok':
                return `https://www.tiktok.com/@${username}`;
            case 'linkedin':
                return `https://www.linkedin.com/in/${username}`;
            case 'youtube':
                return `https://www.youtube.com/channel/${username}`;
            default:
                return null;
        }
    };

    function getSocialMediaColor(platformName) {
        let color;
        switch (platformName.toLowerCase()) {
          case 'instagram':
            color = '#C13584'; // pink
            break;
          case 'facebook':
            color = '#1877F2'; // blue
            break;
          case 'tiktok':
            color = '#000000'; // black
            break;
          case 'linkedin':
            color = '#0077B5'; // blue
            break;
          case 'youtube':
            color = '#FF0000'; // red
            break;
          default:
            color = '#000000'; // black
        }
        return color;
      }

    useEffect(() => {
        getStartupInfo()
        getSoical()
    }, [])

    return (
        <>
            <Header/>
            <Sidebar/>
            <div className='sm:ml-64 ml-0 sm:pt-0 pt-10 user-info'>
                
                    <div className='container mx-auto '>
                        <div className='flex justify-center'>
                        {data.map((res) => {
                                return (
                                    <>
                                        <div className='user-details w-full flex justify-center items-center h-full'>
                                            <div className='grid sm:grid-cols-6 grid-cols-1 grid-rows-1 gap-y-4 w-full'>
                                                <div className='bg-white flex items-center justify-center p-4 rounded-l-lg col-span-1'>
                                                    <div className='basic-info flex flex-col items-center justify-center w-full'>
                                                        <div className='profile-img '>
                                                            <img src={imgApi + res.logo} alt={res.logo} className="border border-primary rounded-full" />
                                                        </div>
                                                        <div>
                                                            <p className='mt-4 text-secondary font-bold text-center'>
                                                                {res.name}&nbsp;
                                                                {res.is_sustainable &&
                                                                    <i className="fa-solid fa-leaf text-green-500"></i>
                                                                }
                                                                {res.charitable &&
                                                                    <i className="fa-solid fa-hand-holding-heart text-red-500"></i>
                                                                } 
                                                            </p>
                                                            <p className=' text-primary italic text-center'> 
                                                                Business
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='sm:col-span-5 col-span-1 h-full'>
                                                    <div className='bg-white p-4 rounded-r-lg h-full'>
                                                        <div className='flex justify-end flex-col h-full'>
                                                            
                                                            <div className=''>
                                                                <p className='text-sm'>
                                                                    <i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{res.location}
                                                                </p>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <p className='text-sm'>
                                                                    <i className="fa-solid fa-globe"></i>&nbsp;&nbsp;<a href={addHttpsToUrl(res.website)} target='_blank'>{res.website}</a> 
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className='text-sm mt-2 leading-6' dangerouslySetInnerHTML={{__html: res.bio}}>
                                                                </p>
                                                            </div>
                                                            <div className='flex flex-col'>
                                                                <div className='flex justify-between '>
                                                                    <div>
                                                                        {social.length > 0 ?
                                                                        <div className={'grid grid-cols-5 justify-items-center mt-2' }>
                                                                            { Object.keys(social[0]).map((oneKey,i)=>{
                                                                                if(oneKey !== 'id') {
                                                                                    if((social[0][oneKey] !== '') && (social[0][oneKey] !== null)){
                                                                                        if(social[0][oneKey]?.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase() !== 'NA'.toLowerCase()){
                                                                                            if(social[0][oneKey].toLowerCase().includes(oneKey + ".com") === true) {
                                                                                                return (
                                                                                                    <div key={i} className="p-2 mr-2 flex items-center justify-center h-full rounded-lg w-full border hover:border-secondary" style={{background: getSocialMediaColor(oneKey)}}>
                                                                                                        <a href={createSocialMediaUrl(oneKey, extractUsernameFromUrl(social[0][oneKey].toLowerCase().replace('@', '')))} target="_blank" className='text-center text-xs w-full block'>
                                                                                                            <i className={"fa-brands fa-"+oneKey+" text-white"}></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <div key={i} className="p-2 mr-2 flex items-center justify-center h-full rounded-lg w-full border hover:border-secondary" style={{background: getSocialMediaColor(oneKey)}}>
                                                                                                        <a href={createSocialMediaUrl(oneKey, social[0][oneKey]).replace('@', '')} target="_blank" className='text-center text-xs w-full block'>
                                                                                                            <i className={"fa-brands fa-"+oneKey+" text-white"} ></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        } else {
                                                                                            return (
                                                                                                <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-gray-500 rounded-lg w-full border hover:border-secondary">
                                                                                                    <span target="_blank" className='text-center text-xs w-full block'>
                                                                                                        <i className={"fa-brands fa-"+oneKey+" text-white"}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    } else {
                                                                                        return (
                                                                                            <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-gray-500 rounded-lg w-full border hover:border-secondary">
                                                                                                <span target="_blank" className='text-center text-xs w-full block'>
                                                                                                    <i className={"fa-brands fa-"+oneKey+" text-white"}></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            })}
                                                                        </div> :
                                                                        <> 
                                                                            <div className='mt-2 bg-secondary rounded-lg p-2 px-4 '>
                                                                                <p className='text-white text-sm'>
                                                                                    No Social Media Accounts Found
                                                                                </p> 
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='sm:col-span-6 col-span-1 row-span-1'>
                                                    <div className='grid sm:grid-cols-6 grid-cols-1 grid-rows-1 gap-4 '>
                                                        <div className='sm:col-span-3 col-span-1 row-span-1 h-full'>
                                                            <div className='bg-white p-4 rounded-lg h-full'>
                                                                <h3 className='font-bold'>
                                                                    Basic Information
                                                                </h3>
                                                                <div className='mt-4 rounded-lg'>
                                                                    <table className="w-full rounded-lg text-xs text-left text-gray-500">
                                                                        <tbody>
                                                                            <tr className="bg-white border-b">
                                                                                <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                    Business Type:
                                                                                </th>
                                                                                <td className="py-3 px-2">
                                                                                    {res.business_type ?  res.business_type : 'Not Specified'}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="bg-white border-b">
                                                                                <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                    Charitable:
                                                                                </th>
                                                                                <td className="py-3 px-2">
                                                                                    {res.charitable ? 'Yes' : 'No'}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="bg-white">
                                                                                <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                    Sustainable:
                                                                                </th>
                                                                                <td className="py-3 px-2">
                                                                                    {res.is_sustainable ? 'Yes' : 'No'}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='sm:col-span-3 col-span-1 row-span-1 h-full'>
                                                            <div className='bg-white p-4 rounded-lg h-full'>
                                                            <Tabs.Group
                                                                aria-label="Tabs with underline"
                                                                style="underline"
                                                                className='tab-btn'
                                                                >
                                                                <Tabs.Item 
                                                                    title="Interests"
                                                                    active={true}
                                                                >
                                                                    <h3 className='font-bold'>
                                                                        Target Influencer's Interests
                                                                    </h3>
                                                                    <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mt-4 h-full interests-card'>
                                                                        {res.influencers_interest.length > 0 ?
                                                                            res.influencers_interest.map(obj => {
                                                                                return (
                                                                                    <div className='rounded-lg interest-img' style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("+ imgApi + obj.image +")"}}>
                                                                                        <div className='flex justify-center items-center h-full text-white'>
                                                                                            <p className='font-bold text-xl'>
                                                                                                {obj.interest}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }) : 
                                                                            <>
                                                                                <p>No Interests Found</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Tabs.Item>
                                                                <Tabs.Item
                                                                    title="Additional Pictures"
                                                                >
                                                                    <div className='flex justify-between'>
                                                                        <h3 className='font-bold'>
                                                                            Additional Pictures (<small>Click to view bigger</small>) 
                                                                        </h3>
                                                                    </div>
                                                                    <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mt-4 h-full'>
                                                                        {images.length > 0 ?
                                                                            images.map(obj => {
                                                                                return (
                                                                                    <div className='rounded-lg more-pics' style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("+ imgApi + obj.image +")"}} onClick={()=> {handleClick(obj.image)}}></div>
                                                                                )
                                                                            }) :
                                                                            <>
                                                                                <p> No Pictures Found</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Tabs.Item>
                                                                <Tabs.Item
                                                                    title="Target Media"
                                                                >
                                                                    <div className='flex justify-between'>
                                                                        <h3 className='font-bold'>
                                                                            Target Social Media 
                                                                        </h3>
                                                                    </div>
                                                                    <div className='grid sm:grid-cols-5 grid-cols-1 gap-4 mt-4 h-full'>
                                                                        {res.social_network.length > 0 ?
                                                                            res.social_network.map(obj => {
                                                                                return (
                                                                                    <div className='rounded-lg text-center p-4' style={{background: getSocialMediaColor(obj.social_network)}}>
                                                                                        <i className={"fa-brands fa-"+obj.social_network.toLowerCase()+" text-white"}></i>
                                                                                    </div>
                                                                                )
                                                                            }) :
                                                                            <>
                                                                                <p> No Social Media</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Tabs.Item>
                                                                <Tabs.Item
                                                                    title="Content Type"
                                                                >
                                                                    <div className='flex justify-between'>
                                                                        <h3 className='font-bold'>
                                                                            Type of Content 
                                                                        </h3>
                                                                    </div>
                                                                    <div className='grid sm:grid-cols-4 grid-cols-1 gap-4 mt-4 h-full'>
                                                                        {res.type_of_collabration.length > 0 ?
                                                                            res.type_of_collabration.map(obj => {
                                                                                return (
                                                                                    <div className='rounded-lg text-center p-4 border' style={{backgroundColor: 'white' }}>
                                                                                        <p>
                                                                                            {obj.type}
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                            }) :
                                                                            <>
                                                                                <p> Nothing Specified</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Tabs.Item>
                                                                <Tabs.Item
                                                                    title="Goals"
                                                                >
                                                                    <div className='flex justify-between'>
                                                                        <h3 className='font-bold'>
                                                                            Type of Content 
                                                                        </h3>
                                                                    </div>
                                                                    <div className='grid sm:grid-cols-4 grid-cols-1 gap-4 mt-4 h-full'>
                                                                        {res.compaign_goal.length > 0 ?
                                                                            res.compaign_goal.map(obj => {
                                                                                return (
                                                                                    <div className='rounded-lg text-center p-4 border' style={{backgroundColor: 'white' }}>
                                                                                        <p>
                                                                                            {obj.goals}
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                            }) :
                                                                            <>
                                                                                <p> Nothing Specified</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Tabs.Item>
                                                            </Tabs.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                
            </div>
        </>
    );
}

export default StartupById;